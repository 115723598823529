import {
  useMantineTheme,
} from "@mantine/core";

import { useEffect, useState } from "react";
import { TitlePage } from "../../components/TitlePage";
import RiskPoliceDimension from '../../services/RiskPoliceDimension'
import TesteTabela from "./TableDimension";
import TableFact from "./TableFacts";
import TablePair from "./TableFieldPair";

function RiscoEcontrole() {
  const theme = useMantineTheme();
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState<any>([])

  const fetchData = async () => {
    setLoading(true);
    RiskPoliceDimension.getAll().then((item: any) =>{ 

    }).catch((err) => {
        setLoading(false);
        return err;
      });
  };

  useEffect(() => {
    fetchData()
  },[])


  return (
    <div className="App" style={{ height: "100vh" }}>

      <TitlePage title="Risco e Controle"></TitlePage>
      <div>
      <TesteTabela/>
      <TableFact />
      <TablePair/>
      </div>

    </div>
  );
}

export default RiscoEcontrole;
