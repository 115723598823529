import React from 'react';
import { DatePicker } from '@mantine/dates';
import { UseControllerProps, useController } from 'react-hook-form';

type DatePickerProps = Partial<UseControllerProps> & {
  label?: string;
  name: string;
};

export function CustomDatePicker({ label, name, control, rules }: DatePickerProps) {
  const { field } = useController({
    control,
    name,
    rules,
  });

  return (
    <DatePicker
      {...field}
      label={label}
      placeholder="Selecione uma data"
    />
  );
}
