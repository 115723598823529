import { Button, Flex, Select } from "@mantine/core";
import { showNotification, updateNotification } from "@mantine/notifications";
import { useEffect, useState } from "react";
import req from "../../services/tradutorBtg";

interface Props {
  data: any;
}
export function SelectAndSaveBtg({ data }: Props) {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValue(data.origem);
  }, [data]);

  function saveChangeOrigem(id: number) {
    setLoading(true);
    showNotification({
      id: "load-data",
      loading: true,
      title: "Atualizando item",
      message: "Aguarde...",
      autoClose: true,
      disallowClose: true,
    });
    req
      .put(id, value)
      .then((res: any) => {
        updateNotification({
          id: "load-data",
          color: "teal",
          title: "Sucesso!",
          message: `Item ${data.id} atualizado.`,
          autoClose: 2000,
        });
        setLoading(false);
      })
      .catch((err) => {
        updateNotification({
          id: "load-data",
          title: "Erro!",
          message: `Ocorreu algum problema ao atualizar o item`,
          autoClose: 2000,
          color: "red",
        });
        setLoading(false);
      });
  }

  function handleChangeValue(newValue: string) {
    setValue(newValue);
  }

  if (!data) {
    return <></>;
  }
  return (
    <>
      <Flex align={"center"} justify="center">
        <Select
          size="xs"
          mr="xs"
          w="220px"
          value={value}
          withinPortal
          data={[
            { value: "CADASTRAR", label: "CADASTRAR" },
            { value: "NEGOCIACAO_ATIVO", label: "NEGOCIACAO_ATIVO" },
            { value: "INTRA_ATIVO", label: "INTRA_ATIVO" },
            {
              value: "INTRA_ATIVO_PASSIVO_ADM",
              label: "INTRA_ATIVO_PASSIVO_ADM",
            },
            {
              value: "INTRA_ATIVO_PASSIVO_PFEE",
              label: "INTRA_ATIVO_PASSIVO_PFEE",
            },
            { value: "INFRA_PL", label: "INFRA_PL" },
          ]}
          onChange={(e) => {
            handleChangeValue(typeof e === "string" ? e : "");
          }}
          placeholder="Origem"
        ></Select>

        <Button
          onClick={() => saveChangeOrigem(data.id)}
          fullWidth
          loading={loading}
          variant="outline"
          size="xs"
          w="80px"
        >
          Salvar
        </Button>
      </Flex>
    </>
  );
}
