import {
  Badge,
  Card,
  createStyles,
  Group,
  LoadingOverlay,
  Text,
  Title,
} from "@mantine/core";
import moment from "moment";
import "moment-timezone";
import "moment/locale/pt-br";
import { useEffect, useState } from "react";

import ModalEditCarteiraInfo from "./ModalEdit";

import { AxiosResponse } from "axios";
import { transformDateV2 } from "../../../helpers/transformDateV2";
import { IWallet } from "../../../interfaces/IWallets";
import getWalletInfoById from "../../../services/carteira";

moment.tz.setDefault("UTC");
moment.locale("pt-BR");

interface Props {
  id: string;
}

export function HeaderCarteira({ id }: Props) {
  const useStyles = createStyles((theme) => ({
    edit: {
      width: -0,
      height: -0,
      position: "relative",
      float: "right",
      left: -35,
      top: -10,
    },
    title: {
      fontWeight: 900,
      color: theme.colors.dark[3],
      [theme.fn.smallerThan("sm")]: {
        fontSize: 20,
      },
    },
    item: {
      gap: 1,
      display: "flex",
      flexDirection: "column",
      margin: 0,
      alignItems: "center",
    },
    text: {
      fontWeight: 400,
      margin: 0,
      color: theme.colors.dark[2],
      [theme.fn.smallerThan("sm")]: {
        fontSize: 10,
      },
    },

    description: {
      maxWidth: 600,
      margin: "auto",

      "&::after": {
        content: '""',
        display: "block",
        backgroundColor: theme.fn.primaryColor(),
        width: 85,
        height: 2,
        marginTop: theme.spacing.sm,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },

    card: {
      border: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[1]
      }`,
    },
  }));

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();

  const fetchData = async () => {
    setLoading(true);
    getWalletInfoById
      .getWalletInfoById(id)
      .then((res: AxiosResponse<{ data: IWallet[] }>) => {
        setLoading(false);
        setData(res.data.data[0]);
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const { classes, theme } = useStyles();
  return (
    <div>
      <div style={{ width: "100%", position: "relative" }}>
        <LoadingOverlay visible={loading} overlayBlur={2} />

        <Card shadow="xs">
          <div className={classes.edit}>
            <ModalEditCarteiraInfo
              data={data ? data : ""}
            ></ModalEditCarteiraInfo>
          </div>
          <Group>
            <Title order={3} className={classes.title}>
              {data?.nome}
            </Title>
            {data?.ativo === true ? (
              <Badge color="green">Ativo</Badge>
            ) : (
              <Badge color="red">Inativo</Badge>
            )}
          </Group>

          <Group mt="xs">
            <Group className={classes.item}>
              <Text className={classes.text}>Inicio</Text>
              <Text className={classes.text}>
                <b>{transformDateV2(data?.data_inicio)}</b>
              </Text>
            </Group>

            <Group className={classes.item}>
              <Text className={classes.text}>Documento</Text>
              <Text className={classes.text}>
                <b>{data?.documento}</b>
              </Text>
            </Group>

            <Group className={classes.item}>
              <Text className={classes.text}>Carteira</Text>
              <Text className={classes.text}>
                <b>{data?.n_carteira}</b>
              </Text>
            </Group>

            <Group className={classes.item}>
              <Text className={classes.text}>Corretora</Text>
              <Text className={classes.text}>
                <b>{data?.corretora}</b>
              </Text>
            </Group>
          </Group>
        </Card>
      </div>
    </div>
  );
}
