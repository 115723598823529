import {
  Badge,
  Button,
  Checkbox,
  Flex,
  Input,
  LoadingOverlay,
  Pagination,
  Select,
  Table,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { IconAlertOctagon, IconBusinessplan, IconSearch, IconWallet } from "@tabler/icons";

import { AxiosResponse } from "axios";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TitlePage } from "../../components/TitlePage";
import { transformDateV2 } from "../../helpers/transformDateV2";
import { IWallet } from "../../interfaces/IWallets";
import carteira from "../../services/carteira";
import NovaCarteiraModal from "./NovaCarteiraModal";
import ProcessarCarteirasModal from "./ProcessarCarteirasModal";
import ExportToExcel from "../../helpers/ExportToExcel";

function Carteiras() {
  const theme = useMantineTheme();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IWallet[]>([]);
  const [type, setType] = useState("");
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [walletsProcess, setWalletsProcess] = useState<any>([]);
  const [switchAllDataSelect, setSwitchAllDataSelect] = useState(false);
  const [maxDate, setMaxDate] = useState<Date | null>(null);

  const [customerName, setCustomerName] = useState<string>("");
  const [wallet, setWallet] = useState("");
  const [broker, setBroker] = useState<string>("");
  const [showActive, setShowActive] = useState(true)

  const navigate = useNavigate();

  function CheckWalletInArray(carteira: string) {
    return walletsProcess.includes(carteira);
  }

  function InsertOrRemoveWalletInArray(carteira: string) {
    const index = walletsProcess.indexOf(carteira);
    if (index > -1) {
      const newWalletsProcess = [...walletsProcess];
      newWalletsProcess.splice(index, 1);
      setWalletsProcess(newWalletsProcess);
    } else {
      setWalletsProcess([...walletsProcess, carteira]);
    }
  }

  function SelectAllWallets() {
    setWalletsProcess([""]);
    setSwitchAllDataSelect(!switchAllDataSelect);
    if (switchAllDataSelect) {
      setWalletsProcess([]);
    } else {
      const Wallets = data.map((element: IWallet) => element.n_carteira);
      setWalletsProcess(Wallets);
    }
  }


  const handleChangePage = async (page: number) => {
    setCurrentPage(page);
  };

  const createClickableColumn = (value: any, n_carteira: any) => (
    <td
      onClick={() => navigate(`/cliente/${n_carteira}`)}
      style={{ cursor: "pointer" }}
    >
      {value}
    </td>
  );

  const rows = data.map((element: IWallet, idx: any) => {
    const rowDate = new Date(element.ult_att);
    const shouldHighlight = maxDate && rowDate < maxDate;

    return (
      <tr key={element.id}>
        <td>
          <Checkbox
            checked={CheckWalletInArray(element.n_carteira)}
            onClick={() => InsertOrRemoveWalletInArray(element.n_carteira)}
          />
        </td>
        {[
          element.id,
          element.nome,
          element.documento,
          transformDateV2(element?.data_inicio),
          element.n_carteira,
          element.corretora,
          element.ativo === true ? (
            <Badge color="green">Ativo</Badge>
          ) : (
            <Badge color="red">Inativo</Badge>
          ),

          shouldHighlight ? (
            <Text color="red">{transformDateV2(element.ult_att)}</Text>
          ) : (
            transformDateV2(element.ult_att)
          ),
        ].map((value, index) =>
          index === 0 ? (
            <td key={index}>{value}</td>
          ) : (
            createClickableColumn(value, element.n_carteira)
          )
        )}
      </tr>
    );
  });
  const handleChangeSearchCliente = async (term: string) => {
    setCustomerName(term);
  };

  const handleChangeSearchWallet = async (term: string) => {
    setWallet(term);
  };


  const handleChangeBroker = async (term: string) => {
    if(term === 'Todas'){
      return setBroker('')
    }
    setBroker(term);
  };

  const fetchData = async () => {
    setLoading(true);
    carteira
      .get({corretora: broker, nome: customerName, n_carteira: wallet, ativo: showActive})
      .then((res: AxiosResponse<{ data: IWallet[] }>) => {
        setLoading(false);

        const dates = res.data.data.map((wallet: IWallet) =>
          new Date(wallet.ult_att).getTime()
        );
        const recentDate = new Date(Math.max(...dates));
        setMaxDate(recentDate);

        setData(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };
  const today = new Date().toISOString()

  useEffect(() => {
    fetchData();
  }, [wallet, type, broker, customerName, showActive]);


  return (
    <div className="App" style={{ height: "100vh" }}>
      <Flex w={"100%"} justify="space-between" align={"center"} wrap={"wrap"}>
        <TitlePage title="Carteiras"></TitlePage>{" "}
        <Flex gap={"xs"} wrap={"wrap"} mb="xs">
          <Button
            bg="gray"
            onClick={() => navigate(`/sinistro`)}
            leftIcon={<IconAlertOctagon />}
          >
            Sinistros
          </Button>

          <ProcessarCarteirasModal
            carteiras={walletsProcess}
          ></ProcessarCarteirasModal>
          <NovaCarteiraModal></NovaCarteiraModal>
        </Flex>
      </Flex>
      <Flex pb="md" gap="xs" align={'center'}>
        <Input
          icon={<IconSearch />}
          placeholder="Buscar por Cliente"
          value={customerName}
          onChange={(event: any) => handleChangeSearchCliente(event.target.value)}
        />
        <Select
          placeholder="Buscar por corretora"
          onChange={(value) => handleChangeBroker(value ? value : "")}
          data={["BTG Pactual", "Xp", "Todas"]}
          icon={<IconBusinessplan />}
        />
        <Input
          icon={<IconWallet />}
          placeholder="Número carteira"
          value={wallet}
          onChange={(event: any) => handleChangeSearchWallet(event.target.value)}
        />
        <Checkbox
          checked={showActive}
          label='Exibir apenas ativos'
          onChange={(event) => setShowActive(event.currentTarget.checked)}
        />
        {data && data.length > 0 && <ExportToExcel data={data} fileName={`relatorio_carteiras_${transformDateV2(today)}`} />}
      </Flex>
      <Flex w={"100%"} align={"center"} justify="center" direction={"column"}>
        <div style={{ width: "100%", position: "relative", overflow: "auto" }}>
          <LoadingOverlay visible={loading} overlayBlur={2} />
          <Table striped highlightOnHover withColumnBorders fontSize={12}>
            <thead>
              <tr>
                <th>
                  <Checkbox
                    onClick={() => SelectAllWallets()}
                    checked={switchAllDataSelect}
                  ></Checkbox>
                </th>
                <th>Id</th>
                <th>Nome</th>
                <th>Documento</th>
                <th>Inicio</th>
                <th>Carteira</th>
                <th>Corretora</th>
                <th>Status</th>
                <th>Ultima Atualização</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        </div>

        <Flex p="xs" justify={"center"} w={"100%"} align={"flex-end"}>
          <Pagination
            size={"sm"}
            total={pages}
            page={currentPage}
            onChange={(va) => handleChangePage(va)}
          />
        </Flex>
      </Flex>
    </div>
  );
}

export default Carteiras;
