import React from 'react';
import { Input } from "../../components/Input/Index";
import { ColumnProps } from "../../types";
import { Control } from "react-hook-form";
import { Button } from '@mantine/core';

type TableColumnsProps = {
  idIsLoading: string | undefined;
  refetch: () => void;
  control: Control<any>;
  handleSave: (product: string) => void;
};

export const tableColumns = ({ idIsLoading, refetch, control, handleSave }: TableColumnsProps): ColumnProps[] => {
  return [
    {
      id: 'produto',
      header: "produto",
      cell: (item) => (
        <Input
          key={`produto-${item.row.original.produto}`}
          defaultValue={item.row.original.produto}
          name={`produtos[${item.row.original.produto}].produto`} // Exemplo de como nomear usando o produto
          control={control}
          width={'100px'}
          disabled={true}
        />
      )
    },
    {
      id: 'tag_section',
      header: "Tag Section",
      cell: (item) => (
        <Input
          key={`tag_section-${item.row.original.produto}`}
          defaultValue={item.row.original.ticker}
          name={`produtos[${item.row.original.produto}].tag_section`} // Exemplo de como nomear usando o produto
          control={control}
          width={'100px'}
        />
      )
    },
    {
      id: 'isin',
      header: "ISIN",
      cell: (item) => (
        <Input
          key={`isin-${item.row.original.produto}`}
          defaultValue={item.row.original.ticker}
          name={`produtos[${item.row.original.produto}].isin`}
          control={control}
          width={'100px'}
        />
      )
    },
    {
      id: 'cnpj',
      header: "CNPJ",
      cell: (item) => (
        <Input
          key={`cnpj-${item.row.original.produto}`}
          defaultValue={item.row.original.ticker}
          name={`produtos[${item.row.original.produto}].cnpj`}
          control={control}
          width={'100px'}
        />
      )
    },
    {
      id: 'ticker',
      header: "Ticker",
      cell: (item) => (
        <Input
          key={`ticker-${item.row.original.produto}`}
          defaultValue={item.row.original.ticker}
          name={`produtos[${item.row.original.produto}].ticker`}
          control={control}
          width={'100px'}
        />
      )
    },
    {
      id: 'cod_anbima',
      header: "Cód Anbima",
      cell: (item) => (
        <Input
          key={`cod_anbima-${item.row.original.produto}`}
          defaultValue={item.row.original.ticker}
          name={`produtos[${item.row.original.produto}].cod_anbima`}
          control={control}
          width={'100px'}
        />
      )
    },
    {
      id: 'classe_alphamar',
      header: "Classe Alphamar",
      cell: (item) => (
        <Input
        key={`classe-${item.row.original.produto}`}
        defaultValue={item.row.original.classe_alphamar}
        name={`produtos[${item.row.original.produto}].classe_alphamar`}
        control={control}
        width={'100px'}
        isRequired />
      )
    },
    {
      id: 'prazo_liq',
      header: "Prazo Liquidez",
      cell: (item) => (
        <Input
        key={`prazo_liq-${item.row.original.produto}`}
        defaultValue={item.row.original.prazo_liq}
        name={`produtos[${item.row.original.produto}].prazo_liq`}
        control={control}
        width={'100px'}
      />
      )
    },

    {
      id: 'Salvar',
      header: "Salvar",
      cell: (item) => (
        <><Button loading={idIsLoading === item.row.original.produto ? true : false} onClick={() => handleSave(item.row.original.produto)}>Salvar</Button></>
      )
    },

  ];
};
