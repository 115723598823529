import {login} from "../services/login";
import {useToast} from "@chakra-ui/react";
import {createContext, ReactNode, useContext, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";

type UserProviderContextProps = {
    signIn: (payload: SignInProps) => Promise<boolean>
    token?: TokenProps
    user?: UserProps
    getToken: () => TokenProps
    error?: ErrorProps
    logout: () => void
}

type UserProviderProps = {
    children: ReactNode
}
type TokenProps = {
    access_token: string
    expires_in: number
    ext_expires_in: number
    token_type: string
}

export type SignInProps = {
    email: string
    password: string
}

export type UserProps = {
    email: string
    name: string
    role: string
}
type ErrorProps = {
    response: string
    status: number
}
export const getToken = () => {
	const tokenData: TokenProps = JSON.parse(localStorage?.getItem(`srca@token`)!) ?? undefined
	return tokenData
}
export const getUser = () => {
	return JSON.parse(localStorage?.getItem(`srca@user`)!) ?? undefined
}
export const handleLogout = async (callback?: () => void) => {
	localStorage.removeItem(`srca@token`)
	localStorage.removeItem(`srca@user`)
	if (callback) {
		return callback()
	} else {
		return window.location.href = `/login`
	}
}
const UserProviderContext = createContext({} as UserProviderContextProps)
export function UserProvider({children}: UserProviderProps) {
	const [user, setUser] = useState<UserProps>()
	const [token, setToken] = useState<TokenProps>()
	const [error, setError] = useState<ErrorProps>()
	const [data, setData] = useState<any>()

  const navigate = useNavigate();

	const toast = useToast()
	const logout = async () => {
		handleLogout(() => {
			setToken(undefined)
			setError(undefined)
			setUser(undefined)
			return navigate("/login");
		})
	}
	const signIn = async (payload: SignInProps) => {
		try {
			setData(undefined)
			const response = await login(payload)
			const token = response?.data?.token

			if (response?.data.status === 200) {
			setData(response.data)
			setError(undefined)
			setToken(token)
			localStorage.setItem(`srca@token`, JSON.stringify(token))
			const parts = token.split(".");
			const data = parts[1];
			const decodedPayload = window.atob(data);
			const jsonPayload = JSON.parse(decodedPayload);
			localStorage.setItem(`srca@user`, JSON.stringify(jsonPayload))
			setUser(user)
			return true
			}

			localStorage.removeItem(`srca@token`)
			await setError(response?.data)
			return false

		}catch (e) {
      await setError({"response": 'Erro ao conectar no servidor', "status": 500})
			localStorage.removeItem(`srca@token`)
			return false
		}
	}

	useEffect(() => {
		if (localStorage) {
			const tokenData = getToken()
			const userData = getUser()
			if (tokenData) {
				setToken(tokenData)
				setUser(userData)
			}
		}
	}, [])
	return (
		<UserProviderContext.Provider value={{signIn, user, token, getToken, error, logout, }}>
			{children}
		</UserProviderContext.Provider>
	)
}
export function useUser() {
	return useContext(UserProviderContext)
}