import { ActionIcon, Button, Group, Modal, SimpleGrid } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useDisclosure } from "@mantine/hooks";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconPencil } from "@tabler/icons";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { SubTitlePage } from "../../../../components/SubTitlePage";
import { DateWithoutGmt } from "../../../../helpers/CreateObjectDateWithoutGmt";
import { transformDate } from "../../../../helpers/transformDate";
import pfee from "../../../../services/pfee";

interface Props {
  id: string;
  reload: () => void;
}

export default function ModalEditPfeeDate({ id, reload }: Props) {
  const [opened, { open, close }] = useDisclosure(false);

  const [dataInicial, setDataInicial] = useState<any>("");
  const [dataFinal, setDataFinal] = useState<any>("");
  const [dataPfee, setDataPfee] = useState<any>("");
  const [loading, setLoading] = useState(false);

  const handleChangeDateInicial = (date: any) => {
    const newdate = DateWithoutGmt(date);

    setDataInicial(newdate);
  };

  const handleChangeDateFinal = (date: any) => {
    const newdate = DateWithoutGmt(date);

    setDataFinal(newdate);
  };

  const handleChangeDatePfee = (date: any) => {
    const newdate = DateWithoutGmt(date);

    setDataPfee(newdate);
  };

  const fetchData = async () => {
    setLoading(true);
    pfee
      .GetByUniqueById(id)
      .then((res: AxiosResponse<any>) => {
        setLoading(false);
        const data = res.data.data[0];
        setDataInicial(DateWithoutGmt(data.data_inicial));
        setDataFinal(DateWithoutGmt(data.data_final));
        setDataPfee(DateWithoutGmt(data.data_pfee));
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  async function handleUpdateData() {
    setLoading(true);
    showNotification({
      id: "load-data",
      loading: true,
      title: "Editando data pfee",
      message: "Aguarde...",
      autoClose: true,
      disallowClose: true,
    });
    //const dateNew = await transformDate(date);
    const dataInicialPythonFormat = transformDate(dataInicial);
    const dataFinalPythonFormat = transformDate(dataFinal);
    const dataPfeePythonFormat = transformDate(dataPfee);
    await pfee
      .EditByUniqueById(
        id,
        dataInicialPythonFormat,
        dataFinalPythonFormat,
        dataPfeePythonFormat
      )
      .then((value) => {
        updateNotification({
          id: "load-data",
          color: "teal",
          title: "Sucesso!",
          message: `Pfee editado com Sucesso!`,
          autoClose: 2000,
        });
        setLoading(false);
        reload();

        return;
      })
      .catch((err) => {
        updateNotification({
          id: "load-data",
          title: "Erro!",
          message: `Ocorreu erro ao editar pfee!`,
          autoClose: 2000,
          color: "red",
        });
        setLoading(false);
      });
  }
  return (
    <>
      <Modal
        opened={opened}
        size="xs"
        onClose={close}
        dir="row"
        withCloseButton={false}
      >
        <SubTitlePage title="Editar Pfee Data"></SubTitlePage>
        <SimpleGrid cols={1} breakpoints={[{ maxWidth: "sm", cols: 1 }]}>
          <DatePicker
            value={DateWithoutGmt(dataInicial)}
            label="Data Inicial"
            fullWidth
            placeholder="Data Inicial"
            withAsterisk
            onChange={(e) => handleChangeDateInicial(e)}
          />
          <DatePicker
            value={DateWithoutGmt(dataFinal)}
            label="Data Final"
            fullWidth
            placeholder="Data Inicial"
            withAsterisk
            onChange={(e) => handleChangeDateFinal(e)}
          />
          <DatePicker
            value={DateWithoutGmt(dataPfee)}
            label="Data Pfee"
            fullWidth
            placeholder="Data Inicial"
            withAsterisk
            onChange={(e) => handleChangeDatePfee(e)}
          />
        </SimpleGrid>
        <Button
          fullWidth
          size="md"
          mt="xs"
          onClick={() => handleUpdateData()}
          loading={loading}
        >
          Salvar Edição
        </Button>
      </Modal>

      <Group position="center">
        <ActionIcon>
          <IconPencil size={25} stroke={1} onClick={open} />
        </ActionIcon>
      </Group>
    </>
  );
}
