import {
  Button,
  Group,
  Modal,
  SimpleGrid,
  TextInput,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useDisclosure } from "@mantine/hooks";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconPencil, IconWallet } from "@tabler/icons";
import { useEffect, useState } from "react";
import { TitleBasic } from "../../../components/TitleBasic";
import oddPositions from "../../../services/odd_positions";
import { InputMask } from "../../../components/InputMask/MaskInput";
import { MenuOpcoesCarteiras } from "../../../components/MenuOpcoesCarteiras";
import {Control, useController, UseControllerProps, useForm, UseFormResetField} from "react-hook-form";
import { Input } from "../../../components/Input/Index";
import { useParams } from "react-router-dom";
import { CustomDatePicker } from "../../../components/InputDate";
import { DateWithoutGmt } from "../../../helpers/CreateObjectDateWithoutGmt";
import { transformDateV2 } from "../../../helpers/transformDateV2";
import req from '../../../services/odd_positions'


export default function ModalEditOddPosition({refetch, id}:any) {
  const [opened, { open, close }] = useDisclosure(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({})
  const { carteira } = useParams();
  const {
		control,
		trigger,
		clearErrors,
		watch,
		getValues,
		resetField,
    setValue,
		reset
	} = useForm<any>()

  useEffect(() =>{
    req.getOneById(id).then((res:any) =>{
      let data = res.data.data

      data.data_inicial = DateWithoutGmt(data.data_inicial)
      data.data_final = DateWithoutGmt(data.data_final)
      data.data_vencimento = DateWithoutGmt(data.data_vencimento)

      Object.keys(res.data?.data).forEach((key) => {
        setValue(key, res.data?.data[key]);
      });
    })
  }, [id])

  async function handlePostData() {
    setLoading(true);
    showNotification({
      id: "load-data",
      loading: true,
      title: "Editando nova Posição Odd",
      message: "Aguarde...",
      autoClose: true,
      disallowClose: true,
    });

    const payload = getValues()

    if (payload.data_inicial) {
      payload.data_inicial = transformDateV2(payload.data_inicial);
  } else {
      delete payload.data_inicial;
  }
  
if (payload.data_final) {
      payload.data_final = transformDateV2(payload.data_final);
  } else {
      delete payload.data_final;
  }

if (payload.data_vencimento) {
      payload.data_vencimento = transformDateV2(payload.data_vencimento);
  } else {
      delete payload.data_vencimento;
  }
  
    oddPositions.update({...payload})
    .then((value) => {
      console.log(value.data)
       updateNotification({
          id: "load-data",
          color: "teal",
          title: "Sucesso!",
          message: `Odd Editada com sucesso!`,
          autoClose: 2000,
        });
        refetch()
        return setLoading(false);
        
      })
      .catch((err) => {
        alert('teste')
        updateNotification({
          id: "load-data",
          title: "Erro!",
          message: `Ocorreu erro ao editar Odd Position!: ${err.error}`,
          autoClose: 2000,
          color: "red",
        });
        setLoading(false);
      });
  }
  return (
    <>
      <Modal
        opened={opened}
        size="md"
        onClose={close}
        dir="row"
        withCloseButton={false}
      >
        <TitleBasic title="Editar Odd Position"></TitleBasic>
        <SimpleGrid cols={2} breakpoints={[{ maxWidth: "sm", cols: 1 }]}>
        <Input label="Ativo" placeholder="Digite o ativo" resetField={resetField} clearable={true} control={control} name={'ativo'} isRequired></Input>
        <Input label="Classe" placeholder="Digite a classe" resetField={resetField} clearable={true} control={control} name={'classe'}></Input>
        <Input label="CNPJ" placeholder="Digite o CNPJ" resetField={resetField} clearable={true} control={control} name={'cnpj'} isRequired></Input>
        <CustomDatePicker name="data_inicial" control={control} label="Data Inicial"/>
        <CustomDatePicker name="data_final" control={control} label="Data Final"/>
        <CustomDatePicker name="data_vencimento" control={control} label="Data Vencimento"/>
        <Input label="Tipo" placeholder="Digite o tipo" resetField={resetField} clearable={true} control={control} name={'qtd'}></Input> 
        <Input label="Side" placeholder="Digite o side" resetField={resetField} clearable={true} control={control} name={'side'}></Input>
        <Input label="Classe" placeholder="Digite a classe" resetField={resetField} clearable={true} control={control} name={'tipo'}></Input>
      

        </SimpleGrid>
        
        <Button
          fullWidth
          size="md"
          mt="xs"
          onClick={() => handlePostData()}
          loading={loading}
        >
          Salvar Edição
        </Button>
      </Modal>

      <Group position="center" >
        <IconPencil size={25} stroke={1} onClick={open} cursor={'pointer'} />
      </Group>
   
    </>
  );
}

