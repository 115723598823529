import { Button, Group, Modal, SimpleGrid, TextInput } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconEdit } from "@tabler/icons";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SubTitlePage } from "../../../../components/SubTitlePage";
import carteira from "../../../../services/carteira";
import { InputMaskPercent } from "../../../InputMask/InputMaskPercentage";
import { InputMask } from "../../../InputMask/MaskInput";

interface Props {
  data: any;
  refetch: () => void;
}

export default function ModalEditCarteiraInfo({ data, refetch }: Props) {
  const [opened, { open, close }] = useDisclosure(false);
  const { id } = useParams();
  const [fatorPctBenchmark, setFatorPctBenchmark] = useState<any>("");
  const [taxaAdm, setTaxaAdm] = useState("");
  const [taxaPfee, setTaxaPfee] = useState("");
  const [valorAdmMin, setValorAdmMin] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFatorPctBenchmark(data.fator_pct_benchmark);
    setTaxaAdm(data.taxa_adm);
    setTaxaPfee(data.taxa_pfee);
    setValorAdmMin(data.valor_adm_min);
  }, [data]);

  async function handleUpdateData() {
    setLoading(true);
    showNotification({
      id: "load-data",
      loading: true,
      title: "Editando dados da Carteira",
      message: "Aguarde...",
      autoClose: true,
      disallowClose: true,
    });

    await carteira
      .EditStaticWalletInfoById(
        id ? id : "",
        taxaAdm,
        taxaPfee,
        valorAdmMin,
        fatorPctBenchmark
      )
      .then((value) => {
        updateNotification({
          id: "load-data",
          color: "teal",
          title: "Sucesso!",
          message: `Carteira editada com sucesso!`,
          autoClose: 2000,
        });
        refetch();
        setLoading(false);
        return;
      })
      .catch((err) => {
        updateNotification({
          id: "load-data",
          title: "Erro!",
          message: `Ocorreu erro ao editar a carteira!`,
          autoClose: 2000,
          color: "red",
        });
        setLoading(false);
      });
  }
  return (
    <>
      <Modal
        opened={opened}
        size="sm"
        onClose={close}
        dir="row"
        withCloseButton={false}
      >
        <SubTitlePage title="Editar Informações"></SubTitlePage>
        <SimpleGrid cols={2} breakpoints={[{ maxWidth: "sm", cols: 1 }]}>
          <InputMaskPercent
            defaultValue={taxaAdm}
            onChange={setTaxaAdm}
            label={"Tx. Adm"}
          />

          <InputMask
            defaultValue={valorAdmMin}
            label="Adm Min"
            placeholder="R$"
            onChange={setValorAdmMin}
          />

          <InputMaskPercent
            defaultValue={taxaPfee}
            onChange={setTaxaPfee}
            label={"Taxa de Pfee"}
          />

          <TextInput
            label="Fator Pct Benchmack"
            placeholder="%"
            value={fatorPctBenchmark}
            required
            onChange={(e) => setFatorPctBenchmark(e.target.value)}
          />
        </SimpleGrid>
        <Button
          fullWidth
          size="md"
          mt="xs"
          onClick={() => handleUpdateData()}
          loading={loading}
        >
          Salvar Edição
        </Button>
      </Modal>

      <Group position="center">
        <Button
          variant="subtle"
          size="xs"
          leftIcon={<IconEdit />}
          onClick={open}
        >
          Editar
        </Button>
      </Group>
    </>
  );
}
