import { Button, Flex, LoadingOverlay, SimpleGrid } from "@mantine/core";
import { Table } from "../../../components/Table";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { TitlePage } from "../../../components/TitlePage";


import { tableColumns } from "./tableColumns"
import req from '../../../services/RiskPoliceDimension'
import NovoDimensionModal from "./NovoDimensionModal";
import { showNotification, updateNotification } from "@mantine/notifications";

interface IRiskDimension {
  field: string;
  id: number;
  name: string;
  value: number;
}

export default function TesteTabela() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [idIsLoading, setIdIsLoading] = useState<undefined|number>()

  const [tableConfig, setTableConfig] = useState({
		page: 1,
		last_page: 1,
	})

  const onDelete = async (id: number) => {

    showNotification({
      id: "load-data",
      loading: true,
      title: "Deletando",
      message: "Aguarde...",
      autoClose: true,
      disallowClose: true,
    });
		setIdIsLoading(id)
		const response = await req.delete(id)
		setIdIsLoading(undefined)

    if (response?.status !== 200)  {
      return updateNotification({
        id: "load-data",
        color: "red",
        title: "Error!",
        message: `${response?.response?.data.message} || "Erro ao excluir"`,
        autoClose: 2000,
      });
		}
    
    updateNotification({
      id: "load-data",
      color: "green",
      title: "Sucesso!",
      message: `Excluido com sucesso!`,
      autoClose: 2000,
    });

		fetchData()
	}

  const fetchData = async (page?:number) => {
    setLoading(true);
    req.getAll().then((res: AxiosResponse<any>) => {
        setLoading(false);
        setData(res.data.data);

      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };

  useEffect(() => {
    fetchData()
  },[])

  return (
    <>
      <SimpleGrid cols={1}>
        <Flex justify={"space-between"}>
        <TitlePage title={`Risk Dimensions`} />
          <NovoDimensionModal reload={fetchData}></NovoDimensionModal>
        </Flex>
        <Flex w={"100%"} align={"center"} justify="center" direction={"column"}>
        <Table data={data} columns={tableColumns({ onDelete,  idIsLoading, refetch: fetchData })} pageCount={tableConfig?.last_page} onPageChange={(page) => {fetchData(page)}} />
          <Flex  p="xs" justify={"center"} w={"100%"} align={"flex-end"}></Flex>
        </Flex>
      </SimpleGrid>
    </>
  );
}
