import { InputBase } from "@mantine/core";
import "cleave.js/dist/addons/cleave-phone.br";
import CurrencyInput from "react-currency-input-field";

interface InputMaskPercentageProps {
  label?: string;
  onChange: (value: any) => void;
  placeholder?: string;
  defaultValue?: string;
}

export function InputMaskQtd({
  label,
  onChange,
  defaultValue,
  placeholder,
}: InputMaskPercentageProps) {
  return (
    <>
      <InputBase
        label={label}
        component={CurrencyInput}
        defaultValue={defaultValue}
        placeholder={placeholder}
        groupSeparator="."
        decimalsLimit={8}
        decimalSeparator=","
        onValueChange={(value) => {
          const removeComma = parseFloat(value?.replace(",", ".") || "");
          return onChange(removeComma);
        }}
      />
    </>
  );
}
