import { Button, Flex, Group, Modal, SimpleGrid } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconAbacusOff, IconCalendarEvent, IconPlus } from "@tabler/icons";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { SubTitlePage } from "../../../../components/SubTitlePage";
import req from "../../../../services/RiskPoliceDimension";
import { Input } from "../../../../components/Input/Index";
import { useForm } from "react-hook-form";

interface Props {
  reload: () => void;
}

export default function NovoDimensionModal({ reload }: Props) {
  const [opened, { open, close }] = useDisclosure(false);
  const { id } = useParams();

  const [loading, setLoading] = useState(false);

  const {
		control,
		trigger,
		clearErrors,
		watch,
		getValues,
		setError,
		resetField,
    reset
	} = useForm<any>()


  async function handlePostData() {
    if (!await trigger()) {
			return
		}

    showNotification({
      id: "load-data",
      loading: true,
      title: "Criando",
      message: "Aguarde...",
      autoClose: true,
      disallowClose: true,
    });
    setLoading(true);
		const payload = getValues()

    if (!(await req.create(payload))) {
      setLoading(false);
        updateNotification({
          id: "load-data",
          color: "red",
          title: "Error!",
          message: `Erro ao criar`,
          autoClose: 2000,
        });
			return
		}
    setLoading(false);
    reload()
    reset({})
    updateNotification({
      id: "load-data",
      color: "teal",
      title: "Sucesso!",
      message: `Criado com sucesso!`,
      autoClose: 2000,
    });

   // await req.create({})
}
  return (
    <>
      <Modal
        opened={opened}
        size="sm"
        onClose={close}
        dir="row"
        withCloseButton={false}
      >
        <SubTitlePage title="Criar novo Dimension"></SubTitlePage>
        <Flex className="!gap-2 flex-col">
          <Input resetField={resetField} clearable={true} control={control}
								rules={{required: "Campo obrigatório"}} name={'name'}
								labelClassName={`w-full`} label={"Nome"}/>
          <Input resetField={resetField} clearable={true} control={control}
								rules={{required: "Campo obrigatório"}} name={'field'}
								labelClassName={`w-full`} label={"Campo"}/>
          <Input type="number" resetField={resetField} clearable={true} control={control}
								rules={{required: "Campo obrigatório"}} name={'value'}
								labelClassName={`w-full`} label={"Valor"}/>
        </Flex>
        <Button
          fullWidth
          size="md"
          mt="xs"
          onClick={() => handlePostData()}
          loading={loading}
        >
          Criar
        </Button>
      </Modal>

      <Group position="center">
        <Button size="xs" leftIcon={<IconPlus />} onClick={open}>
          Novo Dimension
        </Button>
      </Group>
    </>
  );
}
