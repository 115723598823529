import {ColumnProps} from "../../../types";
type TableColumnsProps = {
}
export const tableColumns = ({}: TableColumnsProps): ColumnProps[] => {
	return [
		{
			id: "id",
			header: "ID"
		},
		{
			id: "nome",
			header: "Nome"
		},
		{
			id: "corretora",
			header: "Corretora"
		},
		
	]
}