import React, { useEffect } from 'react';
import { Checkbox } from '@mantine/core';
import { UseControllerProps, useController } from 'react-hook-form';

type CheckBoxProps = Partial<UseControllerProps> & {
  label?: string;
  name: string;
};

export function CheckBox({ label, name, control, rules }: CheckBoxProps) {
  const { field } = useController({
    control,
    defaultValue: '',
    name,
    rules,
  });

  useEffect(() => {
    field.onChange(field.value);
  }, [field.value]);

  return (
    <Checkbox
      {...field}
      label={label}
      checked={!!field.value}
    />
  );
}
