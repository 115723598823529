import { Button, Group, Modal, SimpleGrid } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useDisclosure } from "@mantine/hooks";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconCalendarEvent } from "@tabler/icons";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { SubTitlePage } from "../../../../components/SubTitlePage";
import { DateWithoutGmt } from "../../../../helpers/CreateObjectDateWithoutGmt";
import { transformDate } from "../../../../helpers/transformDate";
import pfee from "../../../../services/pfee";

interface Props {
  reload: () => void;
}

export default function NovoPfeeModal({ reload }: Props) {
  const [opened, { open, close }] = useDisclosure(false);
  const { id } = useParams();

  const [dataInicial, setDataInicial] = useState<any>();
  const [dataFinal, setDataFinal] = useState<any>();
  const [dataPfee, setDataPfee] = useState<any>();
  const [loading, setLoading] = useState(false);

  const handleChangeDateInicial = (date: any) => {
    const newdate = DateWithoutGmt(date);

    setDataInicial(newdate);
  };

  const handleChangeDateFinal = (date: any) => {
    const newdate = DateWithoutGmt(date);

    setDataFinal(newdate);
  };

  const handleChangeDatePfee = (date: any) => {
    const newdate = DateWithoutGmt(date);

    setDataPfee(newdate);
  };

  async function handlePostData() {
    setLoading(true);
    showNotification({
      id: "load-data",
      loading: true,
      title: "Criando novo Pfee",
      message: "Aguarde...",
      autoClose: true,
      disallowClose: true,
    });
    const dataInicialPythonFormat = transformDate(dataInicial);
    const dataFinalPythonFormat = transformDate(dataFinal);
    const dataPfeePythonFormat = transformDate(dataPfee);
    await pfee
      .create(
        id ? id : "",
        dataInicialPythonFormat,
        dataFinalPythonFormat,
        dataPfeePythonFormat
      )
      .then((value) => {
        updateNotification({
          id: "load-data",
          color: "teal",
          title: "Sucesso!",
          message: `Pfee criado com sucesso!`,
          autoClose: 2000,
        });
        close();
        setLoading(false);
        reload();

        return;
      })
      .catch((err) => {
        updateNotification({
          id: "load-data",
          title: "Erro!",
          message: `Ocorreu erro ao criar novo pfee!`,
          autoClose: 2000,
          color: "red",
        });
        setLoading(false);
      });
  }
  return (
    <>
      <Modal
        opened={opened}
        size="sm"
        onClose={close}
        dir="row"
        withCloseButton={false}
      >
        <SubTitlePage title="Adicionar Novo Pfee Data"></SubTitlePage>
        <SimpleGrid cols={2} breakpoints={[{ maxWidth: "sm", cols: 1 }]}>
          <DatePicker
            value={dataInicial}
            label="Data Inicial"
            fullWidth
            placeholder="Data Inicial"
            withAsterisk
            onChange={(e) => handleChangeDateInicial(e)}
          />

          <DatePicker
            value={dataFinal}
            label="Data Final"
            fullWidth
            placeholder="Data Inicial"
            withAsterisk
            onChange={(e) => handleChangeDateFinal(e)}
          />

          <DatePicker
            value={dataPfee}
            label="Data Pfee"
            fullWidth
            placeholder="Data Inicial"
            withAsterisk
            onChange={(e) => handleChangeDatePfee(e)}
          />
        </SimpleGrid>
        <Button
          fullWidth
          size="md"
          mt="xs"
          onClick={() => handlePostData()}
          loading={loading}
        >
          Cadastrar Pfee Data
        </Button>
      </Modal>

      <Group position="center">
        <Button size="xs" leftIcon={<IconCalendarEvent />} onClick={open}>
          Nova Data Pfee
        </Button>
      </Group>
    </>
  );
}
