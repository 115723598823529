
import {
  ColorScheme,
  ColorSchemeProvider,
  MantineProvider,
  createEmotionCache
} from "@mantine/core";
import { useHotkeys, useLocalStorage } from "@mantine/hooks";
import { ModalsProvider } from "@mantine/modals";
import { AppUiProvider } from "./context/AppUiContext";
import { AuthProvider } from "./context/authContext";
import { Rotas } from "./routes/index";
import "./styles/globals.css";
import { UserProvider } from "./hooks/useUser";


export default function App() {
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: "mantine-color-scheme",
    defaultValue: "light",
    getInitialValueInEffect: true,
  });

  const myCache = createEmotionCache({
    key: 'mantine',
    prepend: false
  });

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

  useHotkeys([["mod+J", () => toggleColorScheme()]]);
  return (
    <div className="App">
      <ColorSchemeProvider
        colorScheme={colorScheme}
        toggleColorScheme={toggleColorScheme}
      >
        <MantineProvider
        emotionCache={myCache}
          theme={{
            loader: "dots",
            headings: {
              fontWeight: 800,
              fontFamily: "Inter",
              color: "#6b6a6c",
            },
            defaultGradient: {
              from: "orange",
              to: "red",
              deg: 45,
            },
            breakpoints: {
              xs: 500,
              sm: 800,
              md: 1000,
              lg: 1200,
              xl: 1400,
            },
            fontFamily: "Inter, sans-serif",
            colorScheme: "light",
            fontSizes: { md: 14 },
            radius: { sm: 7 },
            colors: {
              blue: [
                "#e8f4fc",
                "#d0dae2",
                "#b4c2cb",
                "#97a9b5",
                "#7a919f",
                "#617786",
                "#4a5d68",
                "#34424b",
                "#1c282f",
                "#010e15",
              ],
            },
            primaryColor: "blue",
          }}
          withCSSVariables
          withGlobalStyles
          withNormalizeCSS
        >
          <AuthProvider>
            <UserProvider>
            <ModalsProvider>
              <AppUiProvider>
                <Rotas />
              </AppUiProvider>
            </ModalsProvider>
            </UserProvider>
          </AuthProvider>
        </MantineProvider>
      </ColorSchemeProvider>
    </div>
  );
}
