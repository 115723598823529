import api from "./api";

const req = {
  GetByNumeroCarteira: async (n_carteira: string) => {
    const response = await api.get(
      `cotizacao_adm_escalonada?carteira=${n_carteira}`
    );

    return response;
  },

  GetByUniqueById: async (id: string) => {
    const response = await api.get(`cotizacao_adm_escalonada_id?id=${id}`);

    return response;
  },

  EditByUniqueById: async (obj: object) => {
    const formData = new FormData();

    const response = await api.put(`cotizacao_adm_escalonada`, obj);

    return response;
  },

  create: async (obj: any) => {
    const response = await api.post(`cotizacao_adm_escalonada`, obj);

    return response;
  },

  delete: async (id: string) => {
    const response = await api.delete(`cotizacao_adm_escalonada?id=${id}`);

    return response;
  },
  
};

export default req;
