import {
  Button,
  Group,
  Modal,
  Select,
  SimpleGrid,
  TextInput,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useDisclosure } from "@mantine/hooks";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconWallet } from "@tabler/icons";
import { useState } from "react";
import { TitleBasic } from "../../../components/TitleBasic";
import carteira from "../../../services/carteira";
import { InputMask } from "../../../components/InputMask/MaskInput";
import { MenuOpcoesCarteiras } from "../../../components/MenuOpcoesCarteiras";

export default function NovaCarteiraModal() {
  const [opened, { open, close }] = useDisclosure(false);

  const [date, setDate] = useState<any>("");
  const [corretora, setCorretora] = useState<any>("");
  const [documento, setDocumento] = useState("");
  const [numeroCarteira, setNumeroCarteira] = useState("");
  const [nome, setNome] = useState("");
  const [loading, setLoading] = useState(false);
  const [linkScrap, setLinkScrap] = useState("");
  const [plInicial, setPlInicial] = useState<any>(null)

  async function transformDate(dateString: string) {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    const isoString = `${year}-${month}-${day}`;

    return isoString;
  }

  async function handlePostData() {
    setLoading(true);
    showNotification({
      id: "load-data",
      loading: true,
      title: "Cadastrando nova Carteira",
      message: "Aguarde...",
      autoClose: true,
      disallowClose: true,
    });
    const dateNew = await transformDate(date);
    await carteira
      .post(dateNew, corretora, documento, numeroCarteira, nome, linkScrap, plInicial)
      .then((value) => {
        updateNotification({
          id: "load-data",
          color: "teal",
          title: "Sucesso!",
          message: `Carteira Cadastrada com Sucesso!`,
          autoClose: 2000,
        });
        setLoading(false);
        setDate(null);
        setCorretora("");
        setDocumento("");
        setNumeroCarteira("");
        setNome("");
        return;
      })
      .catch((err) => {
        updateNotification({
          id: "load-data",
          title: "Erro!",
          message: `Ocorreu erro ao criar nova carteira!`,
          autoClose: 2000,
          color: "red",
        });
        setLoading(false);
      });
  }
  return (
    <>
      <Modal
        opened={opened}
        size="md"
        onClose={close}
        dir="row"
        withCloseButton={false}
      >
        <TitleBasic title="Adicionar Nova Carteira"></TitleBasic>
        <SimpleGrid cols={2} breakpoints={[{ maxWidth: "sm", cols: 1 }]}>
          <TextInput
            label="Nome do Cliente"
            placeholder="Marlon Cunha"
            value={nome}
            required
            onChange={(e) => setNome(e.target.value)}
          />
          <TextInput
            value={documento}
            label="Documento"
            placeholder="15554577709"
            required
            onChange={(e) => setDocumento(e.target.value)}
          />

          <TextInput
            label="Carteira"
            value={numeroCarteira}
            placeholder="123456"
            required
            onChange={(e) => setNumeroCarteira(e.target.value)}
          />
          <DatePicker
            value={date}
            label="Data Inicial"
            fullWidth
            placeholder="Data Inicial"
            withAsterisk
            onChange={(e) => setDate(e ? e : "")}
          />
          <Select
            label="Corretora"
            placeholder="Selecione uma corretora"
            value={corretora}
            data={[
              { value: "Xp", label: "XP" },
              { value: "BTG Pactual", label: "BTG" },
            ]}
            onChange={(e) => setCorretora(e)}
          />
          {corretora === "Xp" ? (
            <TextInput
              label="Link Scrap"
              value={linkScrap}
              placeholder="Link Scrap"
              required
              onChange={(e) => setLinkScrap(e.target.value)}
            />
          ) : (
            <></>
          )}
          <InputMask
            label="PL Inicial (Opcional)"
            placeholder="R$"
            onChange={setPlInicial}
          />
        </SimpleGrid>
        <Button
          fullWidth
          size="md"
          mt="xs"
          onClick={() => handlePostData()}
          loading={loading}
        >
          Cadastrar Cliente
        </Button>
      </Modal>

      <Group position="center">
        <Button leftIcon={<IconWallet />} onClick={open}>
          Nova Carteira
        </Button>
        <MenuOpcoesCarteiras />
      </Group>
   
    </>
  );
}
