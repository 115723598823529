import api from "./api";

const req = {
  getAllByWallet: async (carteira:any, date:any) => {
    const response = await api.get(`/adjust_odd_positions?carteira=${carteira}&date=${date}`);

    return response;
  },

  getOneById: async (id:any) => {
    const response = await api.get(`/adjust_odd_positions?id=${id}`);

    return response;
  },

  create: async (params:any) => {
    const response = await api.post(`/adjust_odd_positions`, params);

    return response;
  },

  update: async (params:any) => {
    const response = await api.put(`/adjust_odd_positions`, params);

    return response;
  },

  delete: async (id: number) => {
    const response = await api.delete(`adjust_odd_positions?id=${id}`);

    return response;
  },

  AvancarCarteira: async (params:any) => {
    const response = await api.post(`/odd_positions_advance`, params);

    return response;
  },

  RetroagirCarteira: async (params:any) => {
    const response = await api.post(`/retroagir_odd_positions`, params);

    return response;
  },
};

export default req;
