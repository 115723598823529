import { Button, Group, Modal, SimpleGrid } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useDisclosure } from "@mantine/hooks";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconCalendarTime } from "@tabler/icons";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { SubTitlePage } from "../../../../components/SubTitlePage";
import { transformDate } from "../../../../helpers/transformDate";
import carteira from "../../../../services/carteira";

export default function RetroagirCarteiraModal() {
  const [opened, { open, close }] = useDisclosure(false);
  const { id } = useParams();

  const [dataCorte, setDataCorte] = useState<any>();
  const [loading, setLoading] = useState(false);

  function handleChangeDataCorte(date: any) {
    setDataCorte(date);
  }

  async function handlePostData() {
    setLoading(true);
    showNotification({
      id: "load-data",
      loading: true,
      title: "Retroagindo Cotas",
      message: "Aguarde...",
      autoClose: true,
      disallowClose: true,
    });
    await carteira
      .RetroagirCotas(transformDate(dataCorte), id ? id : "")
      .then((value) => {
        updateNotification({
          id: "load-data",
          color: "teal",
          title: "Sucesso!",
          message: `Cotas retroagidas com sucesso!`,
          autoClose: 2000,
        });
        setLoading(false);

        return;
      })
      .catch((err) => {
        updateNotification({
          id: "load-data",
          title: "Erro!",
          message: `Erro ao retroagir cotas!`,
          autoClose: 2000,
          color: "red",
        });
        setLoading(false);
      });
  }

  return (
    <>
      <Modal
        opened={opened}
        size="sm"
        onClose={close}
        dir="row"
        withCloseButton={false}
      >
        <SubTitlePage title="Retroagir Carteira"></SubTitlePage>
        <SimpleGrid cols={1} breakpoints={[{ maxWidth: "sm", cols: 1 }]}>
          <DatePicker
            value={dataCorte}
            label="Data para retroagir"
            fullWidth
            placeholder="Data para retroagir"
            withAsterisk
            onChange={(e) => handleChangeDataCorte(e)}
          />
        </SimpleGrid>
        <Button
          fullWidth
          size="md"
          mt="xs"
          onClick={() => handlePostData()}
          loading={loading}
        >
          Retroagir Carteira
        </Button>
      </Modal>

      <Group position="center">
        <Button size="xs" leftIcon={<IconCalendarTime />} onClick={open}>
          Retroagir Carteira
        </Button>
      </Group>
    </>
  );
}
