import { Center, Flex, LoadingOverlay, SimpleGrid, Table } from "@mantine/core";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TitlePage } from "../../../components/TitlePage";
import { DateWithoutGmt } from "../../../helpers/CreateObjectDateWithoutGmt";
import relatorios from "../../../services/relatorios";
import EditarSinistroModal from "./EditarSinistroModal";

export default function Sinistro() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [date, setDate] = useState<any>(new Date());

  const navigate = useNavigate();

  const handleChangeDate = (date: any) => {
    const newdate = DateWithoutGmt(date);

    setDate(newdate);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    relatorios
      .Sinistro()
      .then((res: AxiosResponse<any>) => {
        setLoading(false);
        setData(res.data);
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };

  const rows = data?.asset_outliers?.map((element: any, idx: any) => (
    <tr key={element.id}>
      <td>{element.id}</td>
      <td>{element.carteira}</td>
      <td>{element.data}</td>

      <td>{element.nav}</td>
      <td>{element.ativo_final}</td>
      <td>
        <Center>
          <EditarSinistroModal
            n_carteira={element.carteira}
            id_position={element.id}
            value_position={element.ativo_final}
            date={element.data}
          ></EditarSinistroModal>
        </Center>
      </td>
    </tr>
  ));

  return (
    <>
      <SimpleGrid cols={1}>
        <Flex align={"left"} direction="column">
          <TitlePage title={`Relatório Sinistros`} />
        </Flex>

        <Flex w={"100%"} align={"center"} justify="center" direction={"column"}>
          <div style={{ width: "100%", position: "relative" }}>
            <LoadingOverlay visible={loading} overlayBlur={2} />
            <Table striped highlightOnHover withColumnBorders fontSize={12}>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Carteira</th>
                  <th>Data</th>
                  <th>Nav</th>
                  <th>Ativo</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </div>

          <Flex p="xs" justify={"center"} w={"100%"} align={"flex-end"}></Flex>
        </Flex>
      </SimpleGrid>
    </>
  );
}
