import { Button, Group, Modal, SimpleGrid, Text } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useDisclosure } from "@mantine/hooks";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconChevronsRight } from "@tabler/icons";
import { useState } from "react";
import { TitleBasic } from "../../../../components/TitleBasic";
import { transformDateV2 } from "../../../../helpers/transformDateV2";
import serviceCarteira from "../../../../services/carteira";

interface Props {
  carteira: any;
}

export default function ProcesssarCarteiraClienteModal({ carteira }: Props) {
  const [opened, { open, close }] = useDisclosure(false);

  const [date, setDate] = useState<any>("");
  const [loading, setLoading] = useState(false);

  async function handlePostData() {
    if (!date){
      showNotification({
        id: "error",
        title: 'Erro!',
        message: "A data não pode ser vazia!",
        autoClose: false,
        color: 'red'
      });

      return
    }

    setLoading(true);

    showNotification({
      id: "load-data",
      loading: true,
      title: "Processando Carteiras",
      message: "A operação pode demorar alguns minutos, aguarde!",
      autoClose: false,
    });
    const DateTransform = transformDateV2(date);
    const response = await serviceCarteira.ProcessarCarteiras([carteira], DateTransform)

    if (response.status === 200 || response.status === 204){
      updateNotification({
        id: "load-data",
        color: "teal",
        title: "Sucesso!",
        message: `Carteiras processadas com sucesso!`,
        autoClose: false,
      });
      setLoading(false);
      setDate(null);
      return
    }

        updateNotification({
          id: "load-data",
          title: "Erro!",
          message: response.response.data.error 
          ? `${response.response.data.error }`
          : 'Ocorreu erro ao processar as carteiras!',
          autoClose: 200000,
          color: "red",
        });
        setLoading(false);
  }
  return (
    <>
      <Modal
        opened={opened}
        size="md"
        onClose={close}
        dir="row"
        withCloseButton={false}
      >
        <TitleBasic title={`Processar Carteira ${carteira}`}></TitleBasic>
        <SimpleGrid cols={1} breakpoints={[{ maxWidth: "sm", cols: 1 }]}>
          <DatePicker
            value={date}
            label="Data de Corte"
            fullWidth
            placeholder="Data de Corte"
            withAsterisk
            onChange={(e) => setDate(e ? e : "")}
          />
        </SimpleGrid>
        <Button
          fullWidth
          size="md"
          mt="xs"
          onClick={() => handlePostData()}
          loading={loading}
        >
          Iniciar
        </Button>
      </Modal>

      <Group position="center">
        <Button size='xs' bg="gray" onClick={open} leftIcon={<IconChevronsRight />}>
          Processar Carteira
        </Button>
      </Group>
    </>
  );
}
