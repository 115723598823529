import {
  AppShell,
  Burger,
  createStyles,
  Header,
  MediaQuery,
  Navbar,
  Title,
  useMantineColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { useState } from "react";
import { NavBarAlpha } from "../../components/navbar/navbar";
import { useAppUi } from "../../context/AppUiContext";
import { useAuth } from "../../context/authContext";
import { ToggleColor } from "../toggle/colorscheme";

export default function AppBase({ children }: any) {
  const theme = useMantineTheme();
  const { user } = useAuth();
  const [opened, setOpened] = useState(false);
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();

  const { menuOpen, handleChangeMenuOpen } = useAppUi();
  const useStyles = createStyles((theme) => ({
    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      background: theme.colors.blue[8],
    },
  }));
  const { classes } = useStyles();

  return (
    <AppShell
      styles={{
        main: {
          color:
            theme.colorScheme === colorScheme
              ? theme.colors.red[1]
              : theme.colors.dark[4],
          background:
            theme.colorScheme === colorScheme
              ? theme.colors.gray[1]
              : theme.colors.dark[4],
        },
      }}
      navbarOffsetBreakpoint={1400}
      asideOffsetBreakpoint="lg"
      navbar={
        <Navbar
          p="md"
          hiddenBreakpoint={1400}
          hidden={!menuOpen}
          width={{ sm: 300, lg: 300 }}
        >
          <NavBarAlpha />
        </Navbar>
      }
      header={
        <Header height={70} p="lg" className={classes.container}>
          <MediaQuery largerThan={1400} styles={{ display: "none" }}>
            <Burger
              opened={menuOpen}
              onClick={() => handleChangeMenuOpen()}
              size="md"
              color={theme.colors.gray[1]}
            />
          </MediaQuery>

          <Title
            color="blue"
            variant="gradient"
            gradient={{ from: "#ffffff", to: "#e8e8e8", deg: 95 }}
            inherit
            order={6}
          >
            <h1>SRCA Alphamar</h1>
          </Title>
          <ToggleColor></ToggleColor>
        </Header>
      }
    >
      {children}
    </AppShell>
  );
}
