import {
  Button,
  Flex,
} from "@mantine/core";

import { Table } from '../../components/Table'
import { useEffect, useState } from "react";
import { TitlePage } from "../../components/TitlePage";

import { tableColumns } from "./tableColumns"
import {
  IMovimentacaoXp,
  ImovimentacaoDataXp,
} from "../../interfaces/IMovimentacoesXp";
import { getPosicoes, insertNewProdutoClass } from "../../services/PosicoesAdmAndFunds";
import { AxiosResponse } from "axios";
import { useForm } from "react-hook-form";

export default function ClasseAtivos() {
  const [data, setData] = useState<IMovimentacaoXp[]>([]);
  const [idIsLoading, setIdIsLoading] = useState<undefined|string>()
  const [loading, setLoading] = useState(false)

  const fetchData = async () => {
    setLoading(true);
    getPosicoes().then((res: AxiosResponse<any>) => {
        setLoading(false);
        setData(res.data.data);

      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };

  useEffect(() => {
    fetchData()
  },[])

  const handleSave = async (productName: string) => {
    setIdIsLoading(productName)
    const valuesInputedTable = getValues();
    const productToSave = await valuesInputedTable.produtos[productName];
    await insertNewProdutoClass(productToSave)
    setIdIsLoading(undefined)
    await fetchData()
    

  };
  

  const {
    control,
    trigger,
    clearErrors,
    watch,
    getValues,
    resetField,
    reset,
  } = useForm<any>()

  return (
    <div className="App" style={{ height: "100vh" }}>
      <TitlePage title="Classe Ativos (Sem Classificação)"></TitlePage>
      <Flex w={"100%"} align={"center"} justify="center" direction={"column"}>

        <Table data={data} pageCount={3} page={1} columns={tableColumns({ idIsLoading, refetch: fetchData, control, handleSave })} />
          <Flex  p="xs" justify={"center"} w={"100%"} align={"flex-end"}></Flex>
      </Flex>

      
    </div>
  );
}

