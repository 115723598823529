import { Input } from "@mantine/core";
import { useId } from "@mantine/hooks";
import { IconBrandTwitter } from "@tabler/icons";
import "cleave.js/dist/addons/cleave-phone.br";
import { NumeralThousandsGroupStyleType } from "cleave.js/options";
import CleaveReact from "cleave.js/react";
import { useCallback, useEffect, useState } from "react";

interface InputMaskMoneyProps {
  label?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  defaultValue?: any;
}

export function InputMaskMoney({
  label,
  onChange,
  placeholder,
  defaultValue,
}: InputMaskMoneyProps) {
  const id = useId();
  const [value, setValue] = useState("");

  const options = {
    numeralThousandsGroupStyle: "thousand" as NumeralThousandsGroupStyleType,
    numeral: true,
    numericOnly: true,
    numeralDecimalMark: ",",
    numeralDecimalScale: 2,
    delimiter: ".",
    prefix: "R$ ",
    noImmediatePrefix: true,
  };

  useEffect(() => {
    onChange && onChange(value);
  }, [value, onChange]);

  const handleAccept = useCallback((event: any) => {
    setValue(event.target.rawValue);
  }, []);

  return (
    <Input.Wrapper id={id} label={label} required>
      <Input<any>
        id={id}
        icon={<IconBrandTwitter size="1rem" />}
        placeholder="Your twitter"
      />
      <div style={{ position: "relative" }}>
        <CleaveReact
          id={id}
          options={options}
          placeholder="R$ 0"
          onChange={handleAccept}
        />
        <Input
          value={value}
          style={{ position: "absolute", top: 0, left: 0, opacity: 0 }}
          onChange={() => {}}
        />
      </div>
    </Input.Wrapper>
  );
}
