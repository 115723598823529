import { IconPencil, IconTrash } from "@tabler/icons";
import { transformDateV2 } from "../../helpers/transformDateV2";
import {ColumnProps} from "../../types";
import { Flex } from "@mantine/core";
import odd_positions from '../../services/odd_positions'
import { Spinner } from "@chakra-ui/react";
import ModalEditOddPosition from "./EditOddPositionModal";

type TableColumnsProps = {
  onDelete: (id: number) => void
  idIsLoading: number|undefined,
  refetch:() => void
}
export const tableColumns = ({idIsLoading, onDelete, refetch }: TableColumnsProps) : ColumnProps[] => {
	return [
		{
			id: "id",
			header: "ID"
		},
		{
			id: "ativo",
			header: "Ativo"
		},
		{
			id: "carteira",
			header: "Carteira"
		},
    {
			id: "classe",
			header: "Classe"
		},
    {
			id: "cnpj",
			header: "CNPJ",
		},
    {
			id: "data_final",
			header: "Data Final",
      cell: info => {
				return (
				<p>{ info.row.original.data_final ? transformDateV2(info.row.original.data_final) : ''}</p>
				)
			}
		},
    {
			id: "data_inicial",
			header: "Data Inicial",
      cell: info => {
				return (
				<p>{info.row.original.data_inicial ? transformDateV2(info.row.original.data_inicial) : ''}</p>
				)
			}
		},
    {
			id: "data_vencimento",
			header: "Data Vencimento",
      cell: info => {
				return (
				<p>{info.row.original.data_vencimento ? transformDateV2(info.row.original.data_vencimento) : ''}</p>
				)
			}
		},
    {
			id: "qtd",
			header: "Qtd"
		},
    {
			id: "side",
			header: "Side"
		},
    {
			id: "tipo",
			header: "Tipo"
		},

    {
			header: "Opções",
      cell: (item) => <>
      <Flex className="gap-2">
      <ModalEditOddPosition id={item.row.original.id}  refetch={refetch}/>
      {item?.row?.original?.id === idIsLoading ? <Spinner></Spinner> :
      <IconTrash size={20} cursor={'pointer'} stroke={1} color="red" onClick={() => onDelete(item.row.original.id)}/>}
      </Flex> </>
		},
		
	]
}