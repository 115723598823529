import { ActionIcon, Button, Group, Modal, SimpleGrid } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconPencil } from "@tabler/icons";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { SubTitlePage } from "../../../../components/SubTitlePage";
import req from "../../../../services/AdmEscalonada";
import { InputMaskPercent } from "../../../InputMask/InputMaskPercentage";
import { InputMask } from "../../../InputMask/MaskInput";

interface Props {
  id: string;
  reload: () => void;
}

export default function ModalEditAdmEscalonada({ id, reload }: Props) {
  const [opened, { open, close }] = useDisclosure(false);

  const [patrimonio, setPatrimonio] = useState<any>("");
  const [txAdm, setTxAdm] = useState<any>("");
  const [carteira, setCarteira] = useState<any>("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (opened) {
      fetchData();
    }
  }, [opened]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    req
      .GetByUniqueById(id)
      .then((res: AxiosResponse<any>) => {
        setLoading(false);
        const data = res.data.data[0];
        setPatrimonio(data.volume);
        setTxAdm(data.tx_adm);
        setCarteira(data.carteira);
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };

  async function handleUpdateData() {
    setLoading(true);
    showNotification({
      id: "load-data",
      loading: true,
      title: "Editando taxa escalonada",
      message: "Aguarde...",
      autoClose: true,
      disallowClose: true,
    });
    const obj = { carteira: carteira, volume: patrimonio, tx_adm: txAdm, id };

    await req
      .EditByUniqueById(obj)
      .then((value) => {
        updateNotification({
          id: "load-data",
          color: "teal",
          title: "Sucesso!",
          message: `Taxa escalonada editado com Sucesso!`,
          autoClose: 2000,
        });
        reload();
        close();
        setLoading(false);

        return;
      })
      .catch((err) => {
        updateNotification({
          id: "load-data",
          title: "Erro!",
          message: `Ocorreu erro ao editar taxa escalonada!`,
          autoClose: 2000,
          color: "red",
        });
        setLoading(false);
      });
  }
  return (
    <>
      <Modal
        opened={opened}
        size="xs"
        onClose={close}
        dir="row"
        withCloseButton={false}
      >
        <SubTitlePage title="Editar Tx Escalonada"></SubTitlePage>
        <SimpleGrid cols={1} breakpoints={[{ maxWidth: "sm", cols: 1 }]}>
          <InputMask
            label="Patrimonio"
            placeholder="R$"
            onChange={setPatrimonio}
            defaultValue={patrimonio}
          />

          {/* <TextInput
            label="Taxa de Adm"
            value={txAdm}
            placeholder="0.5%"
            required
            onChange={(e) => setTxAdm(e.target.value)}
          /> */}
          <InputMaskPercent
            defaultValue={txAdm}
            onChange={setTxAdm}
            label={"Taxa Adm"}
          />
        </SimpleGrid>
        <Button
          fullWidth
          size="md"
          mt="xs"
          onClick={() => handleUpdateData()}
          loading={loading}
        >
          Salvar Edição
        </Button>
      </Modal>

      <Group position="center">
        <ActionIcon>
          <IconPencil size={25} stroke={1} onClick={open} />
        </ActionIcon>
      </Group>
    </>
  );
}
