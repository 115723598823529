import { Button, Group, Modal, SimpleGrid } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconCalendarEvent } from "@tabler/icons";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { InputMaskPercent } from "../../../../components/InputMask/InputMaskPercentage";
import { InputMask } from "../../../../components/InputMask/MaskInput";
import { SubTitlePage } from "../../../../components/SubTitlePage";
import req from "../../../../services/AdmEscalonada";

interface Props {
  reload: () => void;
}

export default function NovaTaxaEscalonada({ reload }: Props) {
  const [opened, { open, close }] = useDisclosure(false);
  const { id } = useParams();

  const [patrimonio, setPatrimonio] = useState<string>("");
  const [txAdm, setTxAdm] = useState<any>("");
  const [loading, setLoading] = useState(false);

  async function handlePostData() {
    setLoading(true);
    showNotification({
      id: "load-data",
      loading: true,
      title: "Criando nova taxa escalonada",
      message: "Aguarde...",
      autoClose: true,
      disallowClose: true,
    });
    const obj = {
      carteira: id,
      volume: patrimonio,
      tx_adm: txAdm,
    };
    await req
      .create(obj)
      .then((value) => {
        reload();
        updateNotification({
          id: "load-data",
          color: "teal",
          title: "Sucesso!",
          message: `Taxa escalonada criada com sucesso!`,
          autoClose: 2000,
        });
        close();
        setLoading(false);

        return;
      })
      .catch((err) => {
        updateNotification({
          id: "load-data",
          title: "Erro!",
          message: `Ocorreu erro ao criar nova data escalonada!`,
          autoClose: 2000,
          color: "red",
        });
        setLoading(false);
      });
  }
  return (
    <>
      <Modal
        opened={opened}
        size="sm"
        onClose={close}
        dir="row"
        withCloseButton={false}
      >
        <SubTitlePage title="Adicionar Nova Taxa Escalonada"></SubTitlePage>
        <SimpleGrid cols={1} breakpoints={[{ maxWidth: "sm", cols: 1 }]}>
          <InputMask
            label="Patrimonio"
            placeholder="R$"
            onChange={setPatrimonio}
          />
          
          <InputMaskPercent
            label="Taxa de Adm"
            placeholder="Digite Taxa Adm"
            onChange={setTxAdm}
          />
        </SimpleGrid>

        <Button
          fullWidth
          size="md"
          mt="xs"
          onClick={() => handlePostData()}
          loading={loading}
        >
          Cadastrar Nova Taxa
        </Button>
      </Modal>

      <Group position="center">
        <Button size="xs" leftIcon={<IconCalendarEvent />} onClick={open}>
          Nova Taxa Escalonada
        </Button>
      </Group>
    </>
  );
}
