import api from "./api";

const MovimentacoesBtg = {
  getByWalletId: async (params:any) => {
    return await api.get(`/movimentacoes-btg`,{params}).catch(({response}) => {
      return response
    })
  },

  delete: async (id: number) => {
    const response = await api.delete(`/movimentacoes-btg?id=${id}`);

    return response;
  },
};

export default MovimentacoesBtg;
