import {ReactNode, useEffect, useState} from "react";
import {
	Flex,
	Input as ChakraUiInput,
	InputGroup,
	InputLeftAddon,
	InputRightAddon,
	InputProps as ChakraUiInputProps,
	Text,
  InputRightElement
} from '@chakra-ui/react'

import {Control, useController, UseControllerProps, useForm, UseFormResetField} from "react-hook-form";

type InputProps = (ChakraUiInputProps & Partial<UseControllerProps<any, any>>) & {
    label?: string
    labelClassName?: string
    name?: string
    mask?: "BRL" | "USD" | "%"
    inputRef?: any
    disabled?: boolean
    rightIcon?: ReactNode
    rightIconClassName?: string
    groupClassName?: string
    clearable?: boolean
    onRightIconClick?: () => void
    resetField?: UseFormResetField<any>
}

export function Input({label, labelClassName, inputRef, disabled, groupClassName, rightIconClassName, clearable, resetField, onRightIconClick, name = "", mask,  ...input}: InputProps) {
	const {control: controlDefault} = useForm()
	const [type, setType] = useState(mask ? "number" : input.type)
	const {fieldState, field} = useController({
		control: input.control || controlDefault,
		rules: input.rules,
		name: name,
		defaultValue: input.defaultValue
	})

	return (
		<>
			<label className={`flex flex-col ${labelClassName ? labelClassName : ``}`}>
				{label && (
					<Text className={"mb-1 h3-semibold-uppercase line-clamp-1 text-gray_2"}>{label}</Text>
				)}
				<InputGroup className={`${groupClassName} `}>
					<ChakraUiInput
						{...input}
						{...field}
						ref={inputRef}
            pl={mask === 'BRL' ? '32px' : ''}
            sx={{
              // Estilo CSS para esconder os controles padrão do tipo number
              '::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
              },
              '::-moz-appearance': 'textfield', // Firefox
            }}
						type={type}
            disabled={disabled}
						className={`flex-1 w-full transition-all !rounded-md border border-solid border-gray-400 py-2 px-2 bg-transparent h2-semibold !text-[14px] text-black ${!!input.className && input.className} placeholder:text-gray-400 placeholder:h2-semibold`}
						onChange={(e) => {
							if (input.onChange) {
								input.onChange(e)
							}
							field.onChange(e)
							return e
						}}
						_active={{borderColor: `gray-300 !important`}}
						_focus={{borderColor: `gray-300 !important`}}
						_focusVisible={{borderColor: `gray-300 !important`}}
						_invalid={{borderColor: `#E53E3E !important`}}
						{...(fieldState?.error ? {isInvalid: true} : {})}
					/>
          <InputLeftAddon className="cursor-pointer flex !gap-2 absolute left-2 top-0 bottom-0 my-auto">
          {mask == 'BRL' && <p className="text-black !mr-6">R$</p>}
          </InputLeftAddon>
					{(clearable || input?.rightIcon || input?.type === "password") && (
						<InputRightAddon className={`cursor-pointer flex !gap-2 absolute right-0 top-0 bottom-0 my-auto ${rightIconClassName}`} onClick={(e) => {
							(clearable && resetField) && resetField(name!, {
								keepDirty: false,
								keepError: false,
								keepTouched: false,
								defaultValue: ""})
							onRightIconClick && onRightIconClick()
							input?.type === "password"  && setType(prev => {
								if (prev === "password") {
									return "text"
								} else {
									return "password"
								}
							})
						}}>
							{!!input?.rightIcon && input?.rightIcon}
              {mask == '%' && <p className="text-black !mr-6">%</p>}
						</InputRightAddon>
					)}
				</InputGroup>
				{fieldState?.error?.message && (
					<Flex className={`mt-2 items-center !gap-2`}>
						<img className={`w-4 h-4`} src={"/icons/error.svg"} />
						<Text className={"text-[10px] font-medium line-clamp-1 text-red"}>{fieldState?.error?.message}</Text>
					</Flex>
				)}
			</label>
		</>
	)
}