import api from "./api";

const req = {
  get: async (params?:any) => {
    const response = await api.get(`/carteiras`, {params});

    return response;
  },

  post: async (
    data_inicio: string,
    corretora: string,
    documento: string,
    n_carteira: string,
    nome: string,
    linkScrap: string,
    plInicial: string
  ) => {
    const formData = new FormData();
    formData.append("data_inicio", data_inicio);
    formData.append("corretora", corretora);
    formData.append("documento", documento);
    formData.append("n_carteira", n_carteira);
    formData.append("nome", nome);
    formData.append("link_scrap", linkScrap);
    formData.append("pl_inicial", plInicial);

    const response = await api.post(`/carteira`, formData);

    return response;
  },

  getWalletInfoById: async (id: string) => {
    const response = await api.get(`/carteira?n_carteira=${id}`);

    return response;
  },

  EditWalletInfoById: async (
    id: string,
    data_inicio: string,
    corretora: string,
    documento: string,
    n_carteira: string,
    nome: string,
    ativo: boolean
  ) => {
    const transform = new Boolean(ativo).toString();
    const formData = new FormData();
    formData.append("data_inicio", data_inicio);
    formData.append("corretora", corretora);
    formData.append("documento", documento);
    formData.append("n_carteira", n_carteira);
    formData.append("nome", nome);
    formData.append("ativo", transform);
    const response = await api.put(`/carteira?id=${id}`, formData);

    return response;
  },

  getStaticWalletInfoById: async (id: string) => {
    const response = await api.get(`/static_info?n_carteira=${id}`);

    return response;
  },

  EditStaticWalletInfoById: async (
    id: string,
    taxaAdm: string,
    taxaPfee: string,
    valorAdmMin: string,
    fatorPctBenchmark: string
  ) => {
    const formData = new FormData();
    formData.append("fator_pct_benchmark", fatorPctBenchmark);
    formData.append("taxa_adm", taxaAdm);
    formData.append("taxa_pfee", taxaPfee);
    formData.append("valor_adm_min", valorAdmMin);

    const response = await api.put(`/static_info?n_carteira=${id}`, formData);

    return response;
  },

  getMutableWalletInfoById: async (id: string) => {
    const response = await api.get(`/mutable_info?n_carteira=${id}`);
    return response;
  },

  EditMutableWalletInfoById: async (
    provisao: string,
    valor_adm_acumulado: string,
    pl_fechamento_anterior: string,
    qtd_cotas: string,
    carteira: string

  ) => {
    const formData = new FormData();
    formData.append("provisao", provisao);
    formData.append("valor_adm_acumulado", valor_adm_acumulado);
    formData.append("pl_fechamento_anterior", pl_fechamento_anterior);
    formData.append("qtd_cotas", qtd_cotas);
    const response = await api.put(
      `/mutable_info?n_carteira=${carteira}`,
      formData
    );

    return response;
  },

  MigrarCotas: async (file: any, data_corte: string, carteira: string) => {
    const formData = new FormData();
    formData.append("file", file);
    const response = await api.post(
      `/britech_cotas_migration?carteira=${carteira}&data_corte=${data_corte}`,
      formData
    );

    return response;
  },

  
  // ProcessarCarteiras: async (carteiras: [any], stall: string) => { const Obj = { carteiras, stall, };
  //   const response = await api.post(`/engine_cotizador`, Obj);
  //   return response;
  // },

  ProcessarCarteiras: async (carteiras: [any], stall: string) => {
    const Obj = { carteiras, stall, };
    return await api.post(`/engine_cotizador`, Obj).catch(({response}) => {
      return response
    })
  },



  RetroagirCotas: async (date: string, carteira: string) => {
    const formData = new FormData();
    formData.append("data", date);
    formData.append("carteira", carteira);
    const response = await api.post(`/retroagir_cotas`, formData);

    return response;
  },
};

export default req;
