import { Button, Flex, LoadingOverlay, SimpleGrid } from "@mantine/core";
import { Table } from "../../components/Table";
import { DatePicker } from "@mantine/dates";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { TitlePage } from "../../components/TitlePage";
import { DateWithoutGmt } from "../../helpers/CreateObjectDateWithoutGmt";

import { tableColumns } from "./tableColumns";
import Carteiras from "../Carteiras";
import odd_positions from '../../services/odd_positions'
import NewOddPosition from "./NewOddPositionModal";
import { Input } from "../../components/Input/Index";
import { transformDateV2 } from "../../helpers/transformDateV2";
import ModalRetroagirOdd from "./ModalRetroagirOdd";
import { showNotification, updateNotification } from "@mantine/notifications";
import ExportToExcel from "../../helpers/ExportToExcel";

interface IMovimentacoes {
  accountnumber: string;
  corretora: string;
  data: string;
  grossvalue: number;
  id: number;
  movementhistory: string;
  nome: string;
  origem: string;
}

export default function OddPositions() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false)
  const [date, setDate] = useState<any>(new Date())
	const [idIsLoading, setIdIsLoading] = useState<undefined|number>()
  const { carteira } = useParams();

  const [tableConfig, setTableConfig] = useState({
		page: 1,
		last_page: 1,
	})

  const handleChangeDate = (date: any) => {
    const newdate = DateWithoutGmt(date);
    setDate(newdate);
  };



  const onDelete = async (id: number) => {
		setIdIsLoading(id)
		const response = await odd_positions.delete(id)
		setIdIsLoading(undefined)
		fetchData()
	}
  const HandleAdvanceWallet = () => {
    showNotification({
      id: "load-data",
      loading: true,
      title: "Avançando Data",
      message: "Aguarde...",
      autoClose: true,
      disallowClose: true,
    });
    odd_positions.AvancarCarteira({carteira: carteira})
    .then((res) => {
      updateNotification({
         id: "load-data",
         color: "teal",
         title: "Sucesso!",
         message: `Data avançada para ${res.data.message}!`,
         autoClose: 2000,
       });

     })
     .catch((err) => {
       updateNotification({
         id: "load-data",
         title: "Erro!",
         message: `Ocorreu erro ao editar Odd Position!: ${err.error}`,
         autoClose: 2000,
         color: "red",
       });
     });
  };

  const fetchData = async (page?:number) => {
    setLoading(true);
    const dateTransform = transformDateV2(date)
    odd_positions.getAllByWallet(carteira, dateTransform).then((res: AxiosResponse<any>) => {
        setLoading(false);
        setData(res.data.data);

        setTableConfig({
          page: res?.data?.data?.pagination.current_page,
          last_page: res?.data?.data?.pagination.total_pages
        })

      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };

    useEffect(() => {
      fetchData()
    }, [])

  return (
    <>
      <SimpleGrid cols={1}>

        <Flex className="justify-between items-center">
          <TitlePage title={`Adjust Odd Positions`} />
          <NewOddPosition refetch={fetchData} />
        </Flex>
        <Flex align={"end"} gap="xs">

            <DatePicker
              value={DateWithoutGmt(date)}
              label="Data"
              fullWidth
              placeholder="Data"
              withAsterisk
              onChange={(e) => handleChangeDate(e)}
            />

            <Button onClick={() => fetchData()}>Gerar Relatório</Button>
            {data && data?.length > 0 && <ExportToExcel data={data} fileName={`adjust_odd_positions`} />}

          <Flex gap='xs' className="w-full justify-end">
            <ModalRetroagirOdd />
            <Button onClick={() => HandleAdvanceWallet()}>Avançar Odd Position +1 dia</Button>
          </Flex>


          </Flex>

       
        <Flex w={"100%"} align={"center"} justify="center" direction={"column"}>
        {data?.length > 0 ? <Table data={data} 
        columns={tableColumns({ onDelete,  idIsLoading, refetch: fetchData })}  pageCount={tableConfig?.last_page} onPageChange={(page) => {fetchData(page)}} /> : <p className="text-black">Sem Posições para exibir</p>}
          <Flex  p="xs" justify={"center"} w={"100%"} align={"flex-end"}></Flex>
        </Flex>
      </SimpleGrid>
    </>
  );
}
