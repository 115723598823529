import api from "./api";

const req = {
  getAll: async () => {
    const response = await api.get(`/police_risk_dimension`);

    return response;
  },
  byId: async (id:any) => {
    const response = await api.get(`/police_risk_dimension?id=${id}`);

    return response;
  },
  create: async (params:any) => {
    return await api.post(`/police_risk_dimension`, params)
    .catch(({response}) => {
      return response
    })
  },

  delete: async (id: any) => {
    return await api.delete(`/police_risk_dimension?id=${id}`, {
    }).catch(({response}) => {
      return response
    })
  },
  changeRelation: async (params:any) => {
    return await api.put(`/police_risk_dimension`, params)
    .catch(({response}) => {
      return response
    })
  },
};

export default req;
