import api from "./api";

const req = {
  get: async () => {
    const response = await api.get(`pfees_date`);

    return response;
  },

  GetByNumeroCarteira: async (n_carteira: string) => {
    const response = await api.get(`pfee_date?n_carteira=${n_carteira}`);

    return response;
  },

  GetByUniqueById: async (id: string) => {
    const response = await api.get(`pfee_date_unique?id=${id}`);

    return response;
  },

  EditByUniqueById: async (
    id: string,
    data_inicial: any,
    data_final: any,
    data_pfee: any
  ) => {
    const formData = new FormData();
    formData.append("data_inicial", data_inicial);
    formData.append("data_final", data_final);
    formData.append("data_pfee", data_pfee);
    const response = await api.put(`pfee_date_unique?id=${id}`, formData);

    return response;
  },

  create: async (
    carteira: string,
    data_inicial: any,
    data_final: any,
    data_pfee: any
  ) => {
    const formData = new FormData();
    formData.append("data_inicial", data_inicial);
    formData.append("data_final", data_final);
    formData.append("data_pfee", data_pfee);
    formData.append("carteira", carteira);
    const response = await api.post(`pfee_date`, formData);

    return response;
  },

  delete: async (id: string) => {
    const response = await api.delete(`pfee_date?id=${id}`);

    return response;
  },
};

export default req;
