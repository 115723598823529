import api from "./api";

const req = {
  getAll: async () => {
    const response = await api.get(`/police_risk_facts`);

    return response;
  },

  byId: async (id:any) => {
    const response = await api.get(`/police_risk_facts?id=${id}`);

    return response;
  },

  create: async (params:any) => {
    const response = await api.post(`/police_risk_facts`, params);

    return response;
  },

  delete: async(id:any) => {
    const response = await api.delete(`/police_risk_facts?id=${id}`);

    return response;
  },

  changeRelation: async(params:any) => {
    const response = await api.put(`/police_risk_facts`, params);

    return response;
  }
};

export default req;
