import { Button, createStyles, Navbar } from "@mantine/core";
import { IconFileDollar, IconLanguage, IconWallet, IconLockAccess } from "@tabler/icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppUi } from "../../context/AppUiContext";
import { handleLogout } from "../../hooks/useUser";

import { useUser } from '../../hooks/useUser';

const useStyles = createStyles((theme, _params, getRef) => {

  const {user} = useUser()
  
  const icon = getRef("icon");
  return {
    header: {
      paddingBottom: theme.spacing.md,
      marginBottom: theme.spacing.md * 1.5,
      borderBottom: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : theme.colors.gray[2]
      }`,
    },

    footer: {
      paddingTop: theme.spacing.md,
      marginTop: theme.spacing.md,
      borderTop: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : theme.colors.gray[2]
      }`,
    },

    link: {
      ...theme.fn.focusStyles(),
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      fontSize: theme.fontSizes.sm,
      color:
        theme.colorScheme === "dark"
          ? theme.colors.dark[1]
          : theme.colors.gray[7],
      padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
      borderRadius: theme.radius.sm,
      fontWeight: 500,

      "&:hover": {
        backgroundColor:
          theme.colorScheme === "dark"
            ? theme.colors.dark[6]
            : theme.colors.gray[0],
        color: theme.colorScheme === "dark" ? theme.white : theme.black,

        [`& .${icon}`]: {
          color: theme.colorScheme === "dark" ? theme.white : theme.black,
        },
      },
    },

    linkIcon: {
      ref: icon,
      color:
        theme.colorScheme === "dark"
          ? theme.colors.dark[9]
          : theme.colors.gray[6],
      marginRight: theme.spacing.sm,
    },

    linkActive: {
      "&, &:hover": {
        backgroundColor: theme.fn.variant({
          variant: "light",
          color: theme.primaryColor,
        }).background,
        color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
          .color,
        [`& .${icon}`]: {
          color: theme.fn.variant({
            variant: "light",
            color: theme.primaryColor,
          }).color,
        },
      },
    },
  };
});

const data = [
  { link: "/", label: "Carteiras", icon: IconWallet },
  { link: "/tradutor/btg", label: "Tradutor BTG", icon: IconLanguage },
  { link: "/tradutor/xp", label: "Tradutor XP", icon: IconLanguage },
  { link: "/movimentacoes-manuais", label: "Movimentações Manuais", icon: IconFileDollar },
  { link: "/risco-controle", label: "Risco e Controle", icon: IconLockAccess },
  { link: "/classeativos", label: "Classe Ativos", icon: IconLockAccess }
];

export function NavBarAlpha() {
  const { classes, cx } = useStyles();
  const [active, setActive] = useState("Carteiras");
  const { handleChangeMenuOpen } = useAppUi();
  const navigate = useNavigate();
  const links = data.map((item) => (
    <a
      className={cx(classes.link, {
        [classes.linkActive]: item.label === active,
      })}
      href={item.link}
      key={item.label}
      onClick={(event) => {
        navigate(`${item.link}`);
        event.preventDefault();
        setActive(item.label);
        handleChangeMenuOpen();
      }}
    >
      <item.icon className={classes.linkIcon} stroke={1.5} />
      <span>{item.label}</span>
    </a>
  ));

  return (
    <Navbar width={{ sm: 300 }} p="md" bg="" mt={-1}>
      <Navbar.Section grow>{links}</Navbar.Section>
      <Button onClick={() => handleLogout()}>Logout</Button>
    </Navbar>
  );
}
