import { ActionIcon, Button, Flex, Group, Modal, Select, SimpleGrid } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconAbacusOff, IconRelationOneToOne, IconPlus } from "@tabler/icons";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SubTitlePage } from "../../../../components/SubTitlePage";
import req from "../../../../services/RiskPoliceDimension";
import reqFacts from '../../../../services/RiskPoliceFacts'
import { Input } from "../../../../components/Input/Index";
import { useForm } from "react-hook-form";

interface Props {
  reload: () => void;
  id?: string
}

interface OriginalObject {
  field: string;
  id: number;
  name: string;
  value: string[];
}

interface NewObject {
  value: string;
  label: string;
}

interface IchangeRelation {
  fact_id: string,
  dimension_id: string
}

export default function EditRelationModal({ reload, id }: Props) {
  const [opened, { open, close }] = useDisclosure(false);

  const [loading, setLoading] = useState(false);
  const [valueInput, setValueInput] = useState('')
  const [selectOptions, setSelectOptions] = useState<any[]>([]);

  async function handlePostData() {

    showNotification({
      id: "load-data",
      loading: true,
      title: "Criando",
      message: "Aguarde...",
      autoClose: true,
      disallowClose: true,
    });
    setLoading(true);

    if (!(await req.create({}))) {
      setLoading(false);
        updateNotification({
          id: "load-data",
          color: "red",
          title: "Error!",
          message: `Erro ao criar`,
          autoClose: 2000,
        });
			return
		}
    setLoading(false);
    reload()
    updateNotification({
      id: "load-data",
      color: "teal",
      title: "Sucesso!",
      message: `Criado com sucesso!`,
      autoClose: 2000,
    });

}

function handleChangeValue(newValue: string) {
  setValueInput(newValue);
}

const getDataAllDimensions = async () => {
  const response = await req.getAll()

  const data = response.data.data

  const newData: NewObject[] = [];

  data.forEach((obj: OriginalObject) => {
    const newObject: NewObject = {
        value: obj.id.toString(),
        label: obj.name
    };

    newData.push(newObject)
});
setSelectOptions(newData)

}

const getFactById = async () => {
  const response = await reqFacts.byId(id)
  const data = response.data.data.dimension.id

  setValueInput(data?.toString())
}

const changeRelation = async () => {
  showNotification({
    id: "load-data",
    loading: true,
    title: "Alterando",
    message: "Aguarde...",
    autoClose: true,
    disallowClose: true,
  });
  setLoading(true);
  const response = await reqFacts.changeRelation({fact_id: id?.toString(), dimension_id: valueInput})

  if(response.status !== 200){
    setLoading(false);
        updateNotification({
          id: "load-data",
          color: "red",
          title: "Error!",
          message: `Erro ao alterar relação`,
          autoClose: 2000,
        });
			return
  }

    reload()
    setLoading(false);
    updateNotification({
      id: "load-data",
      color: "teal",
      title: "Sucesso!",
      message: `Relacao Alterada com sucesso!!`,
      autoClose: 2000,
    });
    close()

}

useEffect(() => {
  getFactById()
  getDataAllDimensions()
  
},[])



  return (
    <>
      <Modal
        opened={opened}
        size="sm"
        onClose={close}
        dir="row"
        withCloseButton={false}
      >
        <SubTitlePage title="Editar Relacionamento"></SubTitlePage>
        <Flex className="!gap-2 flex-col">

        <Select

          value={valueInput}
          withinPortal
          data={selectOptions}
          onChange={(e) => {
            handleChangeValue(typeof e === "string" ? e : "");
          }}
          placeholder="Relação"
        ></Select>

        </Flex>
        <Button
          fullWidth
          size="md"
          mt="xs"
          onClick={() => changeRelation()}
          loading={loading}
        >
          Salvar Alteração
        </Button>
      </Modal>

      <ActionIcon>
            <IconRelationOneToOne
              color="blue"
              size={36}
              stroke={1}
              onClick={() => open()}
            />
          </ActionIcon>
    </>
  );
}
