import api from "./api";

interface RelatorioReceitasParams {
  data_inicial: any;
  data_final: any;
}

interface RelatorioCarteirasParams {
  date:any;
}

interface RelatorioAporteResgate {
  data_inicial: any;
  data_final: any;
}


const relatorios = {
  FeeByWallet: async (carteira: string, date: string) => {
    const response = await api.get(
      `/cotas_fee?n_carteira=${carteira}&date=${date ? date : ""}`
    );

    return response;
  },

  HistoricoPosicoes: async (
    carteira: string,
    start_date: string,
    end_date: string
  ) => {
    const response = await api.get(
      `/posicao?n_carteira=${carteira}&start_date=${
        start_date ? start_date : ""
      }&end_date=${end_date ? end_date : ""}`
    );

    return response;
  },

  EditarPosicao: async (
    n_carteira: string,
    id_position: string,
    new_value: number
  ) => {
    const formData = new FormData();
    formData.append("id_position", id_position);
    formData.append("ativo_final", new_value.toString());
    const response = await api.put(
      `/posicao?n_carteira=${n_carteira}`,
      formData
    );

    return response;
  },

  AdicionarPosicao: async (
    carteira: string,
    ativo_final: string,
    data: string
  ) => {
    const obj = { carteira, ativo_final, data };

    const response = await api.post(`/posicao`, obj);

    return response;
  },

  DeletarPosicao: async (id: string) => {
    const response = await api.delete(`/posicao?id=${id}`);

    return response;
  },

  HistoricoDeCotas: async (
    carteira: string,
    dateInicial: string,
    dateFinal: string
  ) => {
    const response = await api.get(
      `/historico_cotas?n_carteira=${carteira}&data_inicial=${
        dateInicial ? dateInicial : ""
      }&data_final=${dateFinal ? dateFinal : ""}`
    );

    return response;
  },

  Sinistro: async () => {
    const response = await api.get(`/asset_outliers`);

    return response;
  },

  

  RelatorioReceita: async (params: RelatorioReceitasParams) => {
    const response = await api.get(`/relatorio_receita`, { params: params} );

    return response;
  },

  RelatorioCarteira: async (params: RelatorioCarteirasParams) => {
    const response = await api.get(`/relatorio_carteiras`, { params: params} );

    return response;
  },

  RelatorioAporteResgate: async (params: RelatorioAporteResgate) => {
    console.log(params)
    const response = await api.get(`/relatorio_aporte_resgate`, { params: params} );

    return response;
  },

};

export default relatorios;
