import { ActionIcon, Menu } from "@mantine/core";
import {
  IconCalendarTime,
  IconDotsVertical,
  IconFileInvoice,
  IconPigMoney,
  IconTrash,
} from "@tabler/icons";
import { useNavigate, useParams } from "react-router-dom";

export function MenuOpcoesCarteiras() {
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <Menu shadow="md" width={200} zIndex="9999">
      <Menu.Target>
        <ActionIcon variant="filled" bg="blue">
          <IconDotsVertical size="1rem" />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>Relatórios</Menu.Label>
        <Menu.Item
          onClick={() => navigate(`/relatorios/relatorio_receitas`)}
          icon={<IconFileInvoice size={14} />}
        >
          Relatório Receitas
        </Menu.Item>
        <Menu.Item
          onClick={() => navigate(`/relatorios/relatorio_carteiras`)}
          icon={<IconFileInvoice size={14} />}
        >
          Relatório Carteiras
        </Menu.Item>

        <Menu.Item
          onClick={() => navigate(`/relatorios/relatorio_aportes_resgates`)}
          icon={<IconFileInvoice size={14} />}
        >
          Relatório Aportes / Resgates
        </Menu.Item>
      
      </Menu.Dropdown>
    </Menu>
  );
}
