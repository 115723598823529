export function transformDateV2(dateString: string) {
  if(!dateString){
    return ''
  }
  const date = new Date(dateString);
  date.setUTCHours(0, 0, 0, 0);
  const day = date.getUTCDate().toString().padStart(2, "0");
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const year = date.getUTCFullYear();
  return `${year}-${month}-${day}`;
}
