import api from "./api";

export const registerFormAnbimaInput = async (params: any) => {
	return await api.post(`/anbima_input_info`, params).catch(({response}) => {
		return response
	})
}

export const GetFormAnbimaInputByCarteira = async (n_carteira: any) => {
	return await api.get(`/anbima_input_info?n_carteira=${n_carteira}`).catch(({response}) => {
		return response
	})
}
