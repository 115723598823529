import { ActionIcon, Button, Group, Modal, SimpleGrid } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconPencil } from "@tabler/icons";
import { useEffect, useState } from "react";
import relatorios from "../../../../services/relatorios";
import { InputMaskMoney } from "../../../InputMask/InputMaskMoney";
import { SubTitlePage } from "../../../SubTitlePage";

interface Props {
  n_carteira: string;
  id_position: string;
  value_position: number;
  date: string;
  reload: () => void;
}

export default function ModalEditPosicoes({
  n_carteira,
  id_position,
  value_position,
  date,
  reload,
}: Props) {
  const [opened, { open, close }] = useDisclosure(false);

  const [loading, setLoading] = useState(false);
  const [position, setPosition] = useState<number>(0);

  const handleChangePosition = (value: any) => {
    setPosition(value);
  };

  // const fetchData = async () => {
  //   setLoading(true);
  //   pfee
  //     .GetByUniqueById(n_carteira)
  //     .then((res: AxiosResponse<any>) => {
  //       setLoading(false);
  //       const data = res.data.data[0];
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       return err;
  //     });
  // };

  useEffect(() => {
    const numeroFormatado = value_position.toFixed(2);
    const numero: number = Number(numeroFormatado);
    setPosition(numero);
    // fetchData();
  }, []);

  async function handleUpdateData() {
    setLoading(true);
    showNotification({
      id: "load-data",
      loading: true,
      title: "Editando posição",
      message: "Aguarde...",
      autoClose: true,
      disallowClose: true,
    });
    //const dateNew = await transformDate(date);
    await relatorios
      .EditarPosicao(n_carteira, id_position, position)
      .then((value) => {
        updateNotification({
          id: "load-data",
          color: "teal",
          title: "Sucesso!",
          message: `Posição editada com Sucesso!`,
          autoClose: 2000,
        });
        close();
        reload();
        setLoading(false);

        return;
      })
      .catch((err) => {
        updateNotification({
          id: "load-data",
          title: "Erro!",
          message: `Ocorreu erro ao editar a posição!`,
          autoClose: 2000,
          color: "red",
        });
        setLoading(false);
      });
  }
  return (
    <>
      <Modal
        opened={opened}
        size="xs"
        onClose={close}
        dir="row"
        withCloseButton={false}
      >
        <SubTitlePage title="Editar Posição"></SubTitlePage>
        <SimpleGrid cols={1} breakpoints={[{ maxWidth: "sm", cols: 1 }]}>
          <InputMaskMoney
            label={`Posição dia ${date}`}
            onChange={handleChangePosition}
            placeholder="R$"
            defaultValue={"400450.53"}
          />
        </SimpleGrid>
        <Button
          fullWidth
          size="md"
          mt="xs"
          onClick={() => handleUpdateData()}
          loading={loading}
        >
          Salvar Edição
        </Button>
      </Modal>

      <Group>
        <ActionIcon>
          <IconPencil size={25} stroke={1} onClick={open} />
        </ActionIcon>
      </Group>
    </>
  );
}
