import api from "./api";

export const getPosicoes = async () => {
	return await api.get(`/fix_posicoes`).catch(({response}) => {
		return response
	})
}

export const insertNewProdutoClass = async (body: any) => {
	return await api.post(`/fix_posicoes`, body).catch(({response}) => {
		return response
	})
}