import { Button, Modal, SimpleGrid, TextInput } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useDisclosure } from "@mantine/hooks";
import { showNotification, updateNotification } from "@mantine/notifications";
import { useState } from "react";
import { InputMaskMoney } from "../../../../components/InputMask/InputMaskMoney";
import { SubTitlePage } from "../../../../components/SubTitlePage";
import { transformDateV2 } from "../../../../helpers/transformDateV2";
import relatorios from "../../../../services/relatorios";

export default function AdicionarPosicaoModal() {
  const [opened, { open, close }] = useDisclosure(false);

  const [loading, setLoading] = useState(false);
  const [carteira, setCarteira] = useState("");
  const [ativoFinal, setAtivoFinal] = useState("");
  const [date, setDate] = useState<any>();

  async function handlePostData() {
    setLoading(true);
    showNotification({
      id: "load-data",
      loading: true,
      title: "Adicionando nova posição",
      message: "Aguarde...",
      autoClose: true,
      disallowClose: true,
    });
    const dateString = await transformDateV2(date);
    relatorios
      .AdicionarPosicao(carteira, ativoFinal, dateString)
      .then((res) => {
        updateNotification({
          id: "load-data",
          color: "teal",
          title: "Sucesso!",
          message: `Nova posição criada com sucesso!`,
          autoClose: 2000,
        });
        setLoading(false);
      })

      .catch((err) => {
        updateNotification({
          id: "load-data",
          title: "Erro!",
          message: `Ocorreu algum problema ao adicionar a posição`,
          autoClose: 2000,
          color: "red",
        });
        setLoading(false);
      });
  }
  return (
    <>
      <Modal
        opened={opened}
        size="xs"
        onClose={close}
        dir="row"
        withCloseButton={false}
      >
        <SubTitlePage title="Adicionar Posição"></SubTitlePage>
        <SimpleGrid cols={1} breakpoints={[{ maxWidth: "sm", cols: 1 }]}>
          <TextInput
            label="Carteira"
            value={carteira}
            required
            onChange={(e) => setCarteira(e.target.value)}
          />
          <InputMaskMoney label="Ativo Final" onChange={(e) => setAtivoFinal} />
          <DatePicker
            value={date}
            label="Data"
            fullWidth
            placeholder="Data"
            withAsterisk
            onChange={(e) => setDate(e ? e : "")}
          />
        </SimpleGrid>
        <Button
          fullWidth
          size="md"
          mt="xs"
          onClick={() => handlePostData()}
          loading={loading}
        >
          Salvar Edição
        </Button>
      </Modal>

      <Button onClick={open}>Adicionar Posição</Button>
    </>
  );
}
