import api from "./api";

export type SignInProps = {
  email: string
  password: string
}

export const login = async (payload: SignInProps) => {
	return await api.post(`/login`, payload).catch(({response}) => {
		return response
	})
}
