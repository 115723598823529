import {
  Button,
  Card,
  Flex,
  Input,
  LoadingOverlay,
  Pagination,
  Select,
  Table,
  useMantineTheme,
} from "@mantine/core";

import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { SelectAndSaveBtg } from "../../components/SelectAndSaveBtg";
import { TitlePage } from "../../components/TitlePage";
import {
  IMovimentacaoBtg,
  ImovimentacaoDataBtg,
} from "../../interfaces/IMovimentacoesBtg";
import req from "../../services/tradutorBtg";
import { transformDateV2 } from "../../helpers/transformDateV2";
import { formatFloatToBrl } from "../../helpers/formatFloatToBrl";

function TradutorBtg() {
  const theme = useMantineTheme();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IMovimentacaoBtg[]>([]);
  const [error, setError] = useState("");
  const [movementHistorySearch, setMovementHistorySearch] = useState("");
  const [account, setAccount] = useState("");
  const [date, setDate] = useState("");
  const [type, setType] = useState("");
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  function transformDate(dateString: string) {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    const isoString = `${day}-${month}-${year}`;

    return isoString;
  }

  function handleChangeAccount(account: string) {
    if (account.length === 0) {
      setAccount("");
    }
    if (account.length > 6) {
      setAccount(account);
    }

    return;
  }

  function handleChangeMovementHistory(term: string) {
    setMovementHistorySearch(term);
  }

  const handleChangePage = async (page: number) => {
    setCurrentPage(page);
  };

  function handleChangeOrigin(string: string) {
    setType(string);
  }

  const rows = data.map((element, idx) => (
    <tr key={idx}>
      <td>{element.id}</td>
      <td>{element.accountnumber}</td>
      <td>{element.asset}</td>
      <td>{element.marketname}</td>
      <td>{transformDateV2(element.movementdate)}</td>
      <td>{element.movementhistory.slice(0, 180)}</td>
      <td>{element.origem}</td>
      <td>{formatFloatToBrl(element.valor) }</td>
      <td>{element.submarketname}</td>

      <td key={idx}>
        <>
          <SelectAndSaveBtg key={idx} data={element} />
        </>
      </td>
    </tr>
  ));

  const fetchData = async () => {
    setLoading(true);
    req
      .get(type, account, date, currentPage)
      .then((res: AxiosResponse<ImovimentacaoDataBtg>) => {
        setLoading(false);
        setData(res.data.data);
        setCurrentPage(1);
        setPages(res.data.total_pages - 1);
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };

  const fetchDataNextPage = async () => {
    setLoading(true);
    req
      .get(type, account, date, currentPage)
      .then((res: AxiosResponse<ImovimentacaoDataBtg>) => {
        setLoading(false);
        setData(res.data.data);
        setCurrentPage(res.data.current_page);
        setPages(res.data.total_pages - 1);
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };

  useEffect(() => {
    fetchData();
  }, [account, type]);

  useEffect(() => {
    fetchDataNextPage();
  }, [currentPage]);

  return (
    <div className="App" style={{ height: "100vh" }}>
      <TitlePage title="Tradutor Btg"></TitlePage>
      <Card bg={theme.colors.blue[6]} h="auto" shadow={"xs"} radius={0} p={4}>
        <Flex align={"center"} justify={"center"} direction="row">
          <Input
            w={200}
            m="xs"
            placeholder="Conta"
            onChange={(e: any) => handleChangeAccount(e.target.value)}
          />
          <Input
            w={200}
            m="xs"
            placeholder="Movement History"
            onChange={(e: any) => handleChangeMovementHistory(e.target.value)}
          />
          <Select
            w={300}
            m="xs"
            withinPortal
            onChange={(e) => {
              typeof e === "string"
                ? handleChangeOrigin(e)
                : console.log("parametro sem valor");
            }}
            placeholder="Origem"
            data={[
              { value: "", label: "TODOS" },
              { value: "CADASTRAR", label: "CADASTRAR" },
              { value: "NEGOCIACAO_ATIVO", label: "NEGOCIACAO_ATIVO" },
              { value: "INTRA_ATIVO", label: "INTRA_ATIVO" },
              {
                value: "INTRA_ATIVO_PASSIVO_ADM",
                label: "INTRA_ATIVO_PASSIVO_ADM",
              },
              {
                value: "INTRA_ATIVO_PASSIVO_PFEE",
                label: "INTRA_ATIVO_PASSIVO_PFEE",
              },
              { value: "INFRA_PL", label: "INFRA_PL" },
            ]}
          />
          <Button fullWidth size="xs" w="150px" bg="green" h="38px" m="xs">
            Salvar
          </Button>
        </Flex>
      </Card>

      <Flex
        w={"100%"}
        align={"center"}
        justify="center"
        direction={"column"}
        p="lg"
      >
        <div style={{ width: "100%", position: "relative" }}>
          <LoadingOverlay visible={loading} overlayBlur={2} />
          <Table striped highlightOnHover withColumnBorders fontSize={12}>
            <thead>
              <tr>
                <th>Id</th>
                <th>Conta</th>
                <th>Asset</th>
                <th>Market Name</th>
                <th>Data</th>
                <th>Movement History</th>
                <th>Origem</th>
                <th>Valor</th>
                <th>Submarket Name</th>
                <th>Tipo</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        </div>

        <Flex p="xs" justify={"center"} w={"100%"} align={"flex-end"}>
          <Pagination
            size={"sm"}
            total={pages}
            page={currentPage}
            onChange={(va) => handleChangePage(va)}
          />
        </Flex>
      </Flex>
    </div>
  );
}

export default TradutorBtg;
