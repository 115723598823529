import { IconTrash } from "@tabler/icons";
import {ColumnProps} from "../../../types";
import { Spinner } from "@chakra-ui/react";
import EditRelationModal from "./EditRelationModal";
type TableColumnsProps = {
  onDelete: (id: number) => void
  idIsLoading: number|undefined,
  refetch:() => void
}
export const tableColumns = ({idIsLoading, onDelete, refetch }: TableColumnsProps) : ColumnProps[] => {
	return [
		{
			id: "id",
			header: "ID"
		},
    {
			id: "name",
			header: "Nome"
		},

    {
      id: 'delete',
      header: "Options",
      cell: (item) => <>
      {item?.row?.original?.id === idIsLoading ? <Spinner></Spinner> :
      <div className="flex items-center">
      <IconTrash size={20} cursor={'pointer'} stroke={1} color="red" onClick={() => onDelete(item.row.original.id)}/>
      <EditRelationModal reload={refetch} id={item?.row?.original?.id}/>
      </div>}
</>
      
    }
		
	]
}