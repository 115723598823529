import { Route, Routes, Navigate } from "react-router-dom";
import AppBase from "../components/AppShell";

import Carteiras from "../pages/Carteiras";
import DetalheCarteira from "../pages/Carteiras/DetalheCarteira";
import Movimentacoes from "../pages/MovimentacoesBtg";
import HistoricoCotas from "../pages/Relatorios/HistoricoCotas";
import HistoricoPosicoes from "../pages/Relatorios/HistoricoPosicoes";
import RelatorioFee from "../pages/Relatorios/RelatorioFee";
import Sinistro from "../pages/Relatorios/Sinistro";

import TradutorBtg from "../pages/Tradutores/TradutorBtg";
import TradutorXp from "../pages/Tradutores/TradutorXp";
import MovimentacoesManuais from "../pages/MovimentacoesManual";
import MovimentacoesBtgPage from "../pages/MovimentacoesBtg";
import MovimentacoesXpPage from "../pages/MovimentacoesXp";
import { Login } from "../pages/Login";
import RelatorioReceita from "../pages/RelatoriosGerais/RelatorioReceitas";
import RelatorioCarteiras from "../pages/RelatoriosGerais/RelatorioCarteiras";
import RelatorioAportesResgastes from "../pages/RelatoriosGerais/RelatorioAportesResgates";
import RelatorioAportesResgates from "../pages/RelatoriosGerais/RelatorioAportesResgates";
import TesteTabela from "../pages/RelatoriosGerais/TesteTabela";
import OddPositions from "../pages/OddPositions";
import { getToken } from "../hooks/useUser";
import RiscoEcontrole from "../pages/RiscoControle";
import UserFormInput from "../pages/PageRegulacao/UserFormInput";
import ClasseAtivos from "../pages/ClasseAtivos";


interface ReactRouterDomProps {
  redirectTo: string;
  children: JSX.Element;
}

export const Rotas: any = () => {

  const PrivateRoute = ({ children, redirectTo }: ReactRouterDomProps) => {
    const token = getToken()
    return token ? children : <Navigate to={redirectTo} />}

  return (
    <Routes>
        <Route
        path="/login"
        element={<Login />}
      />,

      <Route
        path="/tradutor/btg"
        element={
          <AppBase>
            <TradutorBtg />
          </AppBase>
        }
      />
      ,
      <Route
        path="/tradutor/xp"
        element={
          <AppBase>
            <TradutorXp />
          </AppBase>
        }
      />
      ,
      <Route
        path="/"
        element={
        <PrivateRoute redirectTo="/login">
          <AppBase>
            <Carteiras />
          </AppBase>
        </PrivateRoute>
        }
      />
      ,
      <Route
        path="/cliente/:id"
        element={
        <PrivateRoute redirectTo="/login">
          <AppBase>
            <DetalheCarteira />
          </AppBase>
        </PrivateRoute>
        }
      />
      ,
      <Route
        path="/cliente/relatorio/fee/:id"
        element={
          <AppBase>
            <RelatorioFee />
          </AppBase>
        }
      />
      ,
      <Route
        path="/cliente/relatorio/posicoes/:id"
        element={
          <AppBase>
            <HistoricoPosicoes />
          </AppBase>
        }
      />
      ,
      <Route
        path="/cliente/relatorio/cotas/:id"
        element={
          <AppBase>
            <HistoricoCotas />
          </AppBase>
        }
      />
      ,
      <Route
        path="/sinistro"
        element={
          <AppBase>
            <Sinistro />
          </AppBase>
        }
      />
      ,
      <Route
        path="/movimentacoes-manuais"
        element={
          <AppBase>
            <MovimentacoesManuais />
          </AppBase>
        }
      />,
        <Route
        path="/movimentacoes-xp/:id"
        element={
          <AppBase>
            <MovimentacoesXpPage />
          </AppBase>
        }
      />,
      <Route
        path="/movimentacoes-btg/:id"
        element={
          <AppBase>
            <MovimentacoesBtgPage />
          </AppBase>
        }
      />,
      <Route
        path="/relatorios/relatorio_receitas"
        element={
          <AppBase>
            <RelatorioReceita />
          </AppBase>
        }
      />,
      <Route
        path="/relatorios/relatorio_carteiras"
        element={
          <AppBase>
            <RelatorioCarteiras />
          </AppBase>
        }
      />,

      <Route
        path="/relatorios/relatorio_aportes_resgates"
        element={
          <AppBase>
            <RelatorioAportesResgates />
          </AppBase>
        }
      />,

    <Route
        path="/relatorios/teste_tabela"
        element={
          <AppBase>
            <TesteTabela />
          </AppBase>
        }
      />,
      
    <Route
        path="/cliente/odd_positions/:carteira"
        element={
          <AppBase>
            <OddPositions />
          </AppBase>
        }
      />,

<Route
        path="/risco-controle"
        element={
          <AppBase>
            <RiscoEcontrole />
          </AppBase>
        }
      />,
      <Route
        path="/regulacao/forminput/:id"
        element={
          <AppBase>
            <UserFormInput />
          </AppBase>
        }
      />,

<Route
        path="/classeativos"
        element={
          <AppBase>
            <ClasseAtivos />
          </AppBase>
        }
      />,

    </Routes>

    
  );
};
