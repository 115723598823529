import { Card, createStyles, Group, LoadingOverlay, Text } from "@mantine/core";
import { AxiosResponse } from "axios";
import moment from "moment";
import "moment-timezone";
import "moment/locale/pt-br";
import { useEffect, useState } from "react";
import { formatFloatToBrl } from "../../../helpers/formatFloatToBrl";
import { IStaticInfos } from "../../../interfaces/IStaticInfo";
import req from "../../../services/carteira";
import ModalEditCarteiraInfo from "./ModalEdit";

moment.tz.setDefault("UTC");
moment.locale("pt-BR");

interface Props {
  id: string;
}

export function StaticInfoCarteira({ id }: Props) {
  const useStyles = createStyles((theme) => ({
    edit: {
      width: -0,
      height: -0,
      position: "relative",
      float: "right",
      right: 50,
      top: 5,
      zIndex: 1000,
    },
    title: {
      fontWeight: 900,
      color: theme.colors.dark[3],
      [theme.fn.smallerThan("sm")]: {
        fontSize: 20,
      },
    },
    item: {
      gap: 1,
      display: "flex",
      flexDirection: "column",
      margin: 0,
      alignItems: "center",
    },
    text: {
      fontWeight: 400,
      margin: 0,
      color: theme.colors.dark[2],
      [theme.fn.smallerThan("sm")]: {
        fontSize: 10,
      },
    },

    description: {
      maxWidth: 600,
      margin: "auto",

      "&::after": {
        content: '""',
        display: "block",
        backgroundColor: theme.fn.primaryColor(),
        width: 85,
        height: 2,
        marginTop: theme.spacing.sm,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },

    card: {
      height: "100%",
      display: "flex",
      border: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[1]
      }`,
    },
  }));

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);

  const fetchData = async () => {
    setLoading(true);
    req
      .getStaticWalletInfoById(id)
      .then((res: AxiosResponse<IStaticInfos>) => {
        setLoading(false);
        setData(res.data.data[0]);
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const { classes, theme } = useStyles();
  return (
    <div style={{ height: "100%" }}>
      <div className={classes.edit}>
        <ModalEditCarteiraInfo
          data={data ? data : ""}
          refetch={fetchData}
        ></ModalEditCarteiraInfo>
      </div>

      <div style={{ width: "100%", position: "relative", height: "100%" }}>
        <LoadingOverlay visible={loading} overlayBlur={2} />

        <Card shadow="xs" className={classes.card}>
          <Group mt="xs">
            <Group className={classes.item}>
              <Text className={classes.text}>Taxa ADM</Text>
              <Text className={classes.text}>
                <b>{data?.taxa_adm}%</b>
              </Text>
            </Group>

            <Group className={classes.item}>
              <Text className={classes.text}>Adm Min.</Text>

              <Text className={classes.text}>
                <b>
                  {data?.valor_adm_min
                    ? formatFloatToBrl(data?.valor_adm_min)
                    : "0"}
                </b>
              </Text>
            </Group>

            <Group className={classes.item}>
              <Text className={classes.text}>Pfee Tx.</Text>
              <Text className={classes.text}>
                <b>{data?.taxa_pfee}%</b>
              </Text>
            </Group>

            <Group className={classes.item}>
              <Text className={classes.text}>Fator Pct Benchmark</Text>
              <Text className={classes.text}>
                <b>{data?.fator_pct_benchmark}</b>
              </Text>
            </Group>
          </Group>
        </Card>
      </div>
    </div>
  );
}
