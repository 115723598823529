import {
  ActionIcon,
  Flex,
  LoadingOverlay,
  SimpleGrid,
  Table,
  Text,
} from "@mantine/core";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconTrash } from "@tabler/icons";

import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import NovoPfeeModal from "../../../pages/Carteiras/DetalheCarteira/NovoPfeeModal";
import pfee from "../../../services/pfee";
import { SubTitlePage } from "../../SubTitlePage";
import ModalEditPfeeDate from "./ModalEdit";

import { modals } from "@mantine/modals";
import { IDatePfee } from "../../../interfaces/IDatePfee";

interface Props {
  id: string;
}

function TablePfeeDate({ id }: Props) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IDatePfee[]>([]);

  async function handleDeletePfee(id: string) {
    setLoading(true);
    showNotification({
      id: "load-data",
      loading: true,
      title: "Removendo Pfee",
      message: "Aguarde...",
      autoClose: true,
      disallowClose: true,
    });
    await pfee
      .delete(id)
      .then((value) => {
        updateNotification({
          id: "load-data",
          color: "teal",
          title: "Sucesso!",
          message: `Pfee removido com sucesso!`,
          autoClose: 2000,
        });
        setLoading(false);
        fetchData();

        return;
      })
      .catch((err) => {
        updateNotification({
          id: "load-data",
          title: "Erro!",
          message: `Ocorreu erro ao remover pfee!`,
          autoClose: 2000,
          color: "red",
        });
        setLoading(false);
      });
  }

  const openModal = (
    id: string,
    inicial: string,
    final: string,
    pfee: string
  ) =>
    modals.openConfirmModal({
      title: "Atenção!",
      children: (
        <Text size="sm">
          Você tem certeza que deseja remover a pffe date cadastrada?
          <br />
          <b>Inicial:</b> {`${inicial}`}
          <br />
          <b>Final:</b> {`${final}`}
          <br />
          <b>Pfee:</b> {`${pfee}`}
        </Text>
      ),
      labels: { confirm: "Confirmar", cancel: "Cancelar" },
      onCancel: () => console.log("Cancelado"),
      onConfirm: () => handleDeletePfee(id),
    });

  const fetchData = async () => {
    setLoading(true);
    pfee
      .GetByNumeroCarteira(id)
      .then((res: AxiosResponse<{ data: IDatePfee[] }>) => {
        setLoading(false);
        setData(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };

  const rows = data.map((element: IDatePfee, idx: any) => (
    <tr key={element.id}>
      <td>{element.data_inicial}</td>
      <td>{element.data_final}</td>
      <td>{element.data_pfee}</td>

      <td>
        <Flex justify="center" gap="md">
          <ModalEditPfeeDate id={element.id.toString()} reload={fetchData} />
          <ActionIcon>
            <IconTrash
              size={20}
              stroke={1}
              color="red"
              onClick={() =>
                openModal(
                  element.id.toString(),
                  element.data_inicial,
                  element.data_final,
                  element.data_pfee
                )
              }
            />
          </ActionIcon>
        </Flex>
      </td>
    </tr>
  ));

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="App" style={{}}>
      <SimpleGrid cols={2}>
        <Flex align={"left"}>
          <SubTitlePage title="Datas Pfee"></SubTitlePage>
        </Flex>
        <Flex justify={"end"}>
          <NovoPfeeModal reload={fetchData}></NovoPfeeModal>
        </Flex>
      </SimpleGrid>

      <Flex w={"100%"} align={"center"} justify="center" direction={"column"}>
        <div style={{ width: "100%", position: "relative" }}>
          <LoadingOverlay visible={loading} overlayBlur={2} />
          <Table striped highlightOnHover withColumnBorders fontSize={12}>
            <thead>
              <tr>
                <th>Data Inicial</th>
                <th>Data Final</th>
                <th>Data Pfee</th>

                <td></td>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        </div>
      </Flex>
    </div>
  );
}

export default TablePfeeDate;
