import { Button, Flex, LoadingOverlay, SimpleGrid, Table } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { TitlePage } from "../../../components/TitlePage";
import { DateWithoutGmt } from "../../../helpers/CreateObjectDateWithoutGmt";
import { transformDateV2 } from "../../../helpers/transformDateV2";
import { IRelatorioFee } from "../../../interfaces/IRelatorioFee";
import wallet from "../../../services/carteira";
import relatorios from "../../../services/relatorios";
import ExportToExcel from "../../../helpers/ExportToExcel";

export default function RelatorioFee() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IRelatorioFee[]>();
  const [date, setDate] = useState<any>(new Date());
  const [name, setName] = useState("");

  const { id } = useParams();

  const fetchUserName = async () => {
    setLoading(true);
    wallet
      .getWalletInfoById(id ? id : "")
      .then((res: AxiosResponse<any>) => {
        setLoading(false);
        setName(res.data.data[0].nome);
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };

  useEffect(() => {
    fetchUserName();
  }, []);

  const handleChangeDate = (date: any) => {
    const newdate = DateWithoutGmt(date);

    setDate(newdate);
  };
  const rows = data?.map((element: IRelatorioFee, idx: any) => (
    <tr key={element.id}>
      <td>{element.cotas}</td>
      <td>{transformDateV2(element.data)}</td>
      <td>{element.value}</td>
      <td>{element.value_base}</td>
      <td>{element.value_corrigida}</td>
      <td>{element.quantidade}</td>
      <td>{element.diferenca}</td>
    </tr>
  ));

  const fetchData = async () => {
    setLoading(true);
    const dateTransformed = transformDateV2(date);
    relatorios
      .FeeByWallet(id ? id : "", dateTransformed)
      .then((res: AxiosResponse<{ data: IRelatorioFee[] }>) => {
        setLoading(false);
        setData(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };

  return (
    <>
      <SimpleGrid cols={1}>
        <Flex align={"left"} direction="column">
          <TitlePage title={`Relatório Fee - ${name}`} />
          <Flex align={"end"} gap="xs">
            <DatePicker
              value={DateWithoutGmt(date)}
              label="Data"
              fullWidth
              placeholder="Data"
              withAsterisk
              onChange={(e) => handleChangeDate(e)}
            />
            <Button onClick={() => fetchData()}>Gerar Relatório</Button>
            {data && data?.length > 0 && <ExportToExcel data={data} fileName={`relatório_fee_${name}`} />}
          </Flex>
        </Flex>

        <Flex w={"100%"} align={"center"} justify="center" direction={"column"}>
          <div style={{ width: "100%", position: "relative" }}>
            <LoadingOverlay visible={loading} overlayBlur={2} />
            <Table striped highlightOnHover withColumnBorders fontSize={12}>
              <thead>
                <tr>
                  <th>N. Cota</th>
                  <th>Data</th>
                  <th>Valor Cota</th>
                  <th>Valor Base</th>
                  <th>Valor Corrigido</th>
                  <th>Quantidade</th>
                  <th>Diferença</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </div>

          <Flex p="xs" justify={"center"} w={"100%"} align={"flex-end"}></Flex>
        </Flex>
      </SimpleGrid>
    </>
  );
}
