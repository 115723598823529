import api from "./api";

const req = {
  get: async (
    type?: string,
    account?: string,
    date?: string,
    page?: number
  ) => {
    const response = await api.get(
      `/btg/movimentacoes_dict/translate?filter=${type}&account=${account}&date=${date}&page=${page}`
    );

    return response;
  },

  put: async (id: number, newValue: string) => {
    const formData = new FormData();
    formData.append("value", newValue);
    const response = await api.put(
      `btg/movimentacoes_dict/update/${id}`,
      formData
    );

    return response;
  },
};

export default req;
