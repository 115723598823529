import {
  Button,
  Group,
  Modal,
  SimpleGrid,
  TextInput,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useDisclosure } from "@mantine/hooks";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconPencil, IconWallet } from "@tabler/icons";
import { useEffect, useState } from "react";
import { TitleBasic } from "../../../components/TitleBasic";
import oddPositions from "../../../services/odd_positions";
import { InputMask } from "../../../components/InputMask/MaskInput";
import { MenuOpcoesCarteiras } from "../../../components/MenuOpcoesCarteiras";
import {Control, useController, UseControllerProps, useForm, UseFormResetField} from "react-hook-form";
import { Input } from "../../../components/Input/Index";
import { useParams } from "react-router-dom";
import { CustomDatePicker } from "../../../components/InputDate";
import { DateWithoutGmt } from "../../../helpers/CreateObjectDateWithoutGmt";
import { transformDateV2 } from "../../../helpers/transformDateV2";
import req from '../../../services/odd_positions'
import { openModal } from "@mantine/modals";


export default function ModalRetroagirOdd({refetch, id}:any) {
  const [opened, { open, close }] = useDisclosure(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({})
  const { carteira } = useParams();
  const {
		control,
		trigger,
		clearErrors,
		watch,
		getValues,
		resetField,
    setValue,
		reset
	} = useForm<any>()



  async function handlePostData() {
    setLoading(true);
    showNotification({
      id: "load-data",
      loading: true,
      title: "Retroagindo Odd Usuário",
      message: "Aguarde...",
      autoClose: true,
      disallowClose: true,
    });

    const payload = getValues()

    payload.data = transformDateV2(payload.data)
  
    oddPositions.RetroagirCarteira({...payload, carteira: carteira})
    .then((value) => {
       updateNotification({
          id: "load-data",
          color: "teal",
          title: "Sucesso!",
          message: `${value.data.message}`,
          autoClose: 2000,
        });
        return setLoading(false);
        
      })
      .catch((err) => {
        updateNotification({
          id: "load-data",
          title: "Erro!",
          message: `Ocorreu erro ao retroagir Odd Position!: ${err.error}`,
          autoClose: 2000,
          color: "red",
        });
        setLoading(false);
      });
  }
  return (
    <>
      <Modal
        opened={opened}
        size="md"
        onClose={close}
        dir="row"
        withCloseButton={false}
      >
        <TitleBasic title="Editar Odd Position"></TitleBasic>

          <SimpleGrid cols={1} breakpoints={[{ maxWidth: "sm", cols: 1 }]}>
            <CustomDatePicker name="data" control={control} label="Data P/ Retroagir"/>
          </SimpleGrid>

        <Button
          fullWidth
          size="md"
          mt="xs"
          onClick={() => handlePostData()}
          loading={loading}
        >
          Retroagir
        </Button>
      </Modal>

      <Group position="center" >
      <Button onClick={open}>Retroagir</Button>
      </Group>
   
    </>
  );
}

