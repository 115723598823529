import {
  Button,
  Group,
  Modal,
  SimpleGrid,
  TextInput,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useDisclosure } from "@mantine/hooks";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconWallet } from "@tabler/icons";
import { useState } from "react";
import { TitleBasic } from "../../../components/TitleBasic";
import oddPositions from "../../../services/odd_positions";
import { InputMask } from "../../../components/InputMask/MaskInput";
import { MenuOpcoesCarteiras } from "../../../components/MenuOpcoesCarteiras";
import {Control, useController, UseControllerProps, useForm, UseFormResetField} from "react-hook-form";
import { Input } from "../../../components/Input/Index";
import { useParams } from "react-router-dom";
import { CustomDatePicker } from "../../../components/InputDate";
import { DateWithoutGmt } from "../../../helpers/CreateObjectDateWithoutGmt";
import { transformDateV2 } from "../../../helpers/transformDateV2";
import { CustomSelect } from "../../../components/Select";


export default function ModalNewOddPosition({refetch}:any) {
  const [opened, { open, close }] = useDisclosure(false);
  const [loading, setLoading] = useState(false);
  const { carteira } = useParams();
  const {
		control,
		trigger,
		clearErrors,
		watch,
		getValues,
		resetField,
		reset
	} = useForm<any>()

  async function handlePostData() {
    setLoading(true);
    showNotification({
      id: "load-data",
      loading: true,
      title: "Cadastrando nova Posição Odd",
      message: "Aguarde...",
      autoClose: true,
      disallowClose: true,
    });

    const payload = getValues()

      if (payload.data_inicial) {
            payload.data_inicial = transformDateV2(payload.data_inicial);
        } else {
            delete payload.data_inicial;
        }
        
    if (payload.data_final) {
            payload.data_final = transformDateV2(payload.data_final);
        } else {
            delete payload.data_final;
        }
    
    if (payload.data_vencimento) {
            payload.data_vencimento = transformDateV2(payload.data_vencimento);
        } else {
            delete payload.data_vencimento;
        }
      

    oddPositions.create({...payload, carteira}).then((value) => {
        updateNotification({
          id: "load-data",
          color: "teal",
          title: "Sucesso!",
          message: `Odd Position Criada com Sucesso!`,
          autoClose: 2000,
        });
        refetch()
        setLoading(false);
        return;
      })
      .catch((err) => {
        updateNotification({
          id: "load-data",
          title: "Erro!",
          message: `Ocorreu erro ao criar nova Odd Position!`,
          autoClose: 2000,
          color: "red",
        });
        setLoading(false);
      });
  }
  return (
    <>
      <Modal
        opened={opened}
        size="md"
        onClose={close}
        dir="row"
        withCloseButton={false}
      >
        <TitleBasic title="Nova Posição"></TitleBasic>
        <SimpleGrid cols={2} breakpoints={[{ maxWidth: "sm", cols: 1 }]}>

        <Input label="Ativo" placeholder="Digite o ativo" resetField={resetField} clearable={true} control={control} name={'ativo'} isRequired></Input>
        <Input label="Classe" placeholder="Digite a classe" resetField={resetField} clearable={true} control={control} name={'classe'}></Input>
        <Input label="CNPJ" placeholder="Digite o CNPJ" resetField={resetField} clearable={true} control={control} name={'cnpj'}></Input>
        <CustomDatePicker name="data_inicial" control={control} label="Data Inicial"/>
        <CustomDatePicker name="data_final" control={control} label="Data Final"/>
        <CustomDatePicker name="data_vencimento" control={control} label="Data Vencimento"/>
        <Input label="Quantidade" placeholder="Digite a quantidade" resetField={resetField} clearable={true} control={control} name={'qtd'}></Input> 
        <CustomSelect options={[
        { value: 'D', label: 'Débito' },
        { value: 'C', label: 'Crédito' },]} label="Side" control={control} name={'side'}></CustomSelect>
        <Input label="Tipo" placeholder="Digite o tipo" resetField={resetField} clearable={true} control={control} name={'tipo'}></Input>
      

        </SimpleGrid>
        
        <Button
          fullWidth
          size="md"
          mt="xs"
          onClick={() => handlePostData()}
          loading={loading}
        >
          Cadastrar Posição
        </Button>
      </Modal>

      <Group position="center">
        <Button onClick={open}>
          Nova Posição
        </Button>
      </Group>
   
    </>
  );
}

