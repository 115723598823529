import { ActionIcon, Menu } from "@mantine/core";
import {
  IconCalendarTime,
  IconDotsVertical,
  IconFileInvoice,
  IconPigMoney,
  IconTrash,
} from "@tabler/icons";
import { useNavigate, useParams } from "react-router-dom";

export function ButtonToggleMenuOptions() {
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <Menu shadow="md" width={200} zIndex="9999">
      <Menu.Target>
        <ActionIcon variant="filled" bg="blue">
          <IconDotsVertical size="1rem" />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>Relatórios</Menu.Label>
        <Menu.Item
          onClick={() => navigate(`/cliente/relatorio/posicoes/${id}`)}
          icon={<IconFileInvoice size={14} />}
        >
          Histórico Posições
        </Menu.Item>
        <Menu.Item
          onClick={() => navigate(`/cliente/relatorio/fee/${id}`)}
          icon={<IconCalendarTime size={14} />}
        >
          Histórico Pfee
        </Menu.Item>
        <Menu.Item
          icon={<IconPigMoney size={14} />}
          onClick={() => navigate(`/cliente/relatorio/cotas/${id}`)}
        >
          Histórico Cotas
        </Menu.Item>

        <Menu.Item
          icon={<IconFileInvoice size={14} />}
          onClick={() => navigate(`/movimentacoes-btg/${id}`)}
        >
          Movimentações BTG
        </Menu.Item>

        <Menu.Item
          icon={<IconFileInvoice size={14} />}
          onClick={() => navigate(`/movimentacoes-xp/${id}`)}
        >
          Movimentações Xp
        </Menu.Item>

        <Menu.Item
          icon={<IconFileInvoice size={14} />}
          onClick={() => navigate(`/cliente/odd_positions/${id}`)}
        >
          Odd's Positions
        </Menu.Item>

        <Menu.Divider />
        <Menu.Label>Regulação</Menu.Label>
        <Menu.Item
          icon={<IconFileInvoice size={14} />}
          onClick={() => navigate(`/regulacao/forminput/${id}`)}
        >
          Anbima Input 
        </Menu.Item>
        <Menu.Divider />
        <Menu.Label>Zona Perigosa</Menu.Label>
        <Menu.Item color="red" icon={<IconTrash size={14} />}>
          Deletar Usuário
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
