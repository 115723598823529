import api from "./api";

const movimentacao = {
  all: async (params?:any) => {
    const response = await api.get(`/movimentacao`, {
      params
    });

    return response;
  },
  add: async (
    carteira: string,
    movementhistory: string,
    origem: string,
    grossvalue: number,
    data: string
  ) => {
    const ObjToPost = { carteira, movementhistory, origem, grossvalue, data };
    const response = await api.post(`/movimentacao`, ObjToPost);

    return response;
  },

  delete: async (id: number) => {
    const response = await api.delete(`/movimentacao?id=${id}`);

    return response;
  },
};

export default movimentacao;
