import { Button, Flex, LoadingOverlay, SimpleGrid } from "@mantine/core";
import { Table } from "../../../components/Table";
import { DatePicker } from "@mantine/dates";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { TitlePage } from "../../../components/TitlePage";
import { DateWithoutGmt } from "../../../helpers/CreateObjectDateWithoutGmt";
import { formatFloatToBrl } from "../../../helpers/formatFloatToBrl";
import { transformDateV2 } from "../../../helpers/transformDateV2";
import { IHistoricoCotas } from "../../../interfaces/IHistoricoCotas";
import wallet from "../../../services/carteira";
import relatorio from "../../../services/relatorios";

import { tableColumns } from "./tableColumns";
import Carteiras from "../../Carteiras";
import req from '../../../services/carteira'

interface IMovimentacoes {
  accountnumber: string;
  corretora: string;
  data: string;
  grossvalue: number;
  id: number;
  movementhistory: string;
  nome: string;
  origem: string;
}


export default function TesteTabela() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [dateInicial, setDateInicial] = useState<any>(new Date());
  const [dateFinal, setDateFinal] = useState<any>(new Date());

  const [tableConfig, setTableConfig] = useState({
		page: 1,
		last_page: 1,
	})


  const handleChangeDateInicial = (date: any) => {
    const newdate = DateWithoutGmt(date);

    setDateInicial(newdate);
  };

  const handleChangeDateFinal = (date: any) => {
    const newdate = DateWithoutGmt(date);

    setDateFinal(newdate);
  };



  const fetchData = async (page?:number) => {
    setLoading(true);
    req.get({page: page ?? tableConfig?.page}).then((res: AxiosResponse<any>) => {
        setLoading(false);
        setData(res.data.data);

        setTableConfig({
          page: res?.data?.data?.pagination.current_page,
          last_page: res?.data?.data?.pagination.total_pages
        })

      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };

  useEffect(() => {
    fetchData()
  },[])

  return (
    <>
      <SimpleGrid cols={1}>
        <Flex align={"left"} direction="column">
          <TitlePage title={`Relatório de Aportes e Resgates`} />
          <Flex align={"end"} gap="xs">
            <DatePicker
              value={DateWithoutGmt(dateInicial)}
              label="Data Inicial"
              fullWidth
              placeholder="Data"
              withAsterisk
              onChange={(e) => handleChangeDateInicial(e)}
            />
            <DatePicker
              value={DateWithoutGmt(dateFinal)}
              label="Data Final"
              fullWidth
              placeholder="Data"
              withAsterisk
              
              onChange={(e) => handleChangeDateFinal(e)}
            />
            <Button onClick={() => fetchData()}>Gerar Relatório</Button>
          </Flex>
        </Flex>

        <Flex w={"100%"} align={"center"} justify="center" direction={"column"}>

        <Table data={data} columns={tableColumns({})} pageCount={tableConfig?.last_page} onPageChange={(page) => {fetchData(page)}} />
          <Flex  p="xs" justify={"center"} w={"100%"} align={"flex-end"}></Flex>
        </Flex>
      </SimpleGrid>
    </>
  );
}
