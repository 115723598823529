import { Button, Flex, LoadingOverlay, SimpleGrid, Table } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { TitlePage } from "../../../components/TitlePage";
import { DateWithoutGmt } from "../../../helpers/CreateObjectDateWithoutGmt";
import { formatFloatToBrl } from "../../../helpers/formatFloatToBrl";
import { transformDateV2 } from "../../../helpers/transformDateV2";
import { IHistoricoCotas } from "../../../interfaces/IHistoricoCotas";
import wallet from "../../../services/carteira";
import relatorio from "../../../services/relatorios";
import ExportToExcel from "../../../helpers/ExportToExcel";



interface IMovimentacoes {
  accountnumber: string;
  corretora: string;
  data: string;
  grossvalue: number;
  id: number;
  movementhistory: string;
  nome: string;
  origem: string;
}


export default function RelatorioAportesResgates() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IMovimentacoes[]>();
  const [dateInicial, setDateInicial] = useState<any>(new Date());
  const [dateFinal, setDateFinal] = useState<any>(new Date());


  const handleChangeDateInicial = (date: any) => {
    const newdate = DateWithoutGmt(date);

    setDateInicial(newdate);
  };

  const handleChangeDateFinal = (date: any) => {
    const newdate = DateWithoutGmt(date);

    setDateFinal(newdate);
  };

  const rows = data?.map((element: IMovimentacoes, idx: any) => (
    <tr key={element.id}>
      <td>{element.id}</td>
      <td>{element.nome}</td>
      <td>{element.accountnumber}</td>
      <td>{element.corretora}</td>
      <td>{element.movementhistory}</td>
      <td>{element.origem}</td>
      <td>{formatFloatToBrl(element.grossvalue)}</td>
      <td>{transformDateV2(element?.data)}</td>
    </tr>
  ));

  const fetchData = async () => {
    setLoading(true);
    const dateInicialTransformed = transformDateV2(dateInicial);
    const dateFinalTransformed = transformDateV2(dateFinal);
    relatorio
      .RelatorioAporteResgate({data_inicial: dateInicialTransformed, data_final: dateFinalTransformed}).then((res: AxiosResponse<any>) => {
        setLoading(false);

        const sort = res.data.data.sort(function (a: any, b: any) {
          return a.nome.localeCompare(b.nome);
        });

        setData(sort);
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };

  return (
    <>
      <SimpleGrid cols={1}>
        <Flex align={"left"} direction="column">
          <TitlePage title={`Relatório de Aportes e Resgates`} />
          <Flex align={"end"} gap="xs">
            <DatePicker
              value={DateWithoutGmt(dateInicial)}
              label="Data Inicial"
              fullWidth
              placeholder="Data"
              withAsterisk
              onChange={(e) => handleChangeDateInicial(e)}
            />
            <DatePicker
              value={DateWithoutGmt(dateFinal)}
              label="Data Final"
              fullWidth
              placeholder="Data"
              withAsterisk
              onChange={(e) => handleChangeDateFinal(e)}
            />
            <Button onClick={() => fetchData()}>Gerar Relatório</Button>
            {data && data.length > 0 && <ExportToExcel data={data} fileName={`Relatório de aportes e resgates`} />}
          </Flex>
        </Flex>

        <Flex w={"100%"} align={"center"} justify="center" direction={"column"}>
          <div style={{ width: "100%", position: "relative" }}>
            <LoadingOverlay visible={loading} overlayBlur={2} />
            <Table striped highlightOnHover withColumnBorders fontSize={12}>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Nome</th>
                  <th>Carteira</th>
                  <th>Corretora</th>
                  <th>Moviment History</th>
                  <th>Origem</th>
                  <th>Gross Value</th>
                  <th>Data</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </div>
          <Flex p="xs" justify={"center"} w={"100%"} align={"flex-end"}></Flex>
        </Flex>
      </SimpleGrid>
    </>
  );
}
