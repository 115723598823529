import {
  ActionIcon,
  Flex,
  LoadingOverlay,
  SimpleGrid,
  Table,
  Text,
} from "@mantine/core";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconTrash } from "@tabler/icons";

import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import req from "../../../services/AdmEscalonada";
import { SubTitlePage } from "../../SubTitlePage";
import ModalEditPfeeDate from "./ModalEdit";

import { modals } from "@mantine/modals";
import { formatFloatToBrl } from "../../../helpers/formatFloatToBrl";
import { IAdmEscalonada } from "../../../interfaces/IAdmEscalonada";
import NovaTaxaEscalonada from "../../../pages/Carteiras/DetalheCarteira/NovaTaxaEscalonada";

interface Props {
  id: string;
}

function TableAdmEscalonada({ id }: Props) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IAdmEscalonada[]>([]);

  async function handleDeletePfee(id: string) {
    setLoading(true);
    showNotification({
      id: "load-data",
      loading: true,
      title: "Removendo taxa escalonada",
      message: "Aguarde...",
      autoClose: true,
      disallowClose: true,
    });
    await req
      .delete(id)
      .then((value) => {
        fetchData();
        updateNotification({
          id: "load-data",
          color: "teal",
          title: "Sucesso!",
          message: `Taxa escalonada removida com sucesso!`,
          autoClose: 2000,
        });
        setLoading(false);

        return;
      })
      .catch((err) => {
        updateNotification({
          id: "load-data",
          title: "Erro!",
          message: `Ocorreu erro ao remover taxa escalonada!`,
          autoClose: 2000,
          color: "red",
        });
        fetchData();
        setLoading(false);
      });
  }

  const openModal = (patrimonio: number, tx_adm: number, id: any) =>
    modals.openConfirmModal({
      title: "Atenção!",
      children: (
        <Text size="sm">
          Você tem certeza que deseja remover a taxa?
          <br />
          <b>Patrimonio:</b> {`${patrimonio}`}
          <br />
          <b>Taxa de Adm:</b> {`${tx_adm}`}
        </Text>
      ),
      labels: { confirm: "Confirmar", cancel: "Cancelar" },
      onCancel: () => console.log("Cancelado"),
      onConfirm: () => handleDeletePfee(id),
    });
  const fetchData = async () => {
    setLoading(true);
    req
      .GetByNumeroCarteira(id)
      .then((res: AxiosResponse<{ data: IAdmEscalonada[] }>) => {
        setLoading(false);
        setData(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };

  const rows = data.map((element: IAdmEscalonada, idx: any) => (
    <tr key={element.id}>
      <td>{formatFloatToBrl(element.volume)}</td>
      <td>{element.tx_adm}% a.a.</td>

      <td>
        <Flex justify="center" gap="md">
          <ModalEditPfeeDate id={element.id.toString()} reload={fetchData} />
          <ActionIcon>
            <IconTrash
              size={20}
              stroke={1}
              color="red"
              onClick={() =>
                openModal(element.volume, element.tx_adm, element.id)
              }
            />
          </ActionIcon>
        </Flex>
      </td>
    </tr>
  ));

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="App" style={{}}>
      <SimpleGrid cols={2}>
        <Flex align={"left"}>
          <SubTitlePage title="Adm Escalonada"></SubTitlePage>
        </Flex>
        <Flex justify={"end"}>
          <NovaTaxaEscalonada reload={fetchData}></NovaTaxaEscalonada>
        </Flex>
      </SimpleGrid>

      <Flex w={"100%"} align={"center"} justify="center" direction={"column"}>
        <div style={{ width: "100%", position: "relative" }}>
          <LoadingOverlay visible={loading} overlayBlur={2} />
          <Table striped highlightOnHover withColumnBorders fontSize={12}>
            <thead>
              <tr>
                <th>Patrimônio</th>
                <th>Taxa de Adm</th>
                <td></td>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        </div>
      </Flex>
    </div>
  );
}

export default TableAdmEscalonada;
