
import axios from "axios";

import { handleLogout, getToken } from "../hooks/useUser";

axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.get["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.get["Access-Control-Allow-Origin"] = "*";

const urlProd = 'https://srca.alphamarinvest.com/api'
const urlDev = 'https://dev.srca.alphamarinvest.com/api'
const urlLocal = "http://localhost:5000"

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || urlLocal,
});

api.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		if (window.location.pathname === "/login") {
			return error;
		}

		if (error.response.status === 401) {
			return handleLogout();
		}
		return error;
	}
);
api.interceptors.request.use(
	function (config) {
		const token = getToken() ?? null;
		if (config.headers && token) {
			config.headers["Authorization"] = `Bearer ${token}`;
		}
		return config;
	},
	function (error) {
		//console.log({error})
		// Do something with request error
		return Promise.reject(error);
	}
);

export default api;
