import { Button } from '@mantine/core';
import React from 'react';
import * as XLSX from 'xlsx';

const ExportToExcel = ({ data, fileName }: any) => {
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    
    const workbook = {
      Sheets: { 'data': worksheet },
      SheetNames: ['data']
    };
    
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    

    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Button size='sm' onClick={exportToExcel}>Exportar para Excel</Button>
  );
};

export default ExportToExcel;
