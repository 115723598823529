import { Card, createStyles, Group, LoadingOverlay, Text } from "@mantine/core";
import { AxiosResponse } from "axios";
import moment from "moment";
import "moment-timezone";
import "moment/locale/pt-br";
import { useEffect, useState } from "react";
import { FormatFloatNumbers } from "../../../helpers/FormatFloatNumbers";
import { formatFloatToBrl } from "../../../helpers/formatFloatToBrl";
import { IMutableInfo } from "../../../interfaces/IMutableInfo";
import req from "../../../services/carteira";
import { SubTitlePage } from "../../SubTitlePage";
import ModalEditMutableInfo from "./ModalEdit";

moment.tz.setDefault("UTC");
moment.locale("pt-BR");

interface Props {
  id: string;
}

export function MutableInfoCarteira({ id }: Props) {
  const useStyles = createStyles((theme) => ({
    edit: {
      width: -0,
      height: -0,
      position: "relative",
      float: "right",
      left: -35,
      top: -10,
    },
    root: {
      height: "100%",
    },
    title: {
      fontWeight: 900,
      color: theme.colors.dark[3],
      [theme.fn.smallerThan("sm")]: {
        fontSize: 20,
      },
    },
    item: {
      gap: 1,
      display: "flex",
      flexDirection: "column",
      margin: 0,
      alignItems: "center",
    },
    text: {
      fontWeight: 400,
      margin: 0,
      color: theme.colors.dark[2],
      [theme.fn.smallerThan("sm")]: {
        fontSize: 10,
      },
    },

    description: {
      maxWidth: 600,
      margin: "auto",

      "&::after": {
        content: '""',
        display: "block",
        backgroundColor: theme.fn.primaryColor(),
        width: 85,
        height: 2,
        marginTop: theme.spacing.sm,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },

    card: {
      height: "100%",
      border: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[1]
      }`,
    },
  }));

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();

  const fetchData = async () => {
    setLoading(true);
    req
      .getMutableWalletInfoById(id)
      .then((res: AxiosResponse<{ data: IMutableInfo[] }>) => {
        setLoading(false);
        setData(res.data.data[0]);
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const { classes, theme } = useStyles();
  return (
    <div>
      <SubTitlePage title="Informações Mutáveis"></SubTitlePage>
      <div style={{ width: "100%", position: "relative" }}>
        <LoadingOverlay visible={loading} overlayBlur={2} />

        <Card shadow="xs" className={classes.card}>
          <div className={classes.edit}>
            <ModalEditMutableInfo
              id={id}
              refetch={fetchData}
            ></ModalEditMutableInfo>
          </div>
          <Group mt="xs">
            <Group className={classes.item}>
              <Text className={classes.text}>Provisão</Text>
              <Text className={classes.text}>
                <b>{data?.provisao ? formatFloatToBrl(data?.provisao) : "0"}</b>
              </Text>
            </Group>

            <Group className={classes.item}>
              <Text className={classes.text}>Valor ADM Acum.</Text>
              <Text className={classes.text}>
                <b>
                  {data?.valor_adm_acumulado
                    ? formatFloatToBrl(data?.valor_adm_acumulado)
                    : "0"}
                </b>
              </Text>
            </Group>

            <Group className={classes.item}>
              <Text className={classes.text}>PL Fech. Anterior</Text>
              <Text className={classes.text}>
                <b>
                  {data?.pl_fechamento_anterior
                    ? formatFloatToBrl(data?.pl_fechamento_anterior)
                    : "0"}
                </b>
              </Text>
            </Group>

            <Group className={classes.item}>
              <Text className={classes.text}>Qtd. Cotas</Text>
              <Text className={classes.text}>
                <b>
                  {data?.qtd_cotas ? FormatFloatNumbers(data?.qtd_cotas) : "0"}
                </b>
              </Text>
            </Group>
          </Group>
        </Card>
      </div>
    </div>
  );
}
