import React from 'react';
import { Select } from '@mantine/core';
import { UseControllerProps, useController } from 'react-hook-form';

type SelectOption = {
  value: string;
  label: string;
};

type SelectProps = Partial<UseControllerProps> & {
  label?: string;
  name: string;
  options: SelectOption[];
};

export function CustomSelect({ label, name, control, rules, options }: SelectProps) {
  const { field } = useController({
    control,
    defaultValue: '',
    name,
    rules,
  });

  return (
    <Select
      {...field}
      label={label}
      placeholder="Selecione"
      data={options}

    />
  );
}
