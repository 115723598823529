import {
  TextInput,
  PasswordInput,
  Checkbox,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { useUser } from '../../hooks/useUser';
import { useForm } from 'react-hook-form';
import { Input } from '../../components/Input/Index';
import { showNotification, updateNotification } from "@mantine/notifications";
import { useNavigate } from 'react-router-dom';


export function Login() {
  const {signIn, token, error, user} = useUser()

  const navigate = useNavigate();

  const {
		control,
		trigger,
		clearErrors,
		watch,
		getValues,
		setError,
		resetField
	} = useForm<any>()

  const [isLoading, setIsLoading] = useState(false)

	const handleSignIn = async () => {
    showNotification({
      id: "load-data",
      loading: true,
      title: "Realizando Login!",
      message: "Aguarde...",
      autoClose: true,
      disallowClose: true,
    });
		const payload = getValues()
		if(await signIn(payload)){
      updateNotification({
        id: "load-data",
        color: "green",
        title: "Sucesso!",
        message: "login realizado com sucesso!",
        autoClose: 2000,
      });
      return navigate("/");
    }

    updateNotification({
      id: "load-data",
      color: "red",
      title: "Erro!",
      message: error?.response,
      autoClose: 2000,
    });
	}

  useEffect(() => {
		if (error?.response) {
      updateNotification({
        id: "load-data",
        color: "red",
        title: "Erro!",
        message: error.response,
        autoClose: 2000,
      });
		}
	}, [error])

	useEffect(() => {
		watch((data, {name}) => {
			clearErrors(name)

		})
  }, [])
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    }}>
  <Container size={420} style={{display: 'flex', flexDirection:'column'}}>

    <Title size={18} color='gray' align='center'>Login SRCA - Alphamar Investimentos</Title>
      <Paper withBorder shadow="md" p={30} mt={30} radius="md" >
        <Input resetField={resetField} clearable={true} control={control} rules={{required: "Campo obrigatório"}} name={'email'} label="EMAIL" placeholder="Digite seu login" />
        <Input control={control} rules={{required: "Campo obrigatório"}} name={'password'} labelClassName={`w-full`} type={"password"} label={"SENHA"}/>
        <Group mt="lg">
          <Anchor component="button" size="sm">
            Esqueceu a senha?
          </Anchor>
        </Group>
        <Button fullWidth mt="xl" onClick={handleSignIn}>
          Login
        </Button>
      </Paper>

  </Container>
    </div>
  );
}