import {
  ActionIcon,
  Button,
  Center,
  Flex,
  LoadingOverlay,
  SimpleGrid,
  Table,
  Text,
} from "@mantine/core";
import { IconTrash } from "@tabler/icons";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { TitlePage } from "../../components/TitlePage";
import { formatFloatToBrl } from "../../helpers/formatFloatToBrl";
import { transformDateV2 } from "../../helpers/transformDateV2";
import movimentacao from "../../services/movimentacao";
import { DatePicker } from "@mantine/dates";
import { useParams } from "react-router-dom";
import wallet from "../../services/carteira";
import MovimentacoesXp from "../../services/movimentacoes_xp";
import { DateWithoutGmt } from "../../helpers/CreateObjectDateWithoutGmt";
import ExportToExcel from "../../helpers/ExportToExcel";

export default function MovimentacoesXpPage() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [name, setName] = useState('')
  const [dataInicial, setDataInicial] = useState<any>(new Date())
  const [dataFinal, setDataFinal] = useState<any>(new Date())

  useEffect(() => {
    fetchData();
  }, []);

  const { id: numero_carteira } = useParams();

  const handleChangeStartDate = (date: any) => {
    const newdate = DateWithoutGmt(date);

    setDataInicial(newdate);
  };

  const handleChangeEndDate = (date: any) => {
    const newdate = DateWithoutGmt(date);

    setDataFinal(newdate);
  };

  const fetchData = async () => {
    setLoading(true);
    MovimentacoesXp
      .getByWalletId({"n_carteira":numero_carteira,"data_inicial":dataInicial,"data_final":dataFinal})
      .then((res: AxiosResponse<any>) => {
        setLoading(false);
        setData(res.data);
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };

  const deleteItem = async (id: number) => {
    setLoading(true);
    MovimentacoesXp
      .delete(id)
      .then((res: AxiosResponse<any>) => {
        setLoading(false);
        fetchData();
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };

  const fetchUserData = async () => {
    setLoading(true);
    wallet
      .getWalletInfoById(numero_carteira ? numero_carteira : "")
      .then((res: AxiosResponse<any>) => {
        setLoading(false);
        console.log(res.data.data)
        setName(res.data.data[0].nome);
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };


  useEffect(() => {
    fetchUserData();
  }, []);

  const rows = data?.data?.map((element: any, idx: any) => (
    <tr key={element.id}>
      <td>{element.accountnumber}</td>
      <td>{transformDateV2(element.movimentacao)}</td>
      <td>{formatFloatToBrl(element.grossvalue)}</td>
      <td>{element.movementhistory}</td>
      <td>{element.origem}</td>
      <td>
        <Center>
          <ActionIcon>
            <IconTrash
              color="red"
              size={18}
              stroke={1}
              onClick={() => deleteItem(element.id)}
            />
          </ActionIcon>
        </Center>
      </td>
    </tr>
  ));

  return (
    <>
      <SimpleGrid cols={1}>

        <Flex align={"left"} direction="column">
          <TitlePage title={`Movimentações Xp- ${name}`} />
          <Flex align={"end"} gap="xs">
            <DatePicker
              value={dataInicial}
              label="Data Inicial"
              fullWidth
              placeholder="Data"
              withAsterisk
              onChange={(e) => handleChangeStartDate(e)}
            />
            <DatePicker
              value={dataFinal}
              label="Data Final"
              fullWidth
              placeholder="Data"
              withAsterisk
              onChange={(e) => handleChangeEndDate(e)}
            />
            <Button onClick={() => fetchData()}>Gerar Relatório</Button>
            {data?.data && data?.data.length > 0 && <ExportToExcel data={data.data} fileName={`movimentacoes_xp_${name}`} />}
          </Flex>
        </Flex>

        <Flex w={"100%"} align={"center"} justify="center" direction={"column"}>
          <div style={{ width: "100%", position: "relative" }}>
            <LoadingOverlay visible={loading} overlayBlur={2} />
          {rows ? <Table striped highlightOnHover withColumnBorders fontSize={12}>
              <thead>
                <tr>
                  <th>Carteira</th>
                  <th>Data</th>
                  <th>Gross</th>
                  <th>Movement History</th>
                  <th>Origem</th>
                </tr>
              </thead>
              <tbody>{rows ? rows : ''}</tbody>
            </Table> : <Text color="black">Sem movimentações para exibir.</Text>}
          </div>

          <Flex p="xs" justify={"center"} w={"100%"} align={"flex-end"}></Flex>
        </Flex>
      </SimpleGrid>
    </>
  );
}
