import { IconTrash } from "@tabler/icons";
import {ColumnProps} from "../../../types";
import { Spinner } from "@chakra-ui/react";
import EditRelationModal from "./EditRelationModal";
type TableColumnsProps = {
  onDelete: (id: number) => void
  idIsLoading: number|undefined,
  refetch:() => void
}
export const tableColumns = ({idIsLoading, onDelete, refetch }: TableColumnsProps) : ColumnProps[] => {
	return [
		{
			id: "id",
			header: "ID"
		},
    {
			id: "field",
			header: "Field"
		},
		{
			id: "name",
			header: "Nome"
		},
    {
			id: "value",
			header: "Valores"
		},
    {
      id: 'delete',
      header: "Delete",
      cell: (item) => <>
      {item?.row?.original?.id === idIsLoading ? <Spinner></Spinner> :
      <IconTrash size={20} cursor={'pointer'} stroke={1} color="red" onClick={() => onDelete(item.row.original.id)}/>}
</>
      
    }
		
	]
}