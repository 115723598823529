import { Button, FileInput, Group, Modal, SimpleGrid } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useDisclosure } from "@mantine/hooks";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconFileSpreadsheet, IconUpload } from "@tabler/icons";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { SubTitlePage } from "../../../../components/SubTitlePage";
import { transformDate } from "../../../../helpers/transformDate";
import carteira from "../../../../services/carteira";

export default function ImportarDadosModal() {
  const [opened, { open, close }] = useDisclosure(false);
  const { id } = useParams();

  const [dataCorte, setDataCorte] = useState<any>();
  const [file, setFile] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  function handleChangeDataCorte(date: any) {
    setDataCorte(date);
  }

  async function handlePostData() {
    setLoading(true);
    showNotification({
      id: "load-data",
      loading: true,
      title: "Importando cotas",
      message: "Aguarde...",
      autoClose: true,
      disallowClose: true,
    });
    await carteira
      .MigrarCotas(file, transformDate(dataCorte), id ? id : "")
      .then((value) => {
        updateNotification({
          id: "load-data",
          color: "teal",
          title: "Sucesso!",
          message: `Dados importados com sucesso!`,
          autoClose: 2000,
        });
        setLoading(false);

        return;
      })
      .catch((err) => {
        updateNotification({
          id: "load-data",
          title: "Erro!",
          message: `Erro ao importar dados!`,
          autoClose: 2000,
          color: "red",
        });
        setLoading(false);
      });
  }

  return (
    <>
      <Modal
        opened={opened}
        size="sm"
        onClose={close}
        dir="row"
        withCloseButton={false}
      >
        <SubTitlePage title="Importar Dados em Lote"></SubTitlePage>
        <SimpleGrid cols={1} breakpoints={[{ maxWidth: "sm", cols: 1 }]}>
          <FileInput
            value={file}
            withAsterisk
            label="Arquivo Excel"
            placeholder="xlsx"
            onChange={(e: any) => setFile(e)}
            icon={<IconUpload size={12} />}
          />
          <DatePicker
            value={dataCorte}
            label="Data de Corte"
            fullWidth
            placeholder="Data de Corte"
            withAsterisk
            onChange={(e) => handleChangeDataCorte(e)}
          />
        </SimpleGrid>
        <Button
          fullWidth
          size="md"
          mt="xs"
          onClick={() => handlePostData()}
          loading={loading}
        >
          Upload Arquivo
        </Button>
      </Modal>

      <Group position="center">
        <Button size="xs" leftIcon={<IconFileSpreadsheet />} onClick={open}>
          Importar Dados
        </Button>
      </Group>
    </>
  );
}
