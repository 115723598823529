export function DateWithoutGmt(dataString: string) {
  const data = new Date(dataString);

  const ano = data.getUTCFullYear();
  const mes = data.getUTCMonth();
  const dia = data.getUTCDate();
  const hora = data.getUTCHours();
  const minutos = data.getUTCMinutes();
  const segundos = data.getUTCSeconds();

  const dataUtc = new Date(ano, mes, dia, hora, minutos, segundos);

  return dataUtc;
}
