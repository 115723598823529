import {
  Button,
  Group,
  Modal,
  Select,
  SimpleGrid,
  Switch,
  TextInput,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useDisclosure } from "@mantine/hooks";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconEdit } from "@tabler/icons";
import { useEffect, useState } from "react";
import { SubTitlePage } from "../../../../components/SubTitlePage";
import { DateWithoutGmt } from "../../../../helpers/CreateObjectDateWithoutGmt";
import { transformDate } from "../../../../helpers/transformDate";
import carteira from "../../../../services/carteira";

interface Props {
  data: any;
}

export default function ModalEditCarteiraInfo({ data }: Props) {
  const [opened, { open, close }] = useDisclosure(false);

  const [date, setDate] = useState<any>("");
  const [id, setId] = useState<any>("");
  const [corretora, setCorretora] = useState<any>("");
  const [ativo, setAtivo] = useState<boolean>(false);
  const [documento, setDocumento] = useState("");
  const [numeroCarteira, setNumeroCarteira] = useState("");
  const [nome, setNome] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setId(data.id);
    setDate(DateWithoutGmt(data.data_inicio));
    setCorretora(data.corretora);
    setDocumento(data.documento);
    setNumeroCarteira(data.n_carteira);
    setNome(data.nome);
    setAtivo(data.ativo);
  }, [data]);

  async function handleUpdateData() {
    setLoading(true);
    showNotification({
      id: "load-data",
      loading: true,
      title: "Editando dados da Carteira",
      message: "Aguarde...",
      autoClose: true,
      disallowClose: true,
    });
    const datePython = await transformDate(date);
    await carteira
      .EditWalletInfoById(
        id,
        datePython,
        corretora,
        documento,
        numeroCarteira,
        nome,
        ativo
      )
      .then((value) => {
        updateNotification({
          id: "load-data",
          color: "teal",
          title: "Sucesso!",
          message: `Carteira editada com sucesso!`,
          autoClose: 2000,
        });
        setLoading(false);
        setDate(null);
        setCorretora("");
        setDocumento("");
        setNumeroCarteira("");
        setNome("");
        return;
      })
      .catch((err) => {
        updateNotification({
          id: "load-data",
          title: "Erro!",
          message: `Ocorreu erro ao editar a carteira!`,
          autoClose: 2000,
          color: "red",
        });
        setLoading(false);
      });
  }
  return (
    <>
      <Modal
        opened={opened}
        size="sm"
        onClose={close}
        dir="row"
        withCloseButton={false}
      >
        <SubTitlePage title="Editar Carteira"></SubTitlePage>
        <SimpleGrid cols={2} breakpoints={[{ maxWidth: "sm", cols: 1 }]}>
          <DatePicker
            value={date}
            label="Data Inicial"
            fullWidth
            placeholder="Data Inicial"
            withAsterisk
            onChange={(e) => setDate(e ? e : "")}
          />

          <TextInput
            label="Documento"
            value={documento}
            required
            onChange={(e) => setDocumento(e.target.value)}
          />

          <TextInput
            label="Carteira"
            value={numeroCarteira}
            required
            onChange={(e) => setNumeroCarteira(e.target.value)}
          />

          <Select
            label="Corretora"
            size="xs"
            value={corretora}
            placeholder={corretora}
            nothingFound="No options"
            onChange={(value) => setCorretora(value)}
            data={["BTG Pactual", "Xp"]}
          />

          <Switch
            label="Status"
            checked={ativo}
            onChange={() => setAtivo((current: any) => !current)}
          />
        </SimpleGrid>
        <Button
          fullWidth
          size="md"
          mt="xs"
          onClick={() => handleUpdateData()}
          loading={loading}
        >
          Salvar Edição
        </Button>
      </Modal>

      <Group position="center">
        <Button
          variant="subtle"
          size="xs"
          leftIcon={<IconEdit />}
          onClick={open}
        >
          Editar
        </Button>
      </Group>
    </>
  );
}
