import { Button, Flex, LoadingOverlay, SimpleGrid, Table } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { TitlePage } from "../../../components/TitlePage";
import { DateWithoutGmt } from "../../../helpers/CreateObjectDateWithoutGmt";
import { formatFloatToBrl } from "../../../helpers/formatFloatToBrl";
import { transformDateV2 } from "../../../helpers/transformDateV2";
import { IHistoricoCotas } from "../../../interfaces/IHistoricoCotas";
import wallet from "../../../services/carteira";
import relatorio from "../../../services/relatorios";
import ExportToExcel from "../../../helpers/ExportToExcel";

export default function HistoricoCotas() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IHistoricoCotas[]>();
  const [dateInicial, setDateInicial] = useState<any>(new Date());
  const [dateFinal, setDateFinal] = useState<any>(new Date());
  const [name, setName] = useState("");

  const { id: numero_carteira } = useParams();

  const fetchUserName = async () => {
    setLoading(true);
    wallet
      .getWalletInfoById(numero_carteira ? numero_carteira : "")
      .then((res: AxiosResponse<any>) => {
        setLoading(false);
        setName(res.data.data[0].nome);
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };

  useEffect(() => {
    fetchUserName();
  }, []);

  const handleChangeDateInicial = (date: any) => {
    const newdate = DateWithoutGmt(date);

    setDateInicial(newdate);
  };

  const handleChangeDateFinal = (date: any) => {
    const newdate = DateWithoutGmt(date);

    setDateFinal(newdate);
  };

  const rows = data?.map((element: IHistoricoCotas, idx: any) => (
    <tr key={element.id}>
      <td>{element.id}</td>
      <td>{transformDateV2(element?.data)}</td>
      <td>{formatFloatToBrl(element.adm_acumulada)}</td>
      <td>{formatFloatToBrl(element.ativo_final)}</td>
      <td>{element.qtd_cotas}</td>
      <td>{element.cota_fe}</td>
      <td>{formatFloatToBrl(element.pl)}</td>
      <td>{element.mov_cotas}</td>
      <td>{element.provisao}</td>
      <td>{transformDateV2(element?.data_att)}</td>
      <td>{element.valor_performance}</td>
    </tr>
  ));

  const fetchData = async () => {
    setLoading(true);
    const dateInicialTransformed = transformDateV2(dateInicial);
    const dateFinalTransformed = transformDateV2(dateFinal);
    relatorio
      .HistoricoDeCotas(
        numero_carteira ? numero_carteira : "",
        dateInicialTransformed,
        dateFinalTransformed
      )
      .then((res: AxiosResponse<{ data: IHistoricoCotas[] }>) => {
        setLoading(false);

        const sort = res.data.data.sort(function (a: any, b: any) {
          var dataA = new Date(a.data).getTime();
          var dataB = new Date(b.data).getTime();
          return dataA - dataB;
        });

        setData(sort);
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };

  return (
    <>
      <SimpleGrid cols={1}>
        <Flex align={"left"} direction="column">
          <TitlePage title={`Histórico de Cotas - ${name}`} />
          <Flex align={"end"} gap="xs">
            <DatePicker
              value={DateWithoutGmt(dateInicial)}
              label="Data Inicial"
              fullWidth
              placeholder="Data"
              withAsterisk
              onChange={(e) => handleChangeDateInicial(e)}
            />
            <DatePicker
              value={DateWithoutGmt(dateFinal)}
              label="Data Final"
              fullWidth
              placeholder="Data"
              withAsterisk
              onChange={(e) => handleChangeDateFinal(e)}
            />
            <Button onClick={() => fetchData()}>Gerar Relatório</Button>
            {data && data?.length > 0 && <ExportToExcel data={data} fileName={`historico_de_cotas_${name}`} />}
          </Flex>
        </Flex>

        <Flex w={"100%"} align={"center"} justify="center" direction={"column"}>
          <div style={{ width: "100%", position: "relative" }}>
            <LoadingOverlay visible={loading} overlayBlur={2} />
            <Table striped highlightOnHover withColumnBorders fontSize={12}>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Data</th>
                  <th>Adm Acumulado</th>
                  <th>Ativo Final</th>
                  <th>Quantidade Cotas</th>
                  <th>Cota Fe</th>
                  <th>PL</th>
                  <th>Mov Cotas</th>
                  <th>Provisao</th>
                  <th>Data de atualização</th>
                  <th>Valor Performance</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </div>
          <Flex p="xs" justify={"center"} w={"100%"} align={"flex-end"}></Flex>
        </Flex>
      </SimpleGrid>
    </>
  );
}
