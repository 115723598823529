import { Button, Flex, LoadingOverlay, SimpleGrid, Table } from "@mantine/core";
import { TitleBasic } from "../../../components/TitleBasic";
import { Input } from "../../../components/Input/Index";
import { useForm } from "react-hook-form";
import { CustomDatePicker } from "../../../components/InputDate";
import { CustomSelect } from "../../../components/Select";
import { CheckBox } from "../../../components/CheckBox";
import { GetFormAnbimaInputByCarteira, registerFormAnbimaInput } from "../../../services/formInputAnbima";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { showNotification } from "@mantine/notifications";
import { transformDateV2 } from "../../../helpers/transformDateV2";
import { DateWithoutGmt } from "../../../helpers/CreateObjectDateWithoutGmt";
import { transformDate } from "../../../helpers/transformDate";

export default function UserFormInput() {
  const { id: n_carteira } = useParams();
  const {
		control,
		trigger,
		clearErrors,
		watch,
		getValues,
		resetField,
		reset,
    setValue
	} = useForm<any>({
    defaultValues: {
      allows_crypto: false,
      allows_derivatives: false,
      allows_foreign_investment: false,
      allows_own_fund_investment: false,
      allows_private_credit: false,
      anbima_code: '',
      has_complementary_fee: false,
      has_controller: false,
      has_custodian: false,
      has_performance_fee: false,
      investor_risk_profile: '',
      investor_segment: '',
      management_fee_description: '',
      management_fee_type: '',
      model: '',
      n_carteira: n_carteira,
      portfolio_risk_profile: '',
      pricing_by: '',
      pricing_responsible_cnpj: '',
      status: '',
      target_audience: '',
      termination_date: null ,
      uses_benchmark: false
    }
  })

  const [loading, setLoading] = useState(false)



  interface AnbimaInputInfoStatic {
  id?: number; // Código identificador da carteira
  anbima_code: string; // Código ANBIMA
  status: 'Ativa' | 'Inativa' | 'Encerrada'; // Status da carteira
  termination_date?: string; // Data de encerramento do contrato
  model: 'Padronizada' | 'Customizada'; // Modelo de carteira
  target_audience: string; // Público-alvo
  investor_segment: string; // Segmento do investidor
  portfolio_risk_profile: string; // Perfil de risco da carteira
  investor_risk_profile: string; // Perfil de risco do investidor
  allows_private_credit: boolean; // Permite crédito privado
  allows_foreign_investment: boolean; // Permite investimento no exterior
  allows_own_fund_investment: boolean; // Permite investimento em cotas de fundos próprios
  allows_derivatives: boolean; // Permite derivativos
  derivatives_strategies?: string; // Estratégias permitidas com derivativos, caso aplicável
  management_fee_type: string; // Tipo de taxa de gestão
  management_fee_description: string; // Descrição da taxa de gestão
  has_complementary_fee: boolean; // Cobrança de taxa complementar
  complementary_fee_description?: string; // Descrição da taxa complementar
  complementary_fee_type?: string; // Tipo de cobrança de taxa complementar
  complementary_fee_value?: number; // Valor da taxa complementar
  has_performance_fee: boolean; // Cobrança de taxa de performance
  performance_fee_description?: string; // Descrição da taxa de performance
  performance_fee_type?: string; // Tipo de cobrança de taxa de performance
  uses_benchmark: boolean; // Utiliza benchmark
  benchmark?: string; // Benchmark, caso aplicável
  has_custodian: boolean; // Custodiante contratado
  custodian_cnpj?: string; // CNPJ do custodiante contratado, caso aplicável
  has_controller: boolean; // Controlador contratado
  controller_cnpj?: string; // CNPJ do controlador contratado, caso aplicável
  pricing_by: 'Própria' | 'Terceiro'; // Apreçamento da carteira é realizado pelo
  pricing_responsible_cnpj?: string; // CNPJ do responsável pelo apreçamento
  allows_crypto: boolean; // Permite criptoativos
  n_carteira: string; // Número da carteira
}
  async function fetchData() {
    setLoading(true);
    try {
      const response = await GetFormAnbimaInputByCarteira(n_carteira);
      if (response.status === 200) {
        
        const data: AnbimaInputInfoStatic = response.data.data[0];

        if (data.termination_date){
          const terminationDate = DateWithoutGmt(data.termination_date) 
          return reset({...data, termination_date: terminationDate});
        }
        reset({...data});
        
      }
    } catch (error) {
      console.error('Failed to fetch data', error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  },[n_carteira, reset])

  async function handlePostData() {
    setLoading(true)
    if (!await trigger()) {
			return
		}

    const payloadHookForm = await getValues()
    const payload = {...payloadHookForm, n_carteira: n_carteira}

    const response = await registerFormAnbimaInput(payload)
    
    if (response.status === 201 || response.status === 200){
        setLoading(false)
       return showNotification({
        id: "load-data32",
        title: 'Sucesso!',
        message: response.data.message,
        autoClose: true,
        disallowClose: true,
        color: "green",
      });
    }
    setLoading(false)
    return showNotification({
      id: "load-data32",
      title: 'Erro!',
      message: response.response.data.message,
      autoClose: true,
      disallowClose: true,
      color: "red",
    });

    

  }
  const allows_derivatives = watch('allows_derivatives')
  const has_complementary_fee = watch('has_complementary_fee');
  const has_performance_fee = watch('has_performance_fee')
  const uses_benchmark = watch('uses_benchmark')
  const has_custodian = watch('has_custodian')
  const has_controller = watch('has_controller')

  useEffect(() => {
    if (!allows_derivatives) {
      setValue('derivatives_strategies', null);
    }

    if (!has_complementary_fee) {
      setValue('complementary_fee_description', null);
      setValue('complementary_fee_type', null);
      setValue('complementary_fee_value', null);
    }

    if (!has_performance_fee) {
      setValue('performance_fee_description', null);
      setValue('performance_fee_type', null);
      setValue('performance_fee_value', null);
    }
    
    if (!uses_benchmark) {
      setValue('benchmark', null);
    }

    if (!has_custodian) {
      setValue('custodian_cnpj', null);
    }

    if (!has_controller) {
      setValue('controller_cnpj', null);
    }

  }, [allows_derivatives, has_complementary_fee, has_performance_fee, uses_benchmark, has_custodian, has_controller, setValue]);



  return (
    <>

    <TitleBasic title='Formulário Form Input Anbima' />
      <Flex className='!pb-4'>
        <p className="text-black py-2">Este formulário recebe informações do usuário que serão anexadas no formulário Anbima Input, enviado mensalmente aos orgãos reguladores</p>
      </Flex>
        <Flex className="gap-2 flex-col">
         <SimpleGrid cols={4} className="items-center" breakpoints={[{ maxWidth: "sm", cols: 1 }]}>
      <Input
        label="Código ANBIMA"
        placeholder="Digite o código ANBIMA"
        resetField={resetField}
        clearable={true}
        control={control}
        name={'anbima_code'}
        isRequired
      />
      <CustomSelect
        options={[
          { value: 'Ativa', label: 'Ativa' },
          { value: 'Inativa', label: 'Inativa' },
          { value: 'Encerrada', label: 'Encerrada' },
        ]}
        label="Status da Carteira"
        control={control}
        name={'status'}
      />
      <CustomDatePicker
        name="termination_date"
        control={control}
        label="Data de Encerramento"
      />
      <CustomSelect
        options={[
          { value: 'Padronizada', label: 'Padronizada' },
          { value: 'Customizada', label: 'Customizada' },
        ]}
        label="Modelo da Carteira"
        control={control}
        name={'model'}
      />
      <CustomSelect
        options={[
          { value: 'Profissional', label: 'Profissional' },
          { value: 'Qualificado', label: 'Qualificado' },
          { value: 'Investidores em geral', label: 'Investidores em geral' }
        ]}
        label="Público-alvo"
        control={control}
        name={'target_audience'}
      />
      <CustomSelect
        options={[
          { value: 'Capitalização', label: 'Capitalização' },
          { value: 'Corporate', label: 'Corporate' },
          { value: 'EAPC', label: 'EAPC' },
          { value: 'EFPC Empresa Privada', label: 'EFPC Empresa Privada' },
          { value: 'EFPC Empresa Pública', label: 'EFPC Empresa Pública' },
          { value: 'Instituições Financeiras', label: 'Instituições Financeiras' },
          { value: 'Investidor não residente', label: 'Investidor não residente' },
          { value: 'Middle Market', label: 'Middle Market' },
          { value: 'Poder Público', label: 'Poder Público' },
          { value: 'Private', label: 'Private' },
          { value: 'RPPS', label: 'RPPS' },
          { value: 'Seguradora', label: 'Seguradora' },
          { value: 'Varejo', label: 'Varejo' },
          { value: 'Varejo Alta Renda', label: 'Varejo Alta Renda' },
          
        ]}
        label="Segmento do Investidor"
        control={control}
        name={'investor_segment'}
      />
      <CustomSelect
        options={[
          { value: 'Arrojado', label: 'Arrojado' },
          { value: 'Balanceado', label: 'Balanceado' },
          { value: 'Conservador', label: 'Conservador' },
          { value: 'Dinamico', label: 'Dinamico' },
          { value: 'Moderado', label: 'Moderado' }
        ]}
        label="Perfil de Risco da Carteira"
        control={control}
        name={'portfolio_risk_profile'}
      />
      <CustomSelect
        options={[
          { value: 'Arrojado', label: 'Arrojado' },
          { value: 'Balanceado', label: 'Balanceado' },
          { value: 'Conservador', label: 'Conservador' },
          { value: 'Dinamico', label: 'Dinamico' },
          { value: 'Moderado', label: 'Moderado' }
        ]}
        label="Perfil de Risco do Investidor"
        control={control}
        name={'investor_risk_profile'}
      />
      </SimpleGrid>
      <TitleBasic title='Permissões'></TitleBasic>
        <SimpleGrid cols={5}>
      <CheckBox
        name="allows_private_credit"
        control={control}
        label="Permite Crédito Privado"
      />
      <CheckBox
        name="allows_foreign_investment"
        control={control}
        label="Permite Investimento no Exterior"
      />
      <CheckBox
        name="allows_own_fund_investment"
        control={control}
        label="Permite Investimento em Fundos Próprios"
      />
      
        </SimpleGrid>

      <TitleBasic title="Derivativos"/>
      <SimpleGrid cols={5} className="items-center">
      
      <CheckBox
        name="allows_derivatives"
        control={control}
        label="Permite Derivativos"
      />

      {watch().allows_derivatives && <CustomSelect
        options={[
          { value: 'Hedge', label: 'Hedge' },
          { value: 'Alavancagem', label: 'Alavancagem' },
          { value: 'Posicionamento', label: 'Posicionamento' },
          {value: 'Hedge, Alavancagem', label: 'Hedge, Alavancagem' },
          {value: 'Hedge, Alavancagem, Posicionamento', label: 'Hedge, Alavancagem, Posicionamento' },
          {value: 'Hedge, Posicionamento', label: 'Hedge, Posicionamento' },
          {value: 'Alavancagem, Posicionamento', label: 'Alavancagem, Posicionamento' },

        ]}
        label="Estratégias com Derivativos"
        control={control}
        name={'derivatives_strategies'}
      />}


</SimpleGrid>

<TitleBasic title='Taxas'></TitleBasic>
  <SimpleGrid cols={5} className="items-end">

     <CustomSelect
        options={[
          {value: 'Fixa', label: 'Fixa' },
          {value: 'Percentual', label: 'Percentual' },
          {value: 'Especifica', label: 'Especifica' },
          {value: 'Escalonada', label: 'Escalonada' },
          {value: 'Nao há cobrança de taxa de gestão', label: 'Nao há cobrança de taxa de gestão' },

        ]}
        label="Tipo de Taxa de Gestão"
        control={control}
        name={'management_fee_type'}
      />
      {(watch().management_fee_type === 'Fixa' || watch().management_fee_type === 'Percentual') && (
        <Input
          label="Valor taxa de gestão"
          placeholder="Valor taxa de gestão"
          mask={watch().management_fee_type === 'Fixa' ? 'BRL' :
                    watch().management_fee_type === 'Percentual' ? '%' : undefined}
          resetField={resetField}
          clearable={true}
          control={control}
          name={'management_fee_value'}
        />
      )}
      <Input
        label="Descrição da Taxa de Gestão"
        placeholder="Digite a descrição da taxa de gestão"
        resetField={resetField}
        clearable={true}
        control={control}
        name={'management_fee_description'}
      />
      <CheckBox
        name="has_complementary_fee"
        control={control}
        label="Cobrança de Taxa Complementar"
      />
     {watch().has_complementary_fee && (
        <>
          <Input
            label="Descrição da Taxa Complementar"
            placeholder="Digite a descrição da taxa complementar"
            resetField={resetField}
            clearable={true}
            control={control}
            name="complementary_fee_description"
          />
          <CustomSelect
            options={[
              { value: 'Fixa', label: 'Fixa' },
              { value: 'Percentual', label: 'Percentual' },
              { value: 'Específica', label: 'Específica' },
              
            ]}
            label="Tipo de cobrança taxa complementar"
            control={control}
            name={'complementary_fee_type'}
          />
          {(watch().complementary_fee_type === 'Fixa' || watch().complementary_fee_type === 'Percentual') && (
                  <Input
                    mask={watch().complementary_fee_type === 'Fixa' ? 'BRL' :
                    watch().complementary_fee_type === 'Percentual' ? '%' : undefined}
                    label="Valor da Taxa Complementar"
                    placeholder="Valor da Taxa Complementar"
                    resetField={resetField}
                    clearable={true}
                    control={control}
                    name={'complementary_fee_value'}
                  />
                )}
        </>
)}
      <CheckBox
        name="has_performance_fee"
        control={control}
        label="Cobrança de Taxa de Performance"
      />

      { watch().has_performance_fee ?
      <Input
        label="Descrição da Taxa de Performance"
        placeholder="Digite a descrição da taxa de performance"
        resetField={resetField}
        clearable={true}
        control={control}
        name={'performance_fee_description'}
      /> : '' }

  { watch().has_performance_fee &&
      <CustomSelect
      options={[
        { value: 'Fixa', label: 'Fixa' },
        { value: 'Percentual', label: 'Percentual' },
        { value: 'Especifica', label: 'Especifica' },
      ]}
      label="Tipo de Cobrança de Taxa de Performance"
      control={control}
      name={'performance_fee_type'}
    /> }

    {(watch().performance_fee_type === 'Fixa' || watch().performance_fee_type === 'Percentual') && (
      <Input
        mask={watch().performance_fee_type === 'Fixa' ? 'BRL' :
        watch().performance_fee_type === 'Percentual' ? '%' : undefined}
        label="Valor taxa de performance"
        placeholder="Digite o valor da taxa de performance"
        resetField={resetField}
        clearable={true}
        control={control}
        name={'performance_fee_value'}
      />
    )}

      </SimpleGrid>


<TitleBasic title='Outros'></TitleBasic>
      <SimpleGrid cols={5} className="items-end">
      <CheckBox
        name="uses_benchmark"
        control={control}
        label="Utiliza Benchmark"
      />

      {watch().uses_benchmark &&
      <CustomSelect
      options={[
        { value: 'CDI', label: 'CDI' },
        { value: 'IBOVESPA', label: 'IBOVESPA' },
        { value: 'IBOVESPA FECHAMENTO', label: 'IBOVESPA FECHAMENTO' },
        { value: 'IBOVESPA MÉDIO', label: 'IBOVESPA MÉDIO' },
        
      ]}
      label="Benchmark"
      control={control}
      name={'benchmark'}
    />}

      <CheckBox
        name="has_custodian"
        control={control}
        label="Custodiante Contratado"
      />
      {watch().has_custodian ? 
      <Input
        label="CNPJ do Custodiante"
        placeholder="Digite o CNPJ do custodiante"
        resetField={resetField}
        clearable={true}
        control={control}
        name={'custodian_cnpj'}
      /> : '' }

      <CheckBox
        name="has_controller"
        control={control}
        label="Controlador Contratado"
      />
      {watch().has_controller ?
      <Input
        label="CNPJ do Controlador"
        placeholder="Digite o CNPJ do controlador"
        resetField={resetField}
        clearable={true}
        control={control}
        name={'controller_cnpj'}
      /> : '' }
      <CustomSelect
        options={[
          { value: 'Própria', label: 'Própria' },
          { value: 'Terceiros', label: 'Terceiros' },
        ]}
        label="Apreçamento Realizado por"
        control={control}
        name={'pricing_by'}
      />
      <Input
        label="CNPJ do Responsável pelo Apreçamento"
        placeholder="Digite o CNPJ do responsável pelo apreçamento"
        resetField={resetField}
        clearable={true}
        control={control}
        name={'pricing_responsible_cnpj'}
      />
      <CustomSelect
        options={[
          { value: 'Permite diretamente', label: 'Permite diretamente' },
          { value: 'Permite Indiretamente', label: 'Permite Indiretamente' },
          { value: 'Não permite', label: 'Não permite' },
          
        ]}
        label="Permite Criptoativos"
        control={control}
        name={'allows_crypto'}
      />
        </SimpleGrid>
        <Flex>
        <Button
        loading={loading}
          size="sm"
          onClick={() => handlePostData()}
        >
          Salvar
        </Button>
          </Flex>

          
          
          </Flex>
    </>
  );
}
