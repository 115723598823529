import api from "./api";

const req = {
  getAll: async () => {
    const response = await api.get(`/police_risk_pair`);

    return response;
  },

  create: async (params:any) => {
    const response = await api.post(`/police_risk_pair`, params);

    return response;
  },

  delete: async(id:any) => {
    const response = await api.delete(`/police_risk_pair?id=${id}`);

    return response;
  },
};

export default req;
