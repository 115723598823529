import api from "./api";

const MovimentacoesXp = {
  getByWalletId: async (params:any) => {
    return await api.get(`/movimentacoes-xp`,{params}).catch(({response}) => {
      return response
    })
  },

  delete: async (id: number) => {
    const response = await api.delete(`/movimentacoes-xp?id=${id}`);

    return response;
  },
};

export default MovimentacoesXp;
