import { IconTrash } from "@tabler/icons";
import {ColumnProps} from "../../../types";
import { Spinner } from "@chakra-ui/react";
import EditRelationModal from "./EditRelationModal";
type TableColumnsProps = {
  onDelete: (id: number) => void
  idIsLoading: number|undefined,
  refetch:() => void
}
export const tableColumns = ({idIsLoading, onDelete, refetch }: TableColumnsProps) : ColumnProps[] => {
	return [
		{
			id: "id",
			header: "ID"
		},
    {
      id: 'dimension',
      header: "ID Dimension Relacionado",
      cell: (item) => <>
      {item.row.original.dimension.id}
</>
      
    },
    {
      id: 'dimension',
      header: "Nome Dimension Relacionado",
      cell: (item) => <>
      {item.row.original.dimension.name}
</>
      
    },

		{
			id: "limit",
			header: "Limite"
		},
		{
			id: "portfolio",
			header: "Portfolio"
		},
    {
			id: "type_limit",
			header: "Tipo Limite"
		},
    {
			id: "type_policies",
			header: "Tipo Politica"
		},
    {
      id: 'delete',
      header: "Delete",
      cell: (item) => <>
      {item?.row?.original?.id === idIsLoading ? <Spinner></Spinner> :
      <IconTrash size={20} cursor={'pointer'} stroke={1} color="red" onClick={() => onDelete(item.row.original.id)}/>}
      <EditRelationModal reload={refetch} id={item?.row?.original?.id}/>
</>
      
    }
		
	]
}