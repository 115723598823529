import {
  Button,
  Group,
  Modal,
  Select,
  SimpleGrid,
  TextInput,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useDisclosure } from "@mantine/hooks";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconFilePlus } from "@tabler/icons";
import { useState } from "react";
import { InputMask } from "../../../components/InputMask/MaskInput";
import { TitleBasic } from "../../../components/TitleBasic";
import { transformDateV2 } from "../../../helpers/transformDateV2";
import movimentacao from "../../../services/movimentacao";

interface Props {
  refetch: () => void;
}

export default function MovManualModal({ refetch }: Props) {
  const [opened, { open, close }] = useDisclosure(false);

  const [carteira, setCarteira] = useState<any>("");
  const [movementHistory, setMovementHistory] = useState<any>("");
  const [origem, setOrigem] = useState<any>("");
  const [grossValue, setGrossValue] = useState<any>("");
  const [date, setDate] = useState<any>("");
  const [loading, setLoading] = useState<any>("");

  async function handlePostData() {
    setLoading(true);
    showNotification({
      id: "load-data",
      loading: true,
      title: "Adicionando nova movimentação",
      message: "Aguarde...",
      autoClose: true,
      disallowClose: true,
    });
    const dateTransformed = transformDateV2(date);
    await movimentacao
      .add(carteira, movementHistory, origem, grossValue, dateTransformed)
      .then((value) => {
        updateNotification({
          id: "load-data",
          color: "teal",
          title: "Sucesso!",
          message: `Movimentação Cadastrada com Sucesso!`,
          autoClose: 2000,
        });

        setLoading(false);
        setMovementHistory("");
        setOrigem("");
        setGrossValue("");
        setDate(null);
        refetch();

        return;
      })
      .catch((err) => {
        updateNotification({
          id: "load-data",
          title: "Erro!",
          message: `Ocorreu erro ao criar nova movimentação!`,
          autoClose: 2000,
          color: "red",
        });
        setLoading(false);
      });
  }
  return (
    <>
      <Modal
        opened={opened}
        size="md"
        onClose={close}
        dir="row"
        withCloseButton={false}
      >
        <TitleBasic title="Adicionar Movimentação Manual"></TitleBasic>
        <SimpleGrid cols={1} breakpoints={[{ maxWidth: "sm", cols: 1 }]}>
          <TextInput
            label="Carteira"
            placeholder="0004373"
            value={carteira}
            required
            onChange={(e) => setCarteira(e.target.value)}
          />
          <TextInput
            value={movementHistory}
            label="Movement History"
            placeholder="Text"
            required
            onChange={(e) => setMovementHistory(e.target.value)}
          />

          <Select
            label="Origem"
            value={origem}
            withinPortal
            data={[
              { value: "CADASTRAR", label: "CADASTRAR" },
              { value: "NEGOCIACAO_ATIVO", label: "NEGOCIACAO_ATIVO" },
              { value: "INTRA_ATIVO", label: "INTRA_ATIVO" },
              {
                value: "INTRA_ATIVO_PASSIVO_ADM",
                label: "INTRA_ATIVO_PASSIVO_ADM",
              },
              {
                value: "INTRA_ATIVO_PASSIVO_PFEE",
                label: "INTRA_ATIVO_PASSIVO_PFEE",
              },
              { value: "INFRA_PL", label: "INFRA_PL" },
            ]}
            onChange={(e) => {
              setOrigem(e);
            }}
            placeholder="Origem"
          ></Select>

          <InputMask
            label="Gross"
            placeholder="R$4.000"
            onChange={setGrossValue}
          />

          <DatePicker
            value={date}
            label="Data"
            fullWidth
            placeholder="Data"
            withAsterisk
            onChange={(e) => setDate(e ? e : "")}
          />
        </SimpleGrid>
        <Button
          fullWidth
          size="md"
          mt="xs"
          onClick={() => handlePostData()}
          loading={loading}
        >
          Adicionar Movimentação
        </Button>
      </Modal>

      <Group position="center">
        <Button leftIcon={<IconFilePlus />} onClick={open}>
          Add Mov.
        </Button>
      </Group>
    </>
  );
}
