import {
  ActionIcon,
  Button,
  Center,
  Flex,
  LoadingOverlay,
  SimpleGrid,
  Table,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconTrash } from "@tabler/icons";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ModalEditPosicoes from "../../../components/Relatorios/HistoricoPosicoes/ModalEditHistoricoPosicao";
import { TitlePage } from "../../../components/TitlePage";
import { DateWithoutGmt } from "../../../helpers/CreateObjectDateWithoutGmt";
import { formatFloatToBrl } from "../../../helpers/formatFloatToBrl";
import { transformDateV2 } from "../../../helpers/transformDateV2";
import { IHistoricoPosicoes } from "../../../interfaces/IHistoricoPosicoes";
import wallet from "../../../services/carteira";
import {
  default as relatorio,
  default as relatorios,
} from "../../../services/relatorios";
import AdicionarPosicaoModal from "./AdicionarPosicaoModal";
import ExportToExcel from "../../../helpers/ExportToExcel";

export default function HistoricoPosicoes() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IHistoricoPosicoes[]>();
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const [name, setName] = useState("");

  const { id: numero_carteira } = useParams();

  const fetchUserName = async () => {
    setLoading(true);
    wallet
      .getWalletInfoById(numero_carteira ? numero_carteira : "")
      .then((res: AxiosResponse<any>) => {
        setLoading(false);
        setName(res.data.data[0].nome);
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };

  const fetchData = async () => {
    setLoading(true);
    const startDateTransformed = transformDateV2(startDate);
    const endDateTransformed = transformDateV2(endDate);
    relatorio
      .HistoricoPosicoes(
        numero_carteira ? numero_carteira : "",
        startDateTransformed,
        endDateTransformed
      )
      .then((res: AxiosResponse<{ data: IHistoricoPosicoes[] }>) => {
        setLoading(false);
        setData(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };

  async function handleDeletePosicao(id: string) {
    setLoading(true);
    showNotification({
      id: "load-data",
      loading: true,
      title: "Removendo Posicao",
      message: "Aguarde...",
      autoClose: true,
      disallowClose: true,
    });
    await relatorios
      .DeletarPosicao(id)
      .then((value: any) => {
        updateNotification({
          id: "load-data",
          color: "teal",
          title: "Sucesso!",
          message: `Posição removida com sucesso!`,
          autoClose: 2000,
        });
        setLoading(false);
        fetchData();

        return;
      })
      .catch((err) => {
        updateNotification({
          id: "load-data",
          title: "Erro!",
          message: `Ocorreu erro ao remover posição`,
          autoClose: 2000,
          color: "red",
        });
        setLoading(false);
      });
  }

  useEffect(() => {
    fetchUserName();
  }, []);

  const handleChangeStartDate = (date: any) => {
    const newdate = DateWithoutGmt(date);

    setStartDate(newdate);
  };

  const handleChangeEndDate = (date: any) => {
    const newdate = DateWithoutGmt(date);

    setEndDate(newdate);
  };

  const rows = data?.map((element: any, idx: any) => (
    <tr key={element.id}>
      <td>{element.id}</td>
      <td>{transformDateV2(element.data)}</td>
      <td>{formatFloatToBrl(element.ativo_final)}</td>
      <td>
        <ModalEditPosicoes
          id_position={element.id}
          n_carteira={numero_carteira ? numero_carteira : ""}
          value_position={element.ativo_final}
          date={transformDateV2(element.data)}
          reload={fetchData}
        ></ModalEditPosicoes>
      </td>
      <td>
        <Center>
          <ActionIcon>
            <IconTrash
              color="red"
              size={18}
              stroke={1}
              onClick={() => handleDeletePosicao(element.id)}
            />
          </ActionIcon>
        </Center>
      </td>
    </tr>
  ));

  return (
    <>
      <SimpleGrid cols={1}>
        <TitlePage title={`Histórico de posições - ${name}`}></TitlePage>

        <SimpleGrid cols={2}>
          <Flex align={"end"} gap="xs">
            <DatePicker
              value={DateWithoutGmt(startDate)}
              label="Data Inicial"
              fullWidth
              placeholder="Data"
              withAsterisk
              onChange={(e) => handleChangeStartDate(e)}
            />

            <DatePicker
              value={DateWithoutGmt(endDate)}
              label="Data Final"
              fullWidth
              placeholder="Data"
              withAsterisk
              onChange={(e) => handleChangeEndDate(e)}
            />
            <Button onClick={() => fetchData()}>Gerar Relatório</Button>
            {data && data?.length > 0 && <ExportToExcel data={data} fileName={`historico_posicoes_${name}`} />}
          </Flex>
          <Flex align="end" justify={"end"}>
            <AdicionarPosicaoModal></AdicionarPosicaoModal>
          </Flex>
        </SimpleGrid>

        <Flex w={"100%"} align={"center"} justify="center" direction={"column"}>
          <div style={{ width: "100%", position: "relative" }}>
            <LoadingOverlay visible={loading} overlayBlur={2} />
            <Table striped highlightOnHover withColumnBorders fontSize={12}>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Data</th>
                  <th>Ativo Final</th>
                  <th>Editar Posição</th>
                  <th>Apagar</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </div>

          <Flex p="xs" justify={"center"} w={"100%"} align={"flex-end"}></Flex>
        </Flex>
      </SimpleGrid>
    </>
  );
}
