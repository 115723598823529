import { Button, Group, Modal, SimpleGrid } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconEdit } from "@tabler/icons";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { SubTitlePage } from "../../../../components/SubTitlePage";
import req from "../../../../services/carteira";
import { InputMask } from "../../../InputMask/MaskInput";
import { InputMaskQtd } from "../../../InputMask/MaskInputQtd";

interface MutableInfoWallet {
  data: Date;
  provisao: string;
  valor_adm_acumulado: string;
  pl_fechamento_anterior: string;
  qtd_cotas: string;
  carteira: string;
  id?: string;
}

interface Props {
  id: string;
  refetch: () => void;
}

export default function ModalEditMutableInfo({ id, refetch }: Props) {
  const [opened, { open, close }] = useDisclosure(false);

  const [provisao, setProvisao] = useState<any>("");
  const [valorAdmAcumulado, setValorAdmAcumulado] = useState<any>("");
  const [plFechamentoAnterior, setPlFechamentoAnterior] = useState<string>("");
  const [qtdCotas, setQdtCotas] = useState<string>("");
  const [carteira, setCarteira] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);

  const fetchData = async () => {
    setLoading(true);
    req
      .getMutableWalletInfoById(id)
      .then((res: AxiosResponse<{ data: any[] }>) => {
        setLoading(false);
        setData(res.data.data[0]);
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setProvisao(data?.provisao);
    setValorAdmAcumulado(data?.valor_adm_acumulado);
    setPlFechamentoAnterior(data?.pl_fechamento_anterior);
    setQdtCotas(data?.qtd_cotas);
    setCarteira(data?.carteira);
  }, [data]);

  async function handleUpdateData() {
    setLoading(true);
    showNotification({
      id: "load-data",
      loading: true,
      title: "Editando Carteira",
      message: "Aguarde...",
      autoClose: true,
      disallowClose: true,
    });
    await req
      .EditMutableWalletInfoById(
        provisao,
        valorAdmAcumulado,
        plFechamentoAnterior,
        qtdCotas,
        carteira
      )
      .then((value) => {
        updateNotification({
          id: "load-data",
          color: "teal",
          title: "Sucesso!",
          message: `Carteira editada com sucesso!`,
          autoClose: 2000,
        });
        refetch();
        fetchData();
        setLoading(false);
        return;
      })
      .catch((err) => {
        updateNotification({
          id: "load-data",
          title: "Erro!",
          message: `Ocorreu erro ao editar carteira!`,
          autoClose: 2000,
          color: "red",
        });
        setLoading(false);
      });
  }
  return (
    <>
      <Modal
        opened={opened}
        size="xg"
        onClose={close}
        dir="row"
        withCloseButton={false}
      >
        <SubTitlePage title="Editar Informações Mutáveis"></SubTitlePage>
        <SimpleGrid cols={2} breakpoints={[{ maxWidth: "sm", cols: 1 }]}>
          <InputMask
            label="Provisão"
            placeholder="R$ 543,56"
            defaultValue={provisao}
            onChange={setProvisao}
          />
          <InputMask
            label="Valor ADM Acuml"
            placeholder="R$ 543,56"
            defaultValue={valorAdmAcumulado}
            onChange={setValorAdmAcumulado}
          />

          <InputMask
            label="PL Fech. Anterior"
            placeholder="R$ 543,56"
            defaultValue={plFechamentoAnterior}
            onChange={setPlFechamentoAnterior}
          />

          <InputMaskQtd
            label="Qtd. Cotas"
            placeholder="43"
            defaultValue={qtdCotas}
            onChange={setQdtCotas}
          />
        </SimpleGrid>
        <Button
          fullWidth
          size="md"
          mt="xs"
          onClick={() => handleUpdateData()}
          loading={loading}
        >
          Salvar Edição
        </Button>
      </Modal>

      <Group position="center">
        <Button
          variant="subtle"
          size="xs"
          leftIcon={<IconEdit />}
          onClick={open}
          z-tabIndex={2}
        >
          Editar
        </Button>
      </Group>
    </>
  );
}
