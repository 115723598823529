import { Button, Flex, Group, Modal, Select, SimpleGrid } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconAbacusOff, IconCalendarEvent, IconPlus } from "@tabler/icons";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SubTitlePage } from "../../../../components/SubTitlePage";
import reqFact from "../../../../services/RiskPoliceFacts";
import reqDimension from "../../../../services/RiskPoliceDimension";
import { Input } from "../../../../components/Input/Index";
import { useForm } from "react-hook-form";

interface Props {
  reload: () => void;
}

export default function NovoFactModal({ reload }: Props) {
  const [opened, { open, close }] = useDisclosure(false);
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [selectOptions, setSelectOptions] = useState<any[]>([]);
  const [valueInput, setValueInput] = useState('')

  const {
		control,
		trigger,
		clearErrors,
		watch,
		getValues,
		setError,
		resetField,
    reset
	} = useForm<any>()


  async function handlePostData() {
    if (!await trigger()) {
			return
		}
    showNotification({
      id: "load-data",
      loading: true,
      title: "Criando",
      message: "Aguarde...",
      autoClose: true,
      disallowClose: true,
    });
    setLoading(true);
		const payload = getValues()


  const data = await reqFact.create({...payload, dimension_id: valueInput })

  console.log(data.data)

  if (data.data.status !== 201){
    setLoading(false);
    reload()
    reset({})
    updateNotification({
      id: "load-data",
      color: "red",
      title: "Erro!",
      message: `Erro ao criar fact!`,
      autoClose: 2000,
    });
    setLoading(false);
  }

  updateNotification({
    id: "load-data",
    color: "teal",
    title: "Sucesso!",
    message: `Criado com sucesso!`,
    autoClose: 2000,
  });
  setLoading(false)
  close()
  reload()

}

function handleChangeValue(newValue: string) {
  setValueInput(newValue);
}

const getDataAllDimensions = async () => {
  const response = await reqDimension.getAll()

  const data = response.data.data

  const newData: any[] = [];

  data.forEach((obj: any) => {
    const newObject: any = {
        value: obj.id.toString(),
        label: obj.name
    };

    newData.push(newObject)
});
setSelectOptions(newData)

}

useEffect(() => {
  getDataAllDimensions()
},[])

  return (
    <>
      <Modal
        opened={opened}
        size="sm"
        onClose={close}
        dir="row"
        withCloseButton={false}
      >
        <SubTitlePage title="Criar novo Fact"></SubTitlePage>
        <Flex className="!gap-2 flex-col">
              
              <Select
              label='Relação'
              value={valueInput}
              withinPortal
              data={selectOptions}
              onChange={(e) => {
                handleChangeValue(typeof e === "string" ? e : "");
              }}
              placeholder="Relação"
              ></Select>


          <Input resetField={resetField} clearable={true} control={control}
								rules={{required: "Campo obrigatório"}} name={'type_policies'}
								labelClassName={`w-full`} label={"Tipo Politica"}/>

          <Input resetField={resetField} clearable={true} control={control}
								rules={{required: "Campo obrigatório"}} name={'type_limit'}
								labelClassName={`w-full`} label={"Campo"}/>
                
          <Input type="number" resetField={resetField} clearable={true} control={control}
								rules={{required: "Campo obrigatório"}} name={'portfolio'}
								labelClassName={`w-full`} label={"Portfólio"}/>

          <Input type="number" resetField={resetField} clearable={true} control={control}
								rules={{required: "Campo obrigatório"}} name={'limit'}
								labelClassName={`w-full`} label={"Limite"}/>
        </Flex>
        <Button
          fullWidth
          size="md"
          mt="xs"
          onClick={() => handlePostData()}
          loading={loading}
        >
          Criar
        </Button>
      </Modal>

      <Group position="center">
        <Button size="xs" leftIcon={<IconPlus />} onClick={open}>
          Novo Fact
        </Button>
      </Group>
    </>
  );
}
